import { useEffect, useState } from "react";
import styled from '@emotion/styled';

import { getSelectedGameCharacter, getGameHistory, getGameCharactersList, selectGameCharacter } from "@api/Api";

import ConfirmationModal from '@components/ConfirmationModal';
import CharacterCard from "@components/CharacterCard";
import MatchHistory from "@components/MatchHistory";

import strings from "@resources/strings";
import { DEFAULT_PAGE_SIZE } from "@resources/DataModel";

const ContentContainer = styled.div(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'start',
    textAlign: 'center',
    justifyContent: 'space-around',
    marginBottom: theme.spacing.xs,
    [ theme.mediaQuery.tabletUp ]: {
        flexDirection: 'row'
    }
}));

function BowlingGameLandingPage() {
    const [ character, setCharacter ] = useState({});
    const [ matches, setMatches ] = useState([]);
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");

    const getCharacter = () => {
        const success = (response) => {
            setCharacter(response);
        };

        const error = (response) => {
            const characterListSuccess = (response) => {
                const selectSuccess = (response) => {
                    getCharacter();
                };

                const selectError = (response) => {
                    setShowErrorModal(true);
                    setErrorMessage(response);
                };

                selectGameCharacter(response.result[0].uuid, selectSuccess, selectError);
            };

            const characterListError = (response) => {
                setShowErrorModal(true);
                setErrorMessage(response);
            };

            if (response.includes("The users didn't select any character")) {
                getGameCharactersList({ pageSize: DEFAULT_PAGE_SIZE, currentPage: 1 }, characterListSuccess, characterListError);
            } else {
                setShowErrorModal(true);
                setErrorMessage(response);
            }
        };

        getSelectedGameCharacter(success, error);
    };

    const getMatches = () => {
        const success = (response) => {
            setMatches(response.result);
        };

        const error = (response) => {
            if (!response.includes("The Character was not found")) {
                setShowErrorModal(true);
                setErrorMessage(response);
            }
        };

        getGameHistory(success, error);
    };

    useEffect(() => {
        getCharacter();
        getMatches();
    }, []);

    return (
        <>
            <ContentContainer>
                <CharacterCard character={character} reload={getCharacter} />
                <MatchHistory matches={matches} outOfStamina={character && character.stats && character.stats.stamina && character.stats.stamina.current === character.stats.stamina.min_value} />
            </ContentContainer>
            <ConfirmationModal
                title={strings.error}
                show={showErrorModal}
                onConfirm={() => {setShowErrorModal(false); setErrorMessage("");}}
                onClose={() => {setShowErrorModal(false); setErrorMessage("");}}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
        </>
    );
}

export default BowlingGameLandingPage;
