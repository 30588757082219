import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useState } from "react";
import { useNavigate } from "react-router";
import { registerUser } from "@api/Api";
import routes from "@resources/Routes";
import strings from "@resources/strings";
import Button from "@components/Button";
import Input from "@components/Input";
import ConfirmationModal from "@components/ConfirmationModal";
import Loading from "@components/Loading";
import { useEthereum } from '@hooks/EthereumContext';
import { useChain } from "@hooks/ChainContext";

const Form = styled.form(() => ({
    display: 'inline-grid',
    maxWidth: 300
}));

const RegistrationForm = () => {
    const { isAuthenticated, actor } = useChain();
    const [ showSuccessModal, setShowSuccessModal ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ showErrorModal, setShowErrorModal ] = useState(false);

    const [ username, setUsername ] = useState('');
    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const { account } = useEthereum();

    const navigate = useNavigate();
    const theme = useTheme();

    const register = async (e) => {
        if (!validateForm()) {
            return;
        }

        await registerUserServer();
    };

    const registerUserServer = async () => {
        const user = {
            username: username,
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            wax_account: isAuthenticated ? actor : undefined,
            eth_account: account ? account : undefined,
            client_id: account ? "eth_account" : "wax_account"
        };

        const onSuccess = () => {
            setLoading(false);
            setShowSuccessModal(true);
        };

        const onError = (response) => {
            setLoading(false);
            setErrorMessage(response);
            setShowErrorModal(true);
        };

        setLoading(true);
        await registerUser(user, onSuccess, onError);
    };

    const validateForm = () => {
        if (username === "" || firstName === "" || lastName === "" || email === "" || password === "") {
            setErrorMessage(strings.emptyFields);
            return false;
        }
        if (password !== confirmPassword) {
            setErrorMessage(strings.passwordsDontMatch);
            return false;
        }
        setErrorMessage("");
        return true;
    };

    return (
        <>
            <Form onSubmit={(e) => e.preventDefault()}>
                <Input type="text" id="fusername" value={username} label={strings.username} onChange={(e) => setUsername(e.target.value)} />
                <Input type="text" id="ffirstname" value={firstName} label={strings.firstName} onChange={(e) => setFirstName(e.target.value)} />
                <Input type="text" id="flastname" value={lastName} label={strings.lastName} onChange={(e) => setLastName(e.target.value)} />
                <Input type="text" id="femail" value={email} label={strings.email} onChange={(e) => setEmail(e.target.value)} />
                <Input type="password" id="fpassword" value={password} label={strings.password} onChange={(e) => setPassword(e.target.value)} />
                <Input type="password" id="frepeatedpassword" value={confirmPassword} label={strings.confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                <p><i>{strings.passwordRequirements}</i></p>
                <Button style={{ marginTop: theme.spacing.xs }} onClick={register}>{strings.register}</Button>
                <p style={{ color: theme.colors.error.main }}><b><i>{errorMessage}</i></b></p>
            </Form>
            <ConfirmationModal
                show={showSuccessModal}
                title={strings.registration + " " + strings.success}
                onConfirm={() => navigate(routes.home)}
                onClose={() => navigate(routes.home)}
                noCancel
            >
                {strings.activationAccountSent}
            </ConfirmationModal>
            <ConfirmationModal
                show={showErrorModal}
                title={strings.error}
                onConfirm={() => {setShowErrorModal(false); setErrorMessage("");}}
                onClose={() => {setShowErrorModal(false); setErrorMessage("");}}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
            { loading ? <Loading isFullScreen /> : null }
        </>
    );
};

export default RegistrationForm;
