import styled from "@emotion/styled";
import Pin from "@components/match/Pin";
import { useEffect, useState } from "react";

const PinsContainer = styled.div(() => ({
    display: 'flex',
    position: "absolute",
    top: "20%",
    height: "30%",
    width: "100%",
    right: 0,
    left: 0,
    flexDirection: 'column',
    alignItems: 'center'
}));

const RowContainer = styled.div(({ theme, index = 1 }) => ({
    display: 'flex',
    zIndex: index,
    height: "100%",
    width: "100%",
    position: "absolute",
    justifyContent: "center",
    top: `calc(100% * 0.15 * ${index})`
}));

const Pins = ({ pins = [] }) => {
    const [ pinsArray, setPinsArray ] = useState([]);

    useEffect(() => {
        let pinsArray = [];
        for (let i = 1; i <= 10; i++) {
            pinsArray.push(<Pin key={i} knocked={pins.includes(i)} />);
        }
        setPinsArray(pinsArray);
    }, [ pins ]);

    return (
        <PinsContainer>
            <RowContainer index={1}>{pinsArray.slice(6, 10)}</RowContainer>
            <RowContainer index={2}>{pinsArray.slice(3, 6)}</RowContainer>
            <RowContainer index={3}>{pinsArray.slice(1, 3)}</RowContainer>
            <RowContainer index={4}>{pinsArray[0]}</RowContainer>
        </PinsContainer>
    );
};

export default Pins;
