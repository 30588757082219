import { createContext, useContext, useState } from 'react';

const GeneralContext = createContext();

export const SessionStorageConnectedNetworkInfoKey = "connectedNetwork";

const BaseGeneralProvider = ({ children }) => {
    const [ connectedNetwork, setConnectedNetwork ] = useState(window.sessionStorage.getItem(SessionStorageConnectedNetworkInfoKey));
    const [ activeUserAccounts, setActiveUserAccounts ] = useState([]);

    const setActiveAccounts = (serverAccountInfo) => {
        const userAccounts = [];
        if (serverAccountInfo) {
            if (serverAccountInfo.wax_account) {
                userAccounts.push({ wax_account: serverAccountInfo.wax_account });
            }

            if (serverAccountInfo.eth_account) {
                userAccounts.push({ eth_account: serverAccountInfo.eth_account });
            }

            if (userAccounts !== activeUserAccounts) {
                setActiveUserAccounts(userAccounts);
            }
        }
    };

    const setNetwork = (network) => {
        if (network === "wax") {
            setConnectedNetwork("wax");
            window.sessionStorage.setItem(SessionStorageConnectedNetworkInfoKey, network);
        }

        if (network === "ethereum") {
            setConnectedNetwork("ethereum");
            window.sessionStorage.setItem(SessionStorageConnectedNetworkInfoKey, network);
        }
    };

    const logoutNetwork = () => {
        window.sessionStorage.removeItem(SessionStorageConnectedNetworkInfoKey);
        setConnectedNetwork(null);
    };

    return (
        <GeneralContext.Provider value={
            {
                connectedNetwork,
                activeUserAccounts,
                setActiveAccounts,
                setNetwork,
                logoutNetwork
            }
        }
        >
            {children}
        </GeneralContext.Provider>
    );
};
function useSetNetwork() {
    const context = useContext(GeneralContext);
    const {  connectedNetwork, setNetwork, activeUserAccounts, setActiveAccounts, logoutNetwork } = context;

    return { connectedNetwork, setNetwork, activeUserAccounts, setActiveAccounts, logoutNetwork };
}

const GeneralProvider = BaseGeneralProvider;

export { GeneralProvider, useSetNetwork };
