import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { getInventoryNfts } from '@api/Api';

import ConfirmationModal from '@components/ConfirmationModal';
import InventoryCard from '@components/InventoryCard';
import Loading from '@components/Loading';
import Pagination from '@components/Pagination';
import Select from '@components/Select';
import ConsumeNft from '@components/ConsumeNft';

import strings from "@resources/strings";
import { DEFAULT_PAGE_SIZE, QUERY_KEYS, INVENTORY_COLUMNS, NONE } from '@resources/DataModel';
import { rarityFilterOptions, nftTypeFilterOptions, inventoryRoleFilterOptions, orderOptions, sortOptions } from '@resources/FiltersConfig';

const CardGridContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'space-evenly',
    [ theme.mediaQuery.tabletUp ]: {
        flexDirection: 'row'
    }
}));

const OrderAndFilterContainer = styled.div(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [ theme.mediaQuery.tabletLandscapeUp ]: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
}));

const OrderAndFilterGroup = styled.div(({ theme }) => ({
    margin: `${theme.spacing.xxs} ${theme.spacing.xs}`,
    display: 'flex',
    [ '& > div' ]: {
        [ '&:not(:last-of-type)' ]: {
            marginRight: theme.spacing.xxs
        }
    },
    [ theme.mediaQuery.mobileOnly ]: {
        display: 'block'
    }
}));

const Inventory = () => {
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ page, setPage ] = useState(1);
    const [ maxPage, setMaxPage ] = useState(1);
    const [ nftsList, setNftsList ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    const [ selectedOrder, setSelectedOrder ] = useState(null);
    const [ selectedSort, setSelectedSort ] = useState(sortOptions[0]);

    const [ selectedFilters, setSelectedFilters ] = useState({
        [INVENTORY_COLUMNS.rarity]: rarityFilterOptions[0],
        [INVENTORY_COLUMNS.inventoryType]: nftTypeFilterOptions[0],
        [INVENTORY_COLUMNS.inventoryRole]: inventoryRoleFilterOptions[0]
    });

    const theme = useTheme();

    useEffect(() => {
        loadNfts();
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [ nftsList ]);

    const loadNfts = (currentPage = null, currentSelectOrder = null, currentSelectedSort = null, currentFilter = null) => {
        const success = (response) => {
            setPage(response.current_page);
            setMaxPage(response.max_page);
            setNftsList(response.result);
            setLoading(false);
        };

        const error = (response) => {
            setShowErrorModal(true);
            setErrorMessage(response);
            setLoading(false);
        };

        const order = currentSelectOrder?.value ?? selectedOrder?.value;
        const sort = currentSelectedSort?.value ?? selectedSort?.value;
        const queryObj = {
            [QUERY_KEYS.page]: currentPage ?? page,
            [QUERY_KEYS.pageSize]: DEFAULT_PAGE_SIZE,
            [QUERY_KEYS.order]: order ? `${sort}${order}` : null
        };

        // Apply filters
        const filters = currentFilter ?? selectedFilters;
        for (let filter in filters) {
            if (filters[filter].value !== NONE) {
                queryObj[filter] = filters[filter].value;
            }
        }

        setLoading(true);
        getInventoryNfts(queryObj, success, error);
    };

    const onOrderChange = (order, sort) => {
        setSelectedOrder(order);
        setSelectedSort(sort);
        loadNfts(page, order, sort);
    };

    const onFilterChange = (filter, value) => {
        let newFilter = { ...selectedFilters };
        newFilter[filter] = value;
        setSelectedFilters(newFilter);
        loadNfts(page, selectedOrder, selectedSort, newFilter);
    };

    const onPageChange = (newPage) => {
        loadNfts(newPage);
    };

    const renderExtraCardControls = (nft) => {
        return (
            <ConsumeNft nft={nft} reload={loadNfts} />
        );
    };

    return (
        <>
            <OrderAndFilterContainer>
                <OrderAndFilterGroup>
                    <Select placeholder={strings.orderBy} options={orderOptions} selected={selectedOrder} onSelectChange={(order) => onOrderChange(order, selectedSort)} />
                    <Select options={sortOptions} selected={selectedSort} onSelectChange={(sort) => onOrderChange(selectedOrder, sort)} />
                </OrderAndFilterGroup>
                <OrderAndFilterGroup>
                    <Select options={rarityFilterOptions} selected={selectedFilters[INVENTORY_COLUMNS.rarity]} onSelectChange={(f) => onFilterChange(INVENTORY_COLUMNS.rarity, f)} />
                    <Select options={nftTypeFilterOptions} selected={selectedFilters[INVENTORY_COLUMNS.inventoryType]} onSelectChange={(f) => onFilterChange(INVENTORY_COLUMNS.inventoryType, f)} />
                    <Select options={inventoryRoleFilterOptions} selected={selectedFilters[INVENTORY_COLUMNS.inventoryRole]} onSelectChange={(f) => onFilterChange(INVENTORY_COLUMNS.inventoryRole, f)} />
                </OrderAndFilterGroup>
            </OrderAndFilterContainer>
            <CardGridContainer>
                { nftsList ?
                    nftsList.map(nft => (<InventoryCard key={nft.uuid} nft={nft} controls={() => renderExtraCardControls(nft)} />))
                    :
                    null
                }
            </CardGridContainer>
            <Pagination currentPage={page} totalPages={maxPage} onPageChange={onPageChange} />
            <ConfirmationModal
                title={strings.error}
                show={showErrorModal}
                onConfirm={() => {setShowErrorModal(false); setErrorMessage("");}}
                onClose={() => {setShowErrorModal(false); setErrorMessage("");}}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
            { loading ? <Loading isFullScreen /> : null }
        </>
    );
};

export default Inventory;
