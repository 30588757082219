import { createContext, useContext, useEffect, useState } from "react";
import { validateCharacterCreation } from "@api/Api";

const FeatureFlagsContext = createContext();

const FeatureFlagsProvider = ({ children }) => {
    const [ characterCreator, setCharacterCreator ] = useState(null);

    useEffect(() => {
        validateCharacterCreation(() => setCharacterCreator(true), () => setCharacterCreator(false));
    });

    const featureFlags = {
        characterCreator
    };

    return (
        <FeatureFlagsContext.Provider value={{ featureFlags }}>
            { children }
        </FeatureFlagsContext.Provider>
    );
};

const useFeatureFlags = () => {
    const context = useContext(FeatureFlagsContext);
    const { featureFlags } = context;

    return featureFlags;
};

export { FeatureFlagsProvider, useFeatureFlags };
