import { useEffect, useRef, useState } from "react";
import StyledLink from '@components/Link';
import styled from '@emotion/styled';

const DropdownContainer = styled.div(() => ({
    position: 'relative'
}));

const DropdownList = styled.div(({ theme }) => ({
    position: 'absolute',
    top: 30,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.xxs,
    zIndex: 10,
    color: theme.colors.common.text,
    backgroundColor: theme.colors.common.backgroundLighter,
    padding: `${theme.spacing.xs} ${theme.spacing.s}`,
    borderRadius: theme.borderRadius.s,
    boxShadow: theme.shadow.dropShadow,
    transition: theme.transition.default,
    width: theme.size.dropdownWidth
}));

const DropdownItem = styled.div(({ theme }) => ({
    ...theme.typography.p,
    '&:hover': {
        color: theme.colors.primary.main,
        cursor: 'pointer'
    }
}));

function Dropdown({ label, options = [] }) {
    const containerRef = useRef(null);

    const [ open, setOpen ] = useState(false);

    const handleClickOutside = (event) => {
        if (containerRef && !containerRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const optionsList = options.map((option, index) => <DropdownItem key={index} onClick={() => { option.onClick(); setOpen(false); }}>{option.label}</DropdownItem>);

    return (
        <DropdownContainer ref={containerRef}>
            <StyledLink header onClick={() => setOpen(prevState => !prevState)}>
                {label}
            </StyledLink>
            {
                open ?
                    <DropdownList>
                        {optionsList}
                    </DropdownList> : null
            }
        </DropdownContainer>
    );
}

export default Dropdown;
