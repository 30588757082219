import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import Button from "@components/Button";
import Pins from "@components/match/Pins";
import Score from "@components/match/Score";
import Minimap from "@components/match/Minimap";
import Modal from "@components/Modal";
import ConfirmationModal from "@components/ConfirmationModal";
// import CharacterCard from "@components/CharacterCard";
import { getMatch, getNextFrame, skipGame, getSelectedGameCharacter } from "@api/Api";
import strings from '@resources/strings';
import routes from '@resources/Routes';
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router";

const Lane = styled.div(({ theme, selectedAlley }) => ({
    position: "relative",
    backgroundImage: `url('${selectedAlley}')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    borderRadius: theme.borderRadius.l,
    marginBottom: theme.spacing.xxxs,
    width: `min(calc(100vh - 72px - 130px - 38px), 98vw)`,
    height: `min(calc(100vh - 72px - 130px - 38px), 98vw)`,
    minWidth: '350px',
    minHeight: '350px',
    overflow: "hidden"
}));

const ElementContainer = styled.div(({ theme, styles }) => ({
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    ...styles
}));

const Match = ({ selectedAlley = '/assets/match/blue_alley.png', uuid, setLoading = () => {} }) => {
    const [ match, setMatch ] = useState(null);
    const [ frames, setFrames ] = useState([]);
    const [ lastFrame, setLastFrame ] = useState(null);
    const [ currentThrow, setCurrentThrow ] = useState(0);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ totalScore, setTotalScore ] = useState(0);
    const [ started, setStarted ] = useState(false);
    // const [ character, setCharacter ] = useState({});
    const [ strikeAnimation, setStrikeAnimation ] = useState(false);
    const [ pinsResetAnimation, setPinsResetAnimation ] = useState(false);
    const [ pinsReset, setPinsReset ] = useState(false);
    const [ showPinsReset, setShowPinsReset ] = useState(false);

    const theme = useTheme();
    const navigate = useNavigate();

    useEffect(() => {
        if (uuid && uuid !== '') {
            loadMatch(uuid);
        }
    }, [ uuid ]);

    // useEffect(() => {
    //     getCharacter();
    // }, []);

    // const getCharacter = () => {
    //     const success = (response) => {
    //         setCharacter(response);
    //     };

    //     const error = (response) => {
    //         setShowErrorModal(true);
    //         setErrorMessage(response);
    //     };

    //     getSelectedGameCharacter(success, error);
    // };

    const loadMatch = (uuid) => {
        setLoading(true);
        const success = (match) => {
            setMatch(match);
            setLastFrame(match.frames[match.frames.length - 1]);
            if (match.frames.length > 1) {
                setFrames(match.frames.slice(0, match.frames.length - 1));
                setStarted(true);
            }
            if (!match.ongoing) {
                setCurrentThrow(match.frames[match.frames.length - 1].throws.length - 1);
                setTotalScore(match.score);
            }
            setLoading(false);
        };

        const error = (err) => {
            setErrorMessage(err);
            setShowErrorModal(true);
            setLoading(false);
        };

        getMatch(uuid, success, error);
    };

    const callNextFrame = () => {
        const success = (updatedMatch) => {
            setFrames(match.frames);
            setMatch(updatedMatch);
            setLastFrame(updatedMatch.frames[updatedMatch.frames.length - 1]);
            setCurrentThrow(0);
            setPinsReset(false);
            setShowPinsReset(false);
        };

        const error = (err) => {
            setErrorMessage(err);
            setShowErrorModal(true);
        };

        getNextFrame(uuid, success, error);
    };

    const onPlayClick = () => {
        if (pinsReset ||
            (match.frames.length === 10 && (currentThrow === 0 || lastFrame.throws[0]?.strike || lastFrame.throws[1]?.spare)) ||
            (match.frames.length !== 10 && currentThrow === 0 && !lastFrame.throws[currentThrow]?.strike)) {
            setStrikeAnimation(true);
            setTimeout(() => setStrikeAnimation(false), 600);
        } else {
            setPinsResetAnimation(true);
            setTimeout(() => setShowPinsReset(true), 300);
            setTimeout(() => setPinsResetAnimation(false), 600);
        }

        if (!started) {
            setStarted(true);
        } else if (match.frames.length === 10) {
            if (currentThrow + 1 === lastFrame.throws.length - 1) {
                setTotalScore(match.score);
            }
            setCurrentThrow(currentThrow + 1);
        } else if (currentThrow === lastFrame.throws.length - 1 || lastFrame.throws[currentThrow].strike) {
            if (pinsReset) {
                callNextFrame();
            } else {
                setPinsReset(true);
            }
        } else if (currentThrow < lastFrame.throws.length - 1) {
            if (currentThrow + 1 === lastFrame.throws.length - 1) {
                setTotalScore(match.score);
            }
            setCurrentThrow(currentThrow + 1);
        } else if (match.ongoing) {
            callNextFrame();
        }
    };

    const onSkipGameClick = () => {
        if (!started) {
            setStarted(true);
        }
        if (match.ongoing) {
            const success = (match) => {
                setMatch(match);
                const lastFrame = match.frames[match.frames.length - 1];
                setLastFrame(lastFrame);
                setFrames(match.frames);
                setTotalScore(match.score);
                setCurrentThrow(lastFrame.throws.length - 1);
            };

            const error = (err) => {
                setErrorMessage(err);
                setShowErrorModal(true);
            };

            skipGame(uuid, success, error);
        } else if (currentThrow < lastFrame.throws.length - 1) {
            setCurrentThrow(lastFrame.throws.length - 1);
            setTotalScore(match.score);
            setFrames(match.frames);
        }
    };

    const getKnockedPins = () => {
        let knockedPins = [];
        if (!showPinsReset && started && lastFrame != null) {
            const { throws } = lastFrame;
            knockedPins = throws[currentThrow].total_pins_knocked;
            // knockedPins = throws[0].pins_knocked;
            // if (throws.length === 3) {
            //     // TODO: review logic when is 3 throws on last frame
            //     if (currentThrow === 1) {
            //         if (throws[0].pins_knocked.length === 10) {
            //             knockedPins = throws[1].pins_knocked;
            //         } else {
            //             knockedPins = knockedPins.concat(throws[1].pins_knocked);
            //         }
            //     } else if (currentThrow === 2) {
            //         if (throws[0].pins_knocked.length === 10 && throws[0].pins_knocked.length < 10) {
            //             knockedPins = throws[1].pins_knocked.concat(throws[2].pins_knocked);
            //         } else {
            //             knockedPins = throws[2].pins_knocked;
            //         }
            //     }
            // } else {
            //     if (currentThrow === 1) {
            //         knockedPins = knockedPins.concat(throws[1].pins_knocked);
            //     }
            // }
        }

        return knockedPins;
    };

    const getFrames = () => {
        let framesList = frames;
        if (match && match.ongoing || (lastFrame && currentThrow < lastFrame.throws.length - 1)) {
            if (started && frames.length !== match.frames.length) {
                return framesList.concat(lastFrame);
            } else {
                return framesList;
            }
        } else {
            return match ? match.frames : [];
        }
    };

    const renderStrikeAnimation = () => {
        return (
            <ElementContainer className="strike_smoke" styles={{ zIndex: 9 }}>
                <img src="/assets/strike_smoke.svg" />
            </ElementContainer>
        );
    };

    const renderResetPinsAnimation = () => {
        return (
            <ElementContainer className="pins_reset" styles={{ zIndex: 9 }}>
                <img src="/assets/pins_reset.svg" />
            </ElementContainer>
        );
    };

    return (
        <>
            <Lane selectedAlley={selectedAlley}>
                {/* <ElementContainer>
                    <CharacterCard character={character} minified />
                </ElementContainer> */}
                <ElementContainer styles={{ width: "fit-content", height: "fit-content", bottom: "unset", left: "unset", zIndex: 10 }}>
                    <Minimap pins={getKnockedPins()} />
                </ElementContainer>
                <ElementContainer>
                    <Pins pins={getKnockedPins()} />
                </ElementContainer>
                <ElementContainer styles={{ top: 'unset' }}>
                    { match && lastFrame && !match.ongoing && match.frames.length === 10 && currentThrow === lastFrame.throws.length - 1 ?
                        <Modal show onClose={() => navigate(routes.game)}>
                            <div style={{ margin: theme.spacing.s }}>{strings.congratulations}</div>
                            <div style={{ margin: theme.spacing.s }}>{strings.finalScore + ': ' + match.score}</div>
                            <div style={{ margin: theme.spacing.s }}>{strings.matchRewards + ': ' + match.game_data.experience + ' ' + strings.xp}</div>
                            <Button styles={{ padding: `0px ${theme.spacing.s}`, margin: theme.spacing.xs }} onClick={() => navigate(routes.game)} >{strings.close}</Button>
                        </Modal>
                        :
                        <>
                            <Button outline secondary onClick={onSkipGameClick} styles={{ width: 100, float: 'right', margin: `auto ${theme.spacing.xs} ${theme.spacing.xs} auto` }}>{strings.skip}</Button>
                            <Button onClick={onPlayClick} styles={{ width: 100, margin: `auto auto ${theme.spacing.xs} 116px` }}>{strings.play}</Button>
                        </>
                    }
                </ElementContainer>
                { strikeAnimation ? renderStrikeAnimation() : null }
                { pinsResetAnimation ? renderResetPinsAnimation() : null }
            </Lane>
            <Score frames={getFrames()} currentThrow={currentThrow} totalScore={totalScore} />
            <ConfirmationModal
                title={strings.match + " " + strings.error}
                show={showErrorModal}
                onConfirm={() => {setShowErrorModal(false); setErrorMessage("");}}
                onClose={() => {setShowErrorModal(false); setErrorMessage("");}}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
        </>
    );
};

export default Match;
