import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { loginUser, requestToResetUserPassword, requestToRecoverUsername } from "@api/Api";
import { useServerAccount } from "@hooks/AccountContext";
import routes from "@resources/Routes";
import strings from "@resources/strings";
import Button from "@components/Button";
import Input from "@components/Input";
import ConfirmationModal from "@components/ConfirmationModal";
import Link from "@components/Link";
import Loading from "@components/Loading";
import { useEthereum } from "@hooks/EthereumContext";
import { useSetNetwork } from "@hooks/GeneralContext";
import { useChain } from "@hooks/ChainContext";

const Form = styled.form(() => ({
    display: 'inline-grid'
}));

const RegisterLabel = styled.span(({ theme }) => ({
    ...theme.typography.p,
    marginTop: theme.spacing.m
}));

const LinkContainer = styled.div(() => ({
    width: "100%",
    textAlign: "center"
}));

const PasswordContainer = styled.div(({ theme }) => ({
    position: "relative"
}));

const EyeContainer = styled.div(({ theme }) => ({
    position: "absolute",
    right: "12px",
    top: "38px",
    cursor: 'pointer',
    userSelect: 'none'
}));

const ServerLogin = () => {
    const { isAuthenticated, actor, permission } = useChain();
    const { serverAccountInfo, setServerAccountInfo } = useServerAccount();
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ loading, setLoading ] = useState(false);
    const [ showPasswordRecoverySuccessModal, setShowPasswordRecoverySuccessModal ] = useState(false);
    const [ showPasswordResetConfirmationModal, setShowPasswordResetConfirmationModal ] = useState(false);
    const [ showUsernameRecoverySuccessModal, setShowUsernameRecoverySuccessModal ] = useState(false);
    const [ showUsernameRecoveryConfirmationModal, setShowUsernameRecoveryConfirmationModal ] = useState(false);
    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ passwordShow, setPasswordShow ] = useState(false);
    const { account } = useEthereum();
    const { connectedNetwork } = useSetNetwork();

    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        if (serverAccountInfo != null) {
            navigate(routes.home);
        } else if (!connectedNetwork) {
            navigate(routes.ualLogin);
        }
    }, []);

    const login = () => {
        const userLogin = {
            grant_type: 'password',
            username: username,
            password: password,
            client_secret: connectedNetwork === "wax" ? actor : connectedNetwork === "ethereum" ? account : null,
            client_id: connectedNetwork === "wax" ? "wax_account" : connectedNetwork === "ethereum" ? "eth_account" : null
        };

        const onSuccess = (data) => {
            setLoading(false);
            setServerAccountInfo(data, true);
            navigate(routes.home);
        };

        const onError = (response) => {
            setLoading(false);
            setShowErrorModal(true);
            setErrorMessage(response);
        };

        setLoading(true);
        loginUser(userLogin, onSuccess, onError);
    };

    const requestResetPassword = () => {

        const resetPassInfo = {
            wax_account: actor
        };

        const success = () => {
            setShowPasswordResetConfirmationModal(false);
            setLoading(false);
            setShowPasswordRecoverySuccessModal(true);
        };

        const error = (response) => {
            setShowPasswordResetConfirmationModal(false);
            setLoading(false);
            setShowErrorModal(true);
            setErrorMessage(response);
        };

        setLoading(true);
        requestToResetUserPassword(resetPassInfo, success, error);
    };

    const requestRecoverUsername = () => {
        const recoverUsernameInfo = {
            wax_account: actor
        };

        const success = () => {
            setShowUsernameRecoveryConfirmationModal(false);
            setLoading(false);
            setShowUsernameRecoverySuccessModal(true);
        };

        const error = (response) => {
            setShowUsernameRecoveryConfirmationModal(false);
            setLoading(false);
            setShowErrorModal(true);
            setErrorMessage(response);
        };

        setLoading(true);
        requestToRecoverUsername(recoverUsernameInfo, success, error);
    };

    return (
        <>
            <Form onSubmit={(e) => e.preventDefault()}>
                <Input type="text" id="fusername" value={username} label={strings.username} onChange={(e) => setUsername(e.target.value)} />
                <PasswordContainer>
                    <Input
                        name={strings.password}
                        type={passwordShow ? "text" :  "password"}
                        value={password}
                        label={strings.password}
                        onChange={(e) => setPassword(e.target.value)}
                        inputStyle={{
                            paddingRight: 50,
                            width: 'calc(100% - 66px)'
                        }}
                    />
                    <EyeContainer>
                        <img
                            src={passwordShow ? "/assets/password-eye.svg" : "/assets/password-eye-slash.svg"}
                            onClick={() => setPasswordShow(prevState => !prevState)}
                        />
                    </EyeContainer>
                </PasswordContainer>
                <LinkContainer>
                    <Link onClick={() => setShowPasswordResetConfirmationModal(true)}>{strings.forgotMyPassword}</Link>
                </LinkContainer>
                <LinkContainer>
                    <Link onClick={() => setShowUsernameRecoveryConfirmationModal(true)}>{strings.forgotMyUsername}</Link>
                </LinkContainer>
                <LinkContainer>
                    <Link onClick={() => navigate(routes.requestAccountActivation)}>{strings.activateAccount}</Link>
                </LinkContainer>
                <Button style={{ marginTop: theme.spacing.xs }} onClick={login}>{strings.logIn}</Button>
            </Form>
            <RegisterLabel>{strings.dontHaveAnAccountYet} <Link onClick={() => navigate(routes.register)}>{strings.registerNow}</Link></RegisterLabel>
            <ConfirmationModal
                title={strings.forgotMyUsername}
                show={showUsernameRecoveryConfirmationModal}
                onConfirm={requestRecoverUsername}
                onClose={() => setShowUsernameRecoveryConfirmationModal(false)}
                confirmationButtonLabel={strings.sendEmail}
            >
                {strings.requestRecoverUsername.replace('{0}', actor)}
            </ConfirmationModal>
            <ConfirmationModal
                title={strings.requestRecoverUsernameSuccess}
                show={showUsernameRecoverySuccessModal}
                onConfirm={() => { setShowUsernameRecoverySuccessModal(false); }}
                onClose={() => { setShowUsernameRecoverySuccessModal(false); }}
                noCancel
            >
                {strings.requestRecoverUsernameSuccessfulNote}
            </ConfirmationModal>
            <ConfirmationModal
                title={strings.forgotMyPassword}
                show={showPasswordResetConfirmationModal}
                onConfirm={requestResetPassword}
                onClose={() => setShowPasswordResetConfirmationModal(false)}
                confirmationButtonLabel={strings.sendEmail}
            >
                {strings.requestResetPassword.replace('{0}', actor)}
            </ConfirmationModal>
            <ConfirmationModal
                title={strings.requestResetPasswordSuccess}
                show={showPasswordRecoverySuccessModal}
                onConfirm={() => { setShowPasswordRecoverySuccessModal(false);}}
                onClose={() => { setShowPasswordRecoverySuccessModal(false);}}
                noCancel
            >
                {strings.requestResetPasswordSuccessfulNote}
            </ConfirmationModal>
            <ConfirmationModal
                title={strings.logIn + " " + strings.error}
                show={showErrorModal}
                onConfirm={() => { setShowErrorModal(false); setErrorMessage(""); }}
                onClose={() => { setShowErrorModal(false); setErrorMessage(""); }}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
            { loading ? <Loading isFullScreen /> : null }
        </>
    );
};

export default ServerLogin;
