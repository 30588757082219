import { useLocation, useNavigate } from "react-router";
import StyledLink from '@components/Link';
import routes from "@resources/Routes";
import strings from "@resources/strings";
import styled from "@emotion/styled";

const crumbsSeparator = '>';

const BreadcrumbContainer = styled.div(({ theme }) => ({
    display: 'inline-block',
    alignSelf: 'start',
    margin: `${theme.spacing.xxxs} ${theme.spacing.xs}`,
    [ '& > *' ]: {
        margin: `0 ${theme.spacing.xxxs}`,
        color: 'white',
        fontWeight: 'bold',
        fontSize: 'initial'
    },
    [ '& > a' ]: {
        textDecoration: 'underline'
    }
}));

const Breadcrumb = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const renderGameBreadCrumbs = () => {
        let crumbs = [];
        if (location.pathname.includes(routes.game)) {
            crumbs = [ <StyledLink key={crumbs.length} onClick={() => navigate(routes.game)}>{strings.home}</StyledLink> ];
            if (location.pathname.includes(routes.matchHistory)) {
                crumbs.push(<span key={crumbs.length}>{crumbsSeparator}</span>);
                crumbs.push(<StyledLink key={crumbs.length} onClick={() => navigate(routes.game + routes.matchHistory)}>{strings.matchHistory}</StyledLink>);
            }
            if (location.pathname.includes(routes.inventory)) {
                crumbs.push(<span key={crumbs.length}>{crumbsSeparator}</span>);
                crumbs.push(<StyledLink key={crumbs.length} onClick={() => navigate(routes.game + routes.inventory)}>{strings.inventory}</StyledLink>);
            }
            if (location.pathname.includes(routes.practice)) {
                crumbs.push(<span key={crumbs.length}>{crumbsSeparator}</span>);
                crumbs.push(<StyledLink key={crumbs.length} onClick={() => navigate(routes.game + routes.practice)}>{strings.practice}</StyledLink>);
            }
        }
        return crumbs;
    };

    return (
        <BreadcrumbContainer>
            {renderGameBreadCrumbs()}
        </BreadcrumbContainer>
    );
};

export default Breadcrumb;
