import styled from "@emotion/styled";
import Link from "@components/Link";
import strings from '@resources/strings';

const TitleContainer = styled.h1(({ theme }) => ({
    ...theme.typography.h1,
    textAlign: 'center',
    margin: theme.spacing.l,
    maxWidth: '60ch'
}));

const TextContainer = styled.h4(({ theme }) => ({
    ...theme.typography.h4,
    margin: theme.spacing.m,
    textAlign: 'start',
    maxWidth: '60ch'
}));

const LinkContainer = styled.div(() => ({
    width: "100%",
    textAlign: "center"
}));

const NFTNotFound = () => {
    return (
        <>
            <TitleContainer>
                {strings.NFTNotFoundTitle}
            </TitleContainer>
            <TextContainer>
                {strings.NFTNotFoundNote}
            </TextContainer>
            <TextContainer>
                {strings.NFTMarketNote}
            </TextContainer>
            <LinkContainer>
                <Link href={strings.NFTMarketLink} target="_blank">{strings.buyATicket}</Link>
            </LinkContainer>
            <TextContainer>
                {strings.NFTNotFoundHelp}
            </TextContainer>
            <TextContainer>
                {strings.youCanJoinDiscord}
            </TextContainer>
            <LinkContainer>
                <Link href={strings.discordLink} target="_blank">{strings.joinDiscord}</Link>
            </LinkContainer>
        </>
    );
};

export default NFTNotFound;
