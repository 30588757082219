import styled from "@emotion/styled";

const CustomAlert = styled.div(({ styles, theme }) => ({
    borderRadius: theme.borderRadius.m,
    border: `solid ${theme.size.buttonBorder} ${theme.colors.primary.main}`,
    margin: theme.spacing.xs,
    padding: theme.spacing.xs,
    maxWidth: '60ch',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'start',
    ...styles
}));

const Warning = styled.img(({ theme }) => ({
    width: 76,
    marginRight: theme.spacing.xs
}));

const Alert = ({ children, styles, ...otherProps }) => {
    return (
        <CustomAlert styles={styles} {...otherProps}>
            <Warning src='/assets/warning.svg' />
            {children}
        </CustomAlert>
    );
};

export default Alert;
