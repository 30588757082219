import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetUserPassword } from "@api/Api";
import routes from "@resources/Routes";
import strings from "@resources/strings";
import Button from "@components/Button";
import ConfirmationModal from "@components/ConfirmationModal";
import Input from "@components/Input";
import Loading from "@components/Loading";

const Form = styled.form(() => ({
    display: 'inline-grid',
    maxWidth: 300
}));

const ResetPasswordForm = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [ searchParams ] = useSearchParams();
    const [ password, setPassword ] = useState("");
    const [ confirmPassword, setConfirmPassword ] = useState("");
    const [ passwordMessage, setPasswordMessage ] = useState("");
    const [ token, setToken ] = useState();
    const [ showSuccessModal, setShowSuccessModal ] = useState(false);
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        if (searchParams.has("token")) {
            setToken(searchParams.get("token"));
        } else {
            navigate(routes.home);
        }
    }, []);

    useEffect(() => {
        if (password === confirmPassword) {
            setPasswordMessage("");
        } else {
            setPasswordMessage(strings.passwordsDontMatch);
        }
    }, [ password, confirmPassword ]);

    const resetPassword = () => {
        if (!validateForm()) {
            return;
        }

        const resetPassInfo = {
            token: token,
            new_password: password
        };

        const success = () => {
            setLoading(false);
            setShowSuccessModal(true);
        };

        const error = (response) => {
            setLoading(false);
            setShowErrorModal(true);
            setErrorMessage(response);
        };

        setLoading(true);
        resetUserPassword(resetPassInfo, success, error);
    };

    const validateForm = () => {
        if (password !== confirmPassword) {
            setErrorMessage(strings.passwordsDontMatch);
            setShowErrorModal(true);
            return false;
        }
        return true;
    };

    return (
        <>
            <Form onSubmit={(e) => e.preventDefault()}>
                <Input type="password" id="fpassword" value={password} label={strings.newPassword} onChange={(e) => setPassword(e.target.value)} />
                <Input type="password" id="fconfirmpassword" value={confirmPassword} label={strings.confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                <p style={{ color: theme.colors.error.main }}><b><i>{passwordMessage}</i></b></p>
                <p><i>{strings.passwordRequirements}</i></p>
                <Button style={{ marginTop: theme.spacing.xs }} onClick={resetPassword}>{strings.resetPassword}</Button>
            </Form>
            <ConfirmationModal
                title={strings.resetPassword + " " + strings.success}
                show={showSuccessModal}
                onConfirm={() => { setShowSuccessModal(false); navigate(routes.login); }}
                onClose={() => { setShowSuccessModal(false); navigate(routes.login); }}
                noCancel
            >
                {strings.resetPasswordSuccessfulNote}
            </ConfirmationModal>
            <ConfirmationModal
                title={strings.resetPassword + " " + strings.error}
                show={showErrorModal}
                onConfirm={() => { setShowErrorModal(false); setErrorMessage(""); }}
                onClose={() => { setShowErrorModal(false); setErrorMessage(""); }}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
            { loading ? <Loading isFullScreen /> : null }
        </>
    );
};

export default ResetPasswordForm;
