import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { requestGameCharacterEquipmentChange, getInventoryNfts } from '@api/Api';
import { activeUserFromSession, transferNftToCustodyAccount } from "@api/AtomicHubApi";

import Button from '@components/Button';
import ConfirmationModal from '@components/ConfirmationModal';
import Modal from "@components/Modal";
import InventoryCard from "@components/InventoryCard";
import Pagination from '@components/Pagination';
import Loading from '@components/Loading';

import strings from "@resources/strings";
import { DEFAULT_PAGE_SIZE, NFT_TYPES, QUERY_KEYS, INVENTORY_COLUMNS } from "@resources/DataModel";
import { useChain } from "@hooks/ChainContext";

const TextContainer = styled.h5(({ theme }) => ({
    ...theme.typography.h5,
    fontWeight: 'bold',
    margin: theme.spacing.xs,
    color: theme.colors.common.text
}));

const EquipmentsContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    margin: theme.spacing.xs
}));

export const CharacterEquipmentsModal = ({
    equipment = {},
    characterUuid = null,
    show = false,
    onClose = () => {},
    reload = () => {}
}) => {
    const { session } = useChain();
    const [ equipments, setEquipments ] = useState([]);
    const [ selectedEquipment, setSelectedEquipment ] = useState(null);
    const [ page, setPage ] = useState(1);
    const [ maxPage, setMaxPage ] = useState(1);
    const [ showEquipConfirmationModal, setShowEquipConfirmationModal ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const theme = useTheme();

    useEffect(() => {
        if (show) {
            loadEquipments();
        }
    }, [ show ]);

    const loadEquipments = (currentPage = null) => {
        const success = (response) => {
            setPage(response.current_page);
            setMaxPage(response.max_page);
            setEquipments(response.result);
            setLoading(false);
        };

        const error = (response) => {
            setShowErrorModal(true);
            setErrorMessage(response);
            setLoading(false);
        };

        const queryObj = {
            [QUERY_KEYS.order]: '-' + INVENTORY_COLUMNS.rarity,
            [QUERY_KEYS.page]: currentPage ?? page,
            [QUERY_KEYS.pageSize]: DEFAULT_PAGE_SIZE,
            [INVENTORY_COLUMNS.inventoryType]: NFT_TYPES.equipment,
            [INVENTORY_COLUMNS.slotRole]: equipment?.slot,
            [INVENTORY_COLUMNS.statProperty]: equipment?.stats,
            equipped: false
        };

        setLoading(true);
        getInventoryNfts(queryObj, success, error);
    };

    const onPageChange = (newPage) => {
        loadEquipments(newPage);
    };

    const equipTransfer = () => {
        const success = () => {
            reload();
            setShowEquipConfirmationModal(false);
            setLoading(false);
        };

        const error = (message) => {
            setErrorMessage(message);
            setShowErrorModal(true);
            setLoading(false);
        };

        transferNftToCustodyAccount(activeUserFromSession(session), selectedEquipment.nft_id, selectedEquipment.uuid, success, error);
    };

    const equip = () => {
        const success = () => {
            equipTransfer();
        };

        const error = (message) => {
            setShowEquipConfirmationModal(false);
            setErrorMessage(message);
            setShowErrorModal(true);
            setLoading(false);
        };

        const payload = {
            game_character_uuid: characterUuid,
            inventory_uuid: selectedEquipment.uuid,
            slot: equipment.order
        };

        setLoading(true);
        requestGameCharacterEquipmentChange(payload, success, error);
    };

    const renderControls = (equipment) => {
        return <Button style={{ padding: `0px ${theme.spacing.s}`, margin: theme.spacing.xxs }} onClick={() => {setSelectedEquipment(equipment); setShowEquipConfirmationModal(true);}}>{strings.equip}</Button>;
    };

    return (
        <>
            <Modal show={show} style={{ width: '90vw' }} onClose={onClose}>
                { equipments?.length > 0 ?
                    <>
                        <EquipmentsContainer>
                            {equipments.map(equipment => <InventoryCard key={equipment.uuid} nft={equipment} noDescription controls={() => renderControls(equipment)} minified />)}
                        </EquipmentsContainer>
                        <Pagination currentPage={page} totalPages={maxPage} onPageChange={onPageChange} />
                    </>
                    :
                    <TextContainer>{strings.noEquipmentsAvailable}</TextContainer>
                }
            </Modal>
            <ConfirmationModal
                title={strings.equipmentChange}
                confirmationButtonLabel={strings.equip}
                show={showEquipConfirmationModal}
                onConfirm={equip}
                onClose={() => setShowEquipConfirmationModal(false)}
            >
                {strings.equipCharacterConfirmationMessage.replace("{0}", selectedEquipment?.name)}
            </ConfirmationModal>
            <ConfirmationModal
                title={strings.error}
                show={showErrorModal}
                onConfirm={() => {setShowErrorModal(false); setErrorMessage("");}}
                onClose={() => {setShowErrorModal(false); setErrorMessage("");}}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
            { loading ? <Loading isFullScreen /> : null }
        </>
    );
};

export default CharacterEquipmentsModal;
