import strings from '@resources/strings';
import * as GLOBAL_VARS from '@resources/Globals';
import { get } from './Http';

const ATOMIC_API_PATH = '/atomicassets/v1';
const ASSETS_PATH = '/assets';

const noop = () => {};

export function getCharacterCreatorNfts(account, onSuccess = noop, onError = noop) {
    if (!account) {
        return;
    }
    const success = (result) => {
        if (result) {
            if (result.error && result.error.message) {
                onError(result.error.message);
            } else {
                onSuccess(result.data);
            }
        } else {
            onError(strings.unknownErrorOccurred);
        }
    };

    get(GLOBAL_VARS.AA_ENDPOINT + ATOMIC_API_PATH + ASSETS_PATH + `?collection_name=${GLOBAL_VARS.COLLECTION_NAME}&owner=${account}&template_whitelist=${GLOBAL_VARS.LOGIN_ENABLER_NFTS}&order=asc&sort=asset_id`, success, onError);
}

export async function getNft(id) {
    if (!id) {
        return;
    }

    let request = {
        headers: { "Content-Type": "application/json;" },
        method: 'GET'
    };

    const response = await fetch(`${GLOBAL_VARS.AA_ENDPOINT}${ATOMIC_API_PATH}${ASSETS_PATH}/${id}`, request);

    if (!response.ok) {
        throw new Error(`Code: ${response.statusCode} => ${await response.text()}`);
    }

    const result = await response.json();

    return result.data;
}

export async function transferNftToCustodyAccount(activeUser, assetId, uuid, transfered = noop, error = noop) {
    try {
        const response = await activeUser.signTransaction(
            {
                actions: [
                    {
                        account: GLOBAL_VARS.ATOMICASSETS_ACCOUNT,
                        name: GLOBAL_VARS.ATOMICASSETS_TRANSFER_ACTION,
                        authorization: [{
                            actor: activeUser.accountName,
                            permission: activeUser.requestPermission
                        }],
                        data: {
                            from: activeUser.accountName,
                            to: GLOBAL_VARS.CUSTODY_ACCOUNT,
                            asset_ids: [ assetId ],
                            memo: uuid
                        }
                    }
                ]
            },
            {
                blocksBehind: 3,
                expireSeconds: 30
            }
        );

        transfered(response);
    } catch (e) {
        console.debug(e);
        error(e.message);
    }
}

export const burnNft = async (activeUser, assetId, burned = noop, error = noop) => {
    try {
        const response = await activeUser.signTransaction(
            {
                actions: [
                    {
                        account: GLOBAL_VARS.ATOMICASSETS_ACCOUNT,
                        name: GLOBAL_VARS.ATOMICASSETS_BURN_ACTION,
                        authorization: [{
                            actor: activeUser.accountName,
                            permission: activeUser.requestPermission
                        }],
                        data: {
                            asset_owner: activeUser.accountName,
                            asset_id: assetId
                        }
                    }
                ]
            },
            {
                blocksBehind: 3,
                expireSeconds: 30
            }
        );

        burned(response);

    } catch (e) {
        console.debug(e);
        error(e.message);
    }
};

export function activeUserFromSession(session) {
    return {
        accountName: session?.actor?.toString(),
        requestPermission: session?.permission?.toString(),
        signTransaction: session.transact.bind(session)
    };
}
