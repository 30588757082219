import styled from '@emotion/styled';

const CustomLink = styled.a(({ theme, header, tab, isActive }) => {
    let typography = header ? theme.typography.h5 : theme.typography.p;
    let headerStyle = header ? {
        marginLeft: 0,
        marginBottom: theme.spacing.s,
        [theme.mediaQuery.tabletLandscapeUp]: {
            marginBottom: 0
        }
    } : {};
    let padding = tab ? {
        padding: `${theme.spacing.xs} ${theme.spacing.s}`
    } : {};

    return {
        ...typography,
        color: isActive() ? theme.colors.primary.gradientStart : theme.colors.primary.main,
        borderBottom: isActive() ? `solid ${theme.colors.primary.light} 3px` : 'none',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        opacity: 1,
        '&:hover': {
            color: theme.colors.primary.gradientEnd,
            cursor: 'pointer'
        },
        ...padding,
        ...headerStyle
    };
});

function Link({ href, children, header, tab, activeWhen, onClick, match, history, location, style, ...otherProps }) {
    function isActive() {
        // if (href) {
        //     return location.pathname.includes(href.slice(2));
        // }
    }

    return (
        children ?
            <CustomLink href={href} header={header} tab={tab} isActive={isActive} onClick={onClick} {...otherProps}>{children}</CustomLink>
            :
            null
    );
}

export default Link;
