import { useState } from 'react';
import { useTheme } from '@emotion/react';

import { activeUserFromSession, burnNft } from '@api/AtomicHubApi';
import { consumeNft } from '@api/Api';

import Button from '@components/Button';
import ConfirmationModal from '@components/ConfirmationModal';
import Loading from '@components/Loading';

import strings from '@resources/strings';
import { NFT_TYPES } from '@resources/DataModel';
import { useChain } from "@hooks/ChainContext";

const CONSUMABLE_TYPES = {
    pins: "Pins",
    staminaReset: "Stamina Reset",
    rankUpgrade: "Rank Upgrade",
    prestigeUpgrade: "Prestige Upgrade"
};

const ConsumeNft = ({ nft = null, reload = () => { } }) => {
    const { session } = useChain();
    const [ showConsumeNftModal, setShowConsumeNftModal ] = useState(false);
    const [ showSuccessModal, setShowSuccessModal ] = useState(false);
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ loading, setLoading ] = useState(false);

    const theme = useTheme();

    const callBurnNft = () => {
        const success = () => {
            setLoading(false);
            setShowConsumeNftModal(false);
            setShowSuccessModal(true);
        };

        const error = (response) => {
            setErrorMessage(response);
            setShowConsumeNftModal(false);
            setLoading(false);
            setShowErrorModal(true);
        };

        burnNft(activeUserFromSession(session), nft?.nft_id, success, error);
    };

    const sendNftToBeConsumed = () => {
        const success = () => {
            callBurnNft();
        };

        const error = (response) => {
            setErrorMessage(response);
            setShowConsumeNftModal(false);
            setLoading(false);
            setShowErrorModal(true);
        };

        setLoading(true);
        consumeNft({ inventory_uuid: nft?.uuid }, success, error);
    };

    const getButtonLabel = () => {
        let label = strings.use;
        if (nft?.inventory_type === NFT_TYPES.equipment || nft?.inventory_role === CONSUMABLE_TYPES.rankUpgrade || nft?.inventory_role === CONSUMABLE_TYPES.prestigeUpgrade) {
            label = strings.comingSoon;
        } else if (nft?.to_be_burned) {
            label = strings.beingUsed;
        }
        return label;
    };

    const isButtonDisabled = () => {
        return nft?.inventory_type === NFT_TYPES.equipment || nft?.inventory_role === CONSUMABLE_TYPES.rankUpgrade || nft?.inventory_role === CONSUMABLE_TYPES.prestigeUpgrade || nft?.to_be_burned;
    };

    return (
        <>
            <Button style={{ width: theme.size.characterLayerSizeS }} disabled={isButtonDisabled()} onClick={() => setShowConsumeNftModal(true)}>{getButtonLabel()}</Button>
            <ConfirmationModal
                title={`${strings.use} ${nft?.name}`}
                show={showConsumeNftModal}
                onConfirm={sendNftToBeConsumed}
                onClose={() => setShowConsumeNftModal(false)}
                confirmationButtonLabel={strings.use}
            >
                <p>{strings.consumeNftConfirmationMessage.replace('{0}', nft?.name)}</p>
                <p>{strings.consumeNftInformationMessage.replace('{0}', nft?.consume_to === "USER" ? strings.yourAccount : strings.selectedCharacter)}</p>
            </ConfirmationModal>
            <ConfirmationModal
                title={strings.success}
                show={showSuccessModal}
                onConfirm={() => { setShowSuccessModal(false); reload(); }}
                onClose={() => { setShowSuccessModal(false); reload(); }}
                noCancel
            >
                {strings.consumeNftSuccessMessage}
            </ConfirmationModal>
            <ConfirmationModal
                title={strings.error}
                show={showErrorModal}
                onConfirm={() => {setShowErrorModal(false); setErrorMessage("");}}
                onClose={() => {setShowErrorModal(false); setErrorMessage("");}}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
            { loading ? <Loading isFullScreen /> : null }
        </>
    );
};

export default ConsumeNft;
