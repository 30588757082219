import styled from "@emotion/styled";
import Button from '@components/Button';

import strings from "@resources/strings";
import { useEffect, useState } from "react";

const CategorySpan = styled.span(({ theme }) => ({
    borderRadius: theme.borderRadius.xxl,
    width: `calc(90% - (2 * ${theme.size.buttonHeight}))`,
    lineHeight: theme.size.buttonHeight,
    height: `calc(${theme.size.buttonHeight} - (${theme.size.buttonBorder} * 2))`,
    border: `solid ${theme.size.buttonBorder} ${theme.colors.primary.main}`,
    borderColor: theme.colors.primary.main,
    textAlign: 'center',
    textTransform: 'uppercase'
}));

const SelectorContainer = styled.div(({ theme }) => ({
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    marginTop: theme.spacing.xxs
}));

const ImageContainer = styled.img(({ theme }) => ({
    display: 'flex',
    padding: theme.spacing.xxxs
}));

function PieceSelector({ category = "", selectedPiece = "", pieces = {}, onSelectedPieceChange = () => {} }) {
    const [ selectedIndex, setSelectedIndex ] = useState(0);

    useEffect(() => {
        setSelectedIndex(pieces.findIndex(p => p.label === selectedPiece));
    }, [ selectedPiece ]);

    const onPieceChanged = (newSelected) => {
        if (newSelected < 0) {
            newSelected = pieces.length - 1;
        }

        if (newSelected >= pieces.length) {
            newSelected = 0;
        }
        onSelectedPieceChange(category, pieces[newSelected].label);
    };

    return (
        <SelectorContainer>
            <Button outline onClick={() => onPieceChanged(selectedIndex - 1)}>
                <ImageContainer style={{ transform: 'rotateY(180deg)' }} alt={strings.previous} src='/assets/arrow.svg' />
            </Button>
            <CategorySpan> {category} {selectedIndex + 1} </CategorySpan>
            <Button outline onClick={() => onPieceChanged(selectedIndex + 1)}>
                <ImageContainer alt={strings.next} src='/assets/arrow.svg' />
            </Button>
        </SelectorContainer>
    );
}

export default PieceSelector;
