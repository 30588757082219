import { NONE, INVENTORY_COLUMNS, INVENTORY_ROLE_TYPES, NFT_TYPES, RARITY_TYPES } from '@resources/DataModel';
import strings from '@resources/strings';

export const orderOptions = [
    { label: strings.name, value: INVENTORY_COLUMNS.name },
    { label: strings.rarity, value: INVENTORY_COLUMNS.rarity },
    { label: strings.category, value: INVENTORY_COLUMNS.inventoryRole }
];

export const sortOptions = [
    { label: strings.ascending, value: "+" },
    { label: strings.descending, value: "-" }
];
export const nftTypeFilterOptions = [
    { label: strings.allNftTypes, value: NONE },
    { label: strings.consumable, value: NFT_TYPES.consumable },
    { label: strings.equipment, value: NFT_TYPES.equipment }
];

export const rarityFilterOptions = [
    { label: strings.allRarities, value: NONE },
    { label: strings.common, value: RARITY_TYPES.common },
    { label: strings.rare, value: RARITY_TYPES.rare },
    { label: strings.epic, value: RARITY_TYPES.epic },
    { label: strings.legendary, value: RARITY_TYPES.legendary },
    { label: strings.mythical, value: RARITY_TYPES.mythical }
];

export const inventoryRoleFilterOptions = [
    { label: strings.allCategories, value: NONE },
    { label: strings.ball, value: INVENTORY_ROLE_TYPES.ball },
    { label: strings.wristGuard, value: INVENTORY_ROLE_TYPES.wristGuard },
    { label: strings.shoes, value: INVENTORY_ROLE_TYPES.shoes },
    { label: strings.staminaBarBox, value: INVENTORY_ROLE_TYPES.staminaBarBox },
    { label: strings.pins, value: INVENTORY_ROLE_TYPES.pins },
    { label: strings.staminaReset, value: INVENTORY_ROLE_TYPES.staminaReset },
    { label: strings.rankUpgrade, value: INVENTORY_ROLE_TYPES.rankUpgrade },
    { label: strings.prestigeUpgrade, value: INVENTORY_ROLE_TYPES.prestigeUpgrade }
];
