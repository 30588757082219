import { getServerAccountInfo } from '@hooks/AccountContext';
import { SERVER_URL } from '@resources/Globals';
import strings from '@resources/strings';
import { CONTENT_TYPES, get, post, patchWithAuth, postWithAuth, delWithAuth, getWithAuth } from './Http';

const serverAddress = SERVER_URL;

const endpoints = {
    activateAccount: '/activate-account',
    auth: '/auth',
    campaign: '/campaign',
    character: '/character',
    characterArt: '/character-art',
    files: '/files',
    image: '/image',
    list: '/list',
    login: '/login',
    logout: '/logout',
    linkChainAccount: '/link-chain-account',
    me: '/me',
    passwordRecovery: '/password-recovery',
    usernameRecovery: '/request-username-recovery',
    refresh: '/refresh',
    register: '/register',
    requestAccountActivation: '/request-account-activation',
    resetPassword: '/reset-password',
    upload: '/upload',
    user: '/user',
    users: '/users',
    validate: '/validate',
    validateCharacterCreation: '/validate-character-creation',
    storePinata: '/upload-ipfs',
    forceEth: '/force-eth',
    attachTxHash: '/attach-eth-tx',
    game: '/game',
    newMatch: '/new-match',
    allStamina: '/all-stamina',
    nextFrame: '/next-frame',
    skipGame: '/skip-game',
    gameCharacter: '/game-character',
    selectCharacter: '/select-character',
    selectedCharacter: '/selected-character',
    tiers: '/tiers',
    upgradeTier: '/upgrade-tier',
    pins: '/pins',
    stats: '/stats',
    inventory: '/inventory',
    consume: '/consume',
    equip: '/equip',
    unequip: '/unequip'
};

const generateQueryString = (queryObj = null) => {
    if (queryObj) {
        const query = new URLSearchParams();

        for (const key in queryObj) {
            if (queryObj[key] != null) {
                if (Array.isArray(queryObj[key])) {
                    for (const value of queryObj[key]) {
                        query.append(key, value);
                    }
                } else {
                    query.append(key, queryObj[key]);
                }
            }
        }
        return `?${query.toString()}`;
    }
    return "";
};

// Auth API

export const registerUser = async (payload, onSuccess = () => { }, onError = () => { }) => {
    post(serverAddress + endpoints.auth + endpoints.register, payload, onSuccess, onError);
};

export const resetUserPassword = async (payload, onSuccess = () => { }, onError = () => { }) => {
    post(serverAddress + endpoints.auth + endpoints.resetPassword, payload, onSuccess, onError);
};

export const requestToResetUserPassword = async (payload, onSuccess = () => { }, onError = () => { }) => {
    post(serverAddress + endpoints.auth + endpoints.passwordRecovery, payload, onSuccess, onError);
};

export const requestToRecoverUsername = async (payload, onSuccess = () => { }, onError = () => { }) => {
    post(serverAddress + endpoints.auth + endpoints.usernameRecovery, payload, onSuccess, onError);
};

export const requestAccountActivation = async (payload, onSuccess = () => { }, onError = () => { }) => {
    post(serverAddress + endpoints.auth + endpoints.requestAccountActivation, payload, onSuccess, onError);
};

export const loginUser = async (payload, onSuccess = () => { }, onError = () => { }) => {
    let formBody = [];
    for (let property in payload) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(payload[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    post(serverAddress + endpoints.auth + endpoints.login, formBody, onSuccess, onError, () => { }, CONTENT_TYPES.xWwwFormUrlEncoded);
};

export const logoutUser = (onSuccess = () => { }, onError = () => { }) => {
    postWithAuth(serverAddress + endpoints.auth + endpoints.logout, {}, onSuccess, onError);
};

export const validateUser = async (onSuccess = () => { }, onError = () => { }) => {
    const serverAccountInfo = getServerAccountInfo();

    if (serverAccountInfo == null) {
        onError(strings.userNotLoggedIn);
        return;
    }

    get(serverAddress + endpoints.auth + endpoints.validate, onSuccess, onError, () => { }, CONTENT_TYPES.json, serverAccountInfo.access_token);
};

export const refreshAuthenticationToken = async (payload, onSuccess = () => { }, onError = () => { }) => {
    let formBody = [];
    for (let property in payload) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(payload[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    post(serverAddress + endpoints.auth + endpoints.refresh, formBody, onSuccess, onError, () => { }, CONTENT_TYPES.xWwwFormUrlEncoded);
};

export const activateAccount = async (payload, onSuccess = () => { }, onError = () => { }) => {
    get(serverAddress + endpoints.auth + endpoints.login, payload, onSuccess, onError);
};

export const validateCharacterCreation = (onSuccess = () => { }, onError = () => { }) => {
    get(serverAddress + endpoints.campaign + endpoints.validateCharacterCreation, onSuccess, onError);
};

export const updateChainAccount = async (payload, onSuccess = () => { }, onError = () => { }) => {
    patchWithAuth(serverAddress + endpoints.me + endpoints.linkChainAccount, payload, onSuccess, onError);
};

export const getPins = (onSuccess = () => { }, onError = () => { }) => {
    getWithAuth(serverAddress + endpoints.me + endpoints.pins, onSuccess, onError);
};

// Character Creator Event API

export const saveCharacter = async (payload, onSuccess = () => { }, onError = () => { }) => {
    postWithAuth(serverAddress + endpoints.character, payload, onSuccess, onError);
};

export const saveNftImagePinata = async (uuid, onSuccess = () => { }, onError = () => { }) => {
    postWithAuth(serverAddress + endpoints.character + `/${uuid}` + endpoints.storePinata, {}, onSuccess, onError);
};

export const updateCharacter = async (payload, uuid, onSuccess = () => { }, onError = () => { }) => {
    patchWithAuth(serverAddress + endpoints.character + `/${uuid}`, payload, onSuccess, onError);
};

export const deleteCharacter = async (uuid, onSuccess = () => { }, onError = () => { }) => {
    delWithAuth(serverAddress + endpoints.character + `/${uuid}`, onSuccess, onError);
};

export const deleteEthCharacter = async (uuid, onSuccess = () => { }, onError = () => { }) => {
    delWithAuth(serverAddress + endpoints.character + endpoints.forceEth + `/${uuid}`, onSuccess, onError);
};

export const uploadCharacter = (uuid, payload, onSuccess = () => { }, onError = () => { }) => {
    postWithAuth(serverAddress + endpoints.character + `/${uuid}` + endpoints.image, payload, onSuccess, onError, () => { }, CONTENT_TYPES.multipartFormData);
};

export const addTxHashCharacter = (uuid, tx_hash, onSuccess = () => { }, onError = () => { }) => {
    postWithAuth(serverAddress + endpoints.character + `/${uuid}` + endpoints.attachTxHash, { eth_transaction: tx_hash }, onSuccess, onError);
};

export const getCharacterPieceImages = (onSuccess = () => { }, onError = () => { }) => {
    getWithAuth(serverAddress + endpoints.characterArt + '/', onSuccess, onError);
};

export const getCreatedCharacters = (uuid, onSuccess = () => { }, onError = () => { }) => {
    getWithAuth(serverAddress + endpoints.character + endpoints.user + '/' + uuid, onSuccess, onError);
};

// Game API

export const getGameHistory = (onSuccess = () => { }, onError = () => { }) => {
    getWithAuth(serverAddress + endpoints.game, onSuccess, onError);
};

export const getOngoingMatch = (onSuccess = () => { }, onError = () => { }) => {
    getWithAuth(serverAddress + endpoints.game + "?finished=false", onSuccess, onError);
};

export const newMatch = (onSuccess = () => { }, onError = () => { }) => {
    postWithAuth(serverAddress + endpoints.game + endpoints.newMatch, {}, onSuccess, onError);
};

export const consumeAllStamina = (payload, onSuccess = () => { }, onError = () => { }) => {
    postWithAuth(serverAddress + endpoints.game + endpoints.newMatch + endpoints.allStamina, payload, onSuccess, onError);
};

export const getMatch = (uuid, onSuccess = () => { }, onError = () => { }) => {
    getWithAuth(serverAddress + endpoints.game + '/' + uuid, onSuccess, onError);
};

export const getNextFrame = (uuid, onSuccess = () => { }, onError = () => { }) => {
    getWithAuth(serverAddress + endpoints.game + '/' + uuid + endpoints.nextFrame, onSuccess, onError);
};

export const skipGame = (uuid, onSuccess = () => { }, onError = () => { }) => {
    postWithAuth(serverAddress + endpoints.game + '/' + uuid + endpoints.skipGame, {}, onSuccess, onError);
};

// Game Characters API

export const getGameCharactersList = (queryObj = null, onSuccess = () => { }, onError = () => { }) => {
    getWithAuth(serverAddress + endpoints.gameCharacter + generateQueryString(queryObj), onSuccess, onError);
};

export const getGameCharacter = (uuid, onSuccess = () => { }, onError = () => { }) => {
    getWithAuth(serverAddress + endpoints.gameCharacter + '/' + uuid, onSuccess, onError);
};

export const selectGameCharacter = (uuid, onSuccess = () => { }, onError = () => { }) => {
    patchWithAuth(serverAddress + endpoints.gameCharacter + '/' + uuid + endpoints.selectCharacter, {}, onSuccess, onError);
};

export const getSelectedGameCharacter = (onSuccess = () => { }, onError = () => { }) => {
    getWithAuth(serverAddress + endpoints.gameCharacter + endpoints.selectedCharacter, onSuccess, onError);
};

export const getGameCharacterTiers = (onSuccess = () => { }, onError = () => { }) => {
    getWithAuth(serverAddress + endpoints.gameCharacter + endpoints.tiers, onSuccess, onError);
};

export const requestGameCharacterTierUpgrade  = (uuid, onSuccess = () => { }, onError = () => { }) => {
    patchWithAuth(serverAddress + endpoints.gameCharacter + endpoints.upgradeTier, { game_character_uuid: uuid }, onSuccess, onError);
};

export const assignStatPoints = (uuid, payload, onSuccess = () => { }, onError = () => { }) => {
    patchWithAuth(serverAddress + endpoints.gameCharacter + '/' + uuid + endpoints.stats, payload, onSuccess, onError);
};

export const getInventoryNfts = (queryObj = null, onSuccess = () => { }, onError = () => { }) => {
    getWithAuth(serverAddress + endpoints.gameCharacter + endpoints.inventory + generateQueryString(queryObj), onSuccess, onError);
};

export const consumeNft = (payload, onSuccess = () => { }, onError = () => { }) => {
    postWithAuth(serverAddress + endpoints.gameCharacter + endpoints.inventory + endpoints.consume, payload, onSuccess, onError);
};

export const requestGameCharacterEquipmentChange  = (payload, onSuccess = () => { }, onError = () => { }) => {
    patchWithAuth(serverAddress + endpoints.gameCharacter + endpoints.equip, payload, onSuccess, onError);
};

export const requestGameCharacterEquipmentRemoval  = (payload, onSuccess = () => { }, onError = () => { }) => {
    patchWithAuth(serverAddress + endpoints.gameCharacter + endpoints.unequip, payload, onSuccess, onError);
};
