import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import PieceLayer from '@components/PieceLayer';
import { pieces, categories } from '@resources/CharacterPieces';

const Content = styled.div(({ theme, height }) => ({
    width: '90vw',
    height: height,
    margin: `${theme.spacing.xxs} 0px`,
    borderRadius: theme.borderRadius.xl,
    overflowY: 'hidden'
}));

const PreviewCharactersContent = styled.div(({ theme, height, styles }) => ({
    minWidth: height,
    height: height,
    display: 'flex',
    justifyContent: 'center',
    alignitems: 'center',
    whiteSpace: 'nowrap',
    overflowY: 'hidden',
    ...styles
}));

const LayerContainer = styled.div(({ theme, height }) => ({
    position: 'relative',
    width: height,
    height: height,
    maxWidth: height,
    maxHeight: height,
    margin: `0px ${theme.spacing.xxs}`
}));

const PreviewCharacters = ({ styles, height = 128, charactersAmount = 50, animation = "translate_1", ...otherProps }) => {
    const [ selectedPieces, setSelectedPieces ] = useState([]);

    useEffect(() => {
        let characters = [];

        for (let i = 0; i < charactersAmount; i++) {
            characters.push(randomizeSelectedPieces());
        }

        setSelectedPieces(characters);
    }, []);

    const randomizeSelectedPieces = () => {
        let randomizedPieces = {};

        for (let category of categories) {
            randomizedPieces[category] = pieces[category][Math.floor(Math.random() * pieces[category].length)].label;
        }

        return randomizedPieces;
    };

    return (
        <Content className={animation} height={height}>
            <PreviewCharactersContent height={height} styles={styles}>
                {selectedPieces.map(image => (
                    <LayerContainer key={selectedPieces.indexOf(image)} height={height}>
                        {categories.map((category) => <PieceLayer key={category} category={category} selectedPiece={image[category]} pieces={pieces[category]} pieceIndex={categories.indexOf(category)} />)}
                    </LayerContainer>)
                )}
            </PreviewCharactersContent>
        </Content>
    );
};

export default PreviewCharacters;
