import strings from "@resources/strings";

export const DEFAULT_PAGE_SIZE = 30;

export const NONE = "None";

export const NFT_TYPES = {
    consumable: strings.consumable,
    equipment: strings.equipment
};

export const RARITY_TYPES = {
    common: strings.common,
    rare: strings.rare,
    epic: strings.epic,
    legendary: strings.legendary,
    mythical: strings.mythical
};

export const INVENTORY_ROLE_TYPES = {
    ball: strings.ball,
    wristGuard: strings.wristGuard,
    shoes: strings.shoes,
    staminaBarBox: strings.staminaBarBox,

    pins: strings.pins,
    staminaReset: strings.staminaReset,
    rankUpgrade: strings.rankUpgrade,
    prestigeUpgrade: strings.prestigeUpgrade
};

export const QUERY_KEYS = {
    order: "__order",
    page: "__page",
    pageSize: "__page_size"
};

export const INVENTORY_COLUMNS = {
    name: "name",
    rarity: "rarity",
    inventoryType: "inventory_type",
    inventoryRole: "inventory_role",
    slotRole: "slot_role",
    statProperty: "stat_property"
};
