import styled from '@emotion/styled';

const PaginationContainer = styled.div(() => ({
    display: 'inline-block'
}));

const PaginationButton = styled.button(({ active, theme }) => ({
    backgroundColor: active ? theme.colors.primary.main : "transparent",
    color: active ? theme.colors.common.text : theme.colors.primary.main,
    margin: theme.spacing.s,
    height: 30,
    width: 30,
    borderRadius: theme.borderRadius.l,
    borderStyle: "none",
    cursor: "pointer",
    [ '&:not(:first-child)' ]: {
        marginLeft: theme.spacing.xxxs
    },
    [ '&:not(:last-child)']: {
        marginRight: theme.spacing.xxxs
    },
    [ ':disabled' ]: {
        color: theme.colors.common.disabled,
        cursor: "not-allowed"
    },
    [ '&:hover' ]: {
        backgroundColor: active ? `${theme.colors.primary.main}BB` : theme.colors.common.backgroundLighter
    }
}));

const ELLIPSIS = '...';

const Pagination = ({ currentPage = 1, totalPages = 1, onPageChange = () => {}, ...otherProps }) => {
    const changePage = (newPage) => {
        if (newPage !== currentPage && newPage >= 1 && newPage <= totalPages) {
            onPageChange(newPage);
        }
    };

    const renderPageNumbers = () => {
        let pageNumbersList = [];
        let initialPageRange = currentPage - 2 < 1 ? 1 : currentPage - 2;
        let finalPageRange = currentPage + 2 > totalPages ? totalPages : currentPage + 2;

        if (initialPageRange !== 1) {
            pageNumbersList.push(<PaginationButton key={1} active={currentPage === 1} onClick={() => changePage(1)}>{1}</PaginationButton>);
        }
        if (initialPageRange > 2) {
            pageNumbersList.push(ELLIPSIS);
        }

        for (let i = initialPageRange; i <= finalPageRange; i++) {
            pageNumbersList.push(<PaginationButton key={i} active={currentPage === i} onClick={() => changePage(i)}>{i}</PaginationButton>);
        }

        if (finalPageRange < totalPages - 1) {
            pageNumbersList.push(ELLIPSIS);
        }
        if (finalPageRange !== totalPages) {
            pageNumbersList.push(<PaginationButton key={totalPages} active={currentPage === totalPages} onClick={() => changePage(totalPages)}>{totalPages}</PaginationButton>);
        }

        return pageNumbersList;
    };

    return (
        <PaginationContainer {...otherProps}>
            <PaginationButton disabled={currentPage === 1} onClick={() => changePage(currentPage-1)}>{'<'}</PaginationButton>
            {renderPageNumbers()}
            <PaginationButton disabled={currentPage === totalPages} onClick={() => changePage(currentPage+1)}>{'>'}</PaginationButton>
        </PaginationContainer>
    );
};

export default Pagination;
