const breakpoints = [ 576, 768, 992, 1366 ];

const theme = {
    colors: {
        common: {
            text: '#FFFFFF',
            background: '#0b0d21',
            backgroundLighter: '#2b2d41',
            disabled: '#9f9494',
            border: '#9d9d9e'
        },
        primary: {
            main: '#f02a1e',
            gradientStart: '#ed1c24',
            gradientEnd: '#f74a11'
        },
        secondary: {
            main: '#efc944'
        },
        error: {
            main: '#CA1515'
        },
        warning: {
            main: '#CAAC15'
        },
        success: {
            main: '#1E794B'
        },
        info: {
            main: '#007bff'
        },
        placeholder: {
            main: '#CCCCCC'
        },
        characterStats: {
            Stamina: '#EFC944',
            Power: '#EE5555',
            Control: '#3347F5',
            Luck: '#43D775',
            Floor: '#A74FED',
            Ceiling: '#A74FED',
            XP: '#FFFFFF'
        },
        rarities: {
            Common: {
                start: '#9d9d9e',
                end: '#9d9d9e'
            },
            Rare: {
                start: '#6083c2',
                end: '#4866ae'
            },
            Epic: {
                start: '#a77ab4',
                end: '#805da4'
            },
            Legendary: {
                start: '#f6a657',
                end: '#f49738'
            },
            Mythical: {
                start: '#f0ca35',
                end: '#f0ca35'
            }
        }
    },
    spacing: {
        xxxxs: '2px',
        xxxs: '4px',
        xxs: '8px',
        xs: '16px',
        s: '24px',
        m: '32px',
        l: '40px',
        xl: '62px',
        xxl: '80px',
        xxxl: '106px'
    },
    borderRadius: {
        s: '4px',
        m: '8px',
        l: '16px',
        xl: '24px',
        xxl: '46px'
    },
    mediaQuery: {
        mobileOnly: `@media (max-width: ${breakpoints[0] - 1}px)`,
        largeMobileOnly: `@media (max-width: ${breakpoints[1] - 1}px)`,
        largeMobileUp: `@media (min-width: ${breakpoints[0]}px)`,
        tabletUp: `@media (min-width: ${breakpoints[1]}px)`,
        tabletLandscapeUp: `@media (min-width: ${breakpoints[2]}px)`,
        desktopUp: `@media (min-width: ${breakpoints[3]}px)`
    },
    typography: {
        fontFamily: "'Poppins', sans-serif",
        h1: {
            fontSize: '48px',
            lineHeight: '130%',
            fontWeight: 300
        },
        h2: {
            fontSize: '32px',
            lineHeight: '130%',
            fontWeight: 700
        },
        h3: {
            fontSize: '28px',
            lineHeight: '130%',
            fontWeight: 700
        },
        h4: {
            fontSize: '24px',
            lineHeight: '130%',
            fontWeight: 500
        },
        h5: {
            fontSize: '20px',
            lineHeight: '130%',
            fontWeight: 500
        },
        h6: {
            fontSize: '18px',
            lineHeight: '150%',
            fontWeight: 500
        },
        p: {
            fontSize: '18px',
            lineHeight: '180%',
            fontWeight: 300
        },
        pBold: {
            fontSize: '18px',
            lineHeight: '160%',
            fontWeight: 600,
            opacity: 0.9
        },
        pTiny: {
            fontSize: '14px',
            lineHeight: '150%',
            fontWeight: 400,
            opacity: 0.9
        },
        forum: {
            fontFamily: 'Forum',
            lineHeight: '130%',
            letterSpacing: '2px',
            fontWeight: 'bold',
            [`@media (max-width: ${breakpoints[1] - 1}px)`]: {
                fontSize: '28px'
            }
        }
    },
    size: {
        headerHeight: '40px',
        footerHeight: '207.16px',
        mintWidth: '60px',
        mintHeight: '30px',
        assetWidth: '270px',
        secondaryMarketLogosWidth: '150px',
        assetWidthTiny: '240px',
        loadingHeight: '100px',
        loadingWidth: '100px',
        loadingBorder: '8px',
        gridItemButtonsWidth: 200,
        dropdownWidth: 180,
        templateWidth: 100,
        templateHeight: 100,
        buttonHeight: '46px',
        buttonBorder: '2px',
        nftDescriptionMaxWidth: 280,
        characterLayerSizeXS: 160,
        characterLayerSizeS: 320,
        characterLayerSize: 512,
        characterLayerSizeL: 1024,
        characterLayerSizeXL: 1536,
        selectorsContainerWidth: 320,
        defaultSelectWidth: 180
    },
    shadow: {
        dropShadow: '5px 5px 30px 2px rgba(0,0,0,0.2)',
        dropShadowGridButtons: '4px 4px 10px 4px #000000',
        dropShadowCard: '4px 4px 8px 2px #00000077'
    },
    transition: {
        default: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)'
    },
    select: {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected || state.isFocused ? theme.colors.primary.main : theme.colors.primary.main,
            backgroundColor: state.isSelected || state.isFocused ? theme.colors.common.backgroundLighter : theme.colors.common.background,
            border: `solid 1px ${theme.colors.primary.main}`
        }),
        control: (base) => ({
            ...base,
            border: `1px solid ${theme.colors.primary.main}`,
            backgroundColor: theme.colors.common.background,
            borderRadius: theme.borderRadius.xxl,
            '&:hover': { borderColor: theme.colors.primary.gradientEnd },
            boxShadow: 'none'
        }),
        placeholder: (base) => ({
            ...base,
            color: theme.colors.primary.main
        }),
        input: (base) => ({
            ...base,
            color: theme.colors.primary.main
        }),
        singleValue: (base) => ({
            ...base,
            color: theme.colors.primary.main
        }),
        dropdownIndicator: (base) => ({
            ...base,
            color: theme.colors.primary.main,
            '&:hover': { color: theme.colors.primary.gradientEnd }
        }),
        clearIndicator: (base) => ({
            ...base,
            color: theme.colors.primary.main,
            '&:hover': { color: theme.colors.primary.gradientEnd }
        }),
        indicatorSeparator: (base) => ({
            ...base,
            backgroundColor: theme.colors.primary.main
        }),
        menu: (base) => ({
            ...base,
            backgroundColor: theme.colors.primary.main
        })
    }
};

export default theme;
