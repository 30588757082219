import styled from "@emotion/styled";
import { useEffect } from 'react';

const ModalBackgroundOverlay = styled.div(() => ({
    backgroundColor: 'rgba(6,2,1,0.5)',
    position: 'fixed',
    display: 'flex',
    verticalAlign: 'middle',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 999
}));

const ModalContainer = styled.div(({ theme, style }) => ({
    color: theme.colors.common.text,
    backgroundColor: theme.colors.common.background,
    padding: theme.spacing.s,
    border: `solid ${theme.colors.primary.main} 1px`,
    borderRadius: theme.borderRadius.l,
    fontWeight: 'bold',
    width: '70vw',
    minWidth: '300px',
    maxHeight: '85vh',
    overflowX: 'auto',
    margin: 'auto',
    textAlign: 'center',
    ...style
}));

const XCloseButtonContainer = styled.div(({ theme }) => ({
    marginLeft: 'auto',
    marginBottom: theme.spacing.xxs,
    cursor: 'pointer',
    width: theme.spacing.xs
}));

const XCloseButton = styled.span(({ theme }) => ({
}));

function Modal({ children, show, onClose, style, ...otherProps }) {
    useEffect(() => {
        document.body.style.overflow = show ? 'hidden' : 'auto';
    }, [ show ]);

    return (<>
        {show ?
            <ModalBackgroundOverlay>
                <ModalContainer {...otherProps} style={style} >
                    <XCloseButtonContainer onClick={onClose}>
                        <XCloseButton>x</XCloseButton>
                    </XCloseButtonContainer>
                    {children}
                </ModalContainer>
            </ModalBackgroundOverlay>
            :
            null}
    </>);
}

export default Modal;
