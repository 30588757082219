import styled from "@emotion/styled";
import { useEffect } from 'react';
import strings from '@resources/strings';
import Button from '@components/Button';
import Modal from '@components/Modal';

const Title = styled.div(({ theme }) => ({
    ...theme.typography.h3,
    marginBottom: theme.spacing.m,
    display: 'flex',
    justifyContent: 'center'
}));

const ButtonContainer = styled.div(({ theme }) => ({
    margin: `${theme.spacing.s} ${theme.spacing.s} ${theme.spacing.xxs} ${theme.spacing.s}`,
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: 16,
    flexDirection: 'column',
    [theme.mediaQuery.tabletUp]: {
        flexDirection: 'row'
    }
}));

function ConfirmationModal({ children, show, onConfirm, onClose, title, noCancel = false, confirmationButtonLabel = strings.ok, cancelButtonLabel = strings.cancel, style, ...otherProps }) {
    useEffect(() => {
        document.body.style.overflow = show ? 'hidden' : 'auto';
    }, [ show ]);

    return (
        <Modal show={show} onClose={onClose} style={{ ...style, width: '40vw' }}>
            <Title>{title}</Title>
            {children}
            <ButtonContainer>
                <Button style={{ width: '100%' }} onClick={onConfirm}>{confirmationButtonLabel}</Button>
                {noCancel ? null : <Button style={{ width: '100%' }} outline onClick={onClose}>{cancelButtonLabel}</Button>}
            </ButtonContainer>
        </Modal>
    );
}

export default ConfirmationModal;
