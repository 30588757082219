import { useState, useEffect } from "react";
import strings from "@resources/strings";
import styled from '@emotion/styled';
import Input from "@components/Input";
import Button from "@components/Button";
import ConfirmationModal from "@components/ConfirmationModal";
import Container from "@components/Container";
import { useTheme } from "@emotion/react";
import { addTxHashCharacter, deleteCharacter, deleteEthCharacter, saveCharacter, saveNftImagePinata, updateCharacter, uploadCharacter } from "@api/Api";
import { useServerAccount } from "@hooks/AccountContext";
import { activeUserFromSession, transferNftToCustodyAccount } from "@api/AtomicHubApi";
import Loading from "@components/Loading";
import { useEthereum } from "@hooks/EthereumContext";
import { useFeatureFlags } from "@hooks/FeatureFlagsContext";
import { transferNftToCustodyAccountEth } from "@api/EthApi";
import { useSetNetwork } from "@hooks/GeneralContext";
import Link from "@components/Link";
import { CHARACTER_MINT_CONTRACT_ADDRESS, FEATURE_CHARACTER_DELETE, OPENSEA, RARIBLE } from "@resources/Globals";
import { useChain } from "@hooks/ChainContext";

const ImageContainer = styled.img(({ border = false, theme }) => ({
    width: '100%',
    maxWidth: border ? 'unset' : theme.size.characterLayerSize,
    maxHeight: border ? 'unset' : theme.size.characterLayerSize,
    borderRadius: theme.borderRadius.xl,
    margin: border ? 0 : theme.spacing.xs,
    [ theme.mediaQuery.largeMobileUp ]: {
        borderRadius: border ? theme.borderRadius.xxl : theme.borderRadius.xl
    },
    [ theme.mediaQuery.largeMobileOnly ]: {
        borderRadius: '10%'
    }
}));

const LayerContainer = styled.div(({ theme }) => ({
    margin: `${theme.spacing.xs} 0px`,
    position: 'relative',
    width: '90vw',
    height: 'calc(90vw + 150px)',
    [ theme.mediaQuery.largeMobileUp ]: {
        height: 'calc(90vw + 210px)'
    },
    [ theme.mediaQuery.tabletUp ]: {
        margin: theme.spacing.s,
        height: '672px',
        width: theme.size.characterLayerSize,
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    }
}));

const BorderContainer = styled.div(({ pieceIndex, theme }) => ({
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: pieceIndex,
    [ theme.mediaQuery.tabletUp ]: {
        top: -12
    }
}));

const BorderImageContainer = styled.img(({ theme }) => ({
    width: '100%'
}));

const CenteredParagraph = styled.div(({ theme }) => ({
    ...theme.typography.p,
    maxWidth: '60ch'
}));

function UploadYourCharacter({
    selectedNFT = "",
    uuid = null,
    onCharacterSubmitted = () => { },
    selectedCharacter = null,
    onDeleteCharacter = () => {},
    characterName,
    setNameErrorMessage = () => {}
}) {
    const { session } = useChain();
    const [ showSuccessModal, setShowSuccessModal ] = useState(false);
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ showPreviewModal, setShowPreviewModal ] = useState(false);
    const [ showConfirmationModal, setShowConfirmationModal ] = useState(false);
    const [ showDeleteConfirmation, setShowDeleteConfirmation ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ savedUuid, setSavedUuid ] = useState(null);
    const [ tokenId, setTokenId ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ image, setImage ] = useState(null);
    const [ imageBinary, setImageBinary ] = useState(null);
    const theme = useTheme();
    const { serverAccountInfo } = useServerAccount();
    const CHARACTER_CREATOR_ENABLED = useFeatureFlags().characterCreator;
    const { characterMintContract, account, providerEthers,
        accountTickets, lastCharacterId, internalRefreshCreatorAccountNftsEth } = useEthereum();
    const { connectedNetwork } = useSetNetwork();

    useEffect(() => {
        if (selectedCharacter.final_art === "") {
            setImage('/assets/pending.png');
        } else if (selectedCharacter.final_art) {
            setImage(selectedCharacter.final_art);

        }
    }, []);
    const onImageUpload = (event) => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];

            setImageBinary(img);
            setImage(URL.createObjectURL(img));
        }
    };

    const mintCharacter = async (uri, uuid) => {
        const success = (response) => {
            mintEvent();
            setSavedUuid(uuid);
            addTxHashCharacter(uuid, response.hash);
        };

        const error = (response) => {
            setLoading(false);
            setShowErrorModal(true);
            setErrorMessage(response);
        };

        const mintEvent = async () => {
            characterMintContract.on("CharacterCreated", (creator, characterId) => {
                if (Number(characterId.toString()) !== lastCharacterId && creator.toLowerCase() === account) {
                    setLoading(false);
                    setShowSuccessModal(true);
                    setTokenId(characterId.toString());
                }
            });
        };

        setLoading(true);
        await transferNftToCustodyAccountEth(uri, characterMintContract, account, providerEthers, success, error);

    };

    const storeNftImagePinata = async (uuid) => {
        const success = (response) => {
            console.debug("SUCCESS", response);
            const ipfsHash = `ipfs://${response.data.pined_json.IpfsHash}`;
            mintCharacter(ipfsHash, uuid);
        };

        const error = (response) => {
            console.debug("ERROR", response);
            setLoading(false);
            setShowErrorModal(true);
            setErrorMessage(response);
            onDeleteCharacter();
        };

        setLoading(true);
        await saveNftImagePinata(uuid, success, error);
    };

    const save = () => {
        const payload = {
            user_uuid: serverAccountInfo.uuid,
            name: characterName,
            upload_only: true
        };

        if (connectedNetwork === "wax") {
            payload.nft_hash = selectedNFT;
            payload.nft_origin = "WAX";
        }

        if (connectedNetwork === "ethereum") {
            payload.nft_origin = "ETH";
        }

        const success = (response) => {
            setSavedUuid(response.uuid);
            uploadImage(response.uuid);
        };

        const error = (response) => {
            setLoading(false);
            setShowErrorModal(true);
            setErrorMessage(response);
        };

        setLoading(true);
        saveCharacter(payload, success, error);
    };

    const update = () => {
        const payload = {
            name: characterName,
            upload_only: true,
            nft_origin: "WAX"
        };

        const success = (response) => {
            setSavedUuid(response.uuid);
            if (image !== selectedCharacter.final_art) {
                uploadImage(response.uuid);
            } else {
                setLoading(false);
                setShowSuccessModal(true);
            }
        };

        const error = (response) => {
            setLoading(false);
            setShowErrorModal(true);
            setErrorMessage(response);
        };

        setLoading(true);
        updateCharacter(payload, uuid, success, error);
    };

    const uploadImage = (imageUuid) => {
        const success = () => {
            if (uuid) {
                setLoading(false);
                setShowSuccessModal(true);
            } else {
                if (connectedNetwork === "wax") {
                    transferNft(imageUuid);
                } else {
                    setLoading(false);
                    setShowPreviewModal(true);
                }
            }
        };

        const error = (response) => {
            if (!uuid) {
                if (connectedNetwork === "wax") {
                    deleteCharacter(imageUuid);
                } else {
                    deleteEthCharacter(imageUuid);
                }
            }
            setLoading(false);
            setShowErrorModal(true);
            setErrorMessage(response);
        };

        let imgData = new FormData();
        imgData.append('upload_file', imageBinary);
        uploadCharacter(imageUuid, imgData, success, error);
    };

    const transferNft = async (uuid) => {
        const success = () => {
            setLoading(false);
            setShowSuccessModal(true);
        };

        const error = (response) => {
            deleteCharacter(uuid);
            setLoading(false);
            setShowErrorModal(true);
            setErrorMessage(response);
        };

        if (connectedNetwork === "ethereum") {
            storeNftImagePinata(uuid);
        } else {
            transferNftToCustodyAccount(activeUserFromSession(session), selectedNFT, uuid, success, error);
        }
    };

    const saveAction = () => {
        if (!characterName) {
            setNameErrorMessage("Name cannot be empty");
            return;
        }

        if (connectedNetwork === "wax") {
            if (uuid) {
                update();
            } else {
                if (!selectedNFT) {
                    setErrorMessage(strings.nftWAXNotSelected);
                    setShowErrorModal(true);
                    return;
                }
                save();
            }
        } else if (connectedNetwork === "ethereum") {
            if (accountTickets === 0) {
                setErrorMessage(strings.noTicketEth);
                setShowErrorModal(true);
                return;
            }
            if (uuid) {
                setShowConfirmationModal(true);
            } else {
                save();
            }
        }
    };

    const getButtonName = () => {
        if (connectedNetwork === "ethereum") {
            if (!uuid) {
                return strings.preview;
            } else {
                return strings.mint;
            }
        } else {
            return strings.upload;
        }
    };

    return (
        <>
            { image && CHARACTER_CREATOR_ENABLED && (connectedNetwork === "wax" || (connectedNetwork === "ethereum" && !selectedCharacter.active && (!selectedCharacter.data || !selectedCharacter.data.tx_hash)))
                ?
                <Container
                    direction="column"
                    margin={theme.spacing.m}
                >
                    {
                        connectedNetwork === "ethereum" && selectedCharacter.uuid && Object.keys(selectedCharacter.data).length > 0 && !selectedCharacter.data.tx_hash ?
                            <p style={{ color: theme.colors.error.main }}>
                                <b>
                                    <i>{strings.NFTNotSend}</i>
                                </b>
                            </p> : null
                    }
                    {uuid && connectedNetwork === "ethereum" ?  <CenteredParagraph>{strings.uploadImageEth}</CenteredParagraph> : null}
                    <Container>
                        <Button styles={{ width: 150, margin: theme.spacing.xxs }} onClick={saveAction}>{getButtonName()}</Button>
                        {FEATURE_CHARACTER_DELETE && ((connectedNetwork === "ethereum" && selectedCharacter.upload_only && !selectedCharacter.active) || (connectedNetwork === "wax" && selectedCharacter.uuid !== 0)) ? (
                            <Button outline onClick={() => setShowDeleteConfirmation(true)}>
                                {strings.delete} {selectedCharacter.name}
                            </Button>
                        ) : null}
                    </Container>
                </Container>
                : null}
            {uuid ?
                <>
                    <LayerContainer>
                        <BorderContainer pieceIndex={10}>
                            <BorderImageContainer src='/assets/border_gray.png' />
                        </BorderContainer>
                        <BorderContainer>
                            <ImageContainer border src={image} />
                        </BorderContainer>
                    </LayerContainer>
                    {connectedNetwork === "ethereum" && selectedCharacter.nft_hash ?
                        <Link href={`${OPENSEA}/assets/${CHARACTER_MINT_CONTRACT_ADDRESS}/${selectedCharacter.nft_hash}`}  target="_blank">{strings.linkToMarketPlace}</Link>
                        : null}
                </>
                :
                <ImageContainer src={image} />
            }
            { CHARACTER_CREATOR_ENABLED && (!uuid || connectedNetwork === "wax")
                ? <Input type="file" label={strings.uploadYourCharacter} style={{ alignItems: 'center' }} name="myImage" onChange={onImageUpload} />
                : null
            }
            <ConfirmationModal
                title={strings.upload + " " + strings.success}
                show={showSuccessModal}
                onConfirm={() => {
                    setShowSuccessModal(false); onCharacterSubmitted(savedUuid); if (connectedNetwork === "ethereum") { internalRefreshCreatorAccountNftsEth(); }
                    setTokenId(null);
                }}
                onClose={() => {
                    setShowSuccessModal(false); onCharacterSubmitted(savedUuid); if (connectedNetwork === "ethereum") { internalRefreshCreatorAccountNftsEth(); }
                    setTokenId(null);
                }}
                noCancel
            >
                {strings.uploadSuccessfulNote}
                {connectedNetwork === "ethereum" ?
                    <>
                        <p>{strings.linkNFTMarketplace}</p>
                        <Link href={`${OPENSEA}/assets/${CHARACTER_MINT_CONTRACT_ADDRESS}/${tokenId}`}  target="_blank"
                            onClick={() => {
                                setShowSuccessModal(false);
                                onCharacterSubmitted(savedUuid);
                                setTokenId(null);
                            }}
                        >{selectedCharacter.name}</Link>
                    </>
                    : null}
            </ConfirmationModal>
            <ConfirmationModal
                title={strings.upload + " " + strings.error}
                show={showErrorModal}
                onConfirm={() => {setShowErrorModal(false); setErrorMessage("");}}
                onClose={() => {setShowErrorModal(false); setErrorMessage("");}}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
            <ConfirmationModal
                title={strings.mintEthTitle}
                show={showConfirmationModal}
                onConfirm={() => {setShowConfirmationModal(false); transferNft(uuid);}}
                onClose={() => {setShowConfirmationModal(false); }}
            >
                {strings.mintEthWarning}
            </ConfirmationModal>
            <ConfirmationModal
                title={strings.previewEthTitle}
                show={showPreviewModal}
                onConfirm={() => {setShowPreviewModal(false); onCharacterSubmitted(savedUuid);}}
                onClose={() => {setShowPreviewModal(false); }}
            >
                {strings.previewEthMessage}
            </ConfirmationModal>
            <ConfirmationModal
                title={strings.delete + " " + selectedCharacter.name}
                show={showDeleteConfirmation}
                confirmationButtonLabel={strings.delete}
                onConfirm={() => {
                    onDeleteCharacter(selectedCharacter.nft_hash);
                    setShowDeleteConfirmation(false);
                }}
                onClose={() => {
                    setShowDeleteConfirmation(false);
                }}
            >
                {strings.deleteConfirmationMessage}
            </ConfirmationModal>
            { loading ? <Loading isFullScreen /> : null }
        </>
    );
}

export default UploadYourCharacter;
