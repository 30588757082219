import { ethers } from "ethers";
import { CHARACTER_MINT_CONTRACT_ADDRESS } from "@resources/Globals";
// eslint-disable-next-line import/extensions
import characterMintABI from '@api/CharacterMintABI.json';

export async function getCharacterCreatorTicketsEth(characterMintContract, account, onSuccess = () => { }) {
    if (!account) {
        return;
    }

    try {
        const tickets = await characterMintContract._tickets(account);
        onSuccess(tickets);
    } catch (error) {
        console.debug("error when getting tickets", error.message);
    }

}

export async function getCharacterLastIDEth(characterMintContract, account, onSuccess = () => { }) {
    if (!account) {
        return;
    }

    try {
        const lastCharacterId = await characterMintContract._characterIds();
        onSuccess(lastCharacterId);
    } catch (error) {
        console.debug("error when getting tickets", error.message);
    }

}

export async function getTicketsLeftEth(characterMintContract, account, onSuccess = () => { }) {
    if (!account) {
        return;
    }

    try {
        const ticketsLeft = await characterMintContract._ticketsLeft();
        onSuccess(ticketsLeft);
    } catch (error) {
        console.debug("error when getting tickets", error.message);
    }

}

export async function getUsdPrice(characterMintContract, onSuccess = () => { }) {
    const usdPrice = (await characterMintContract._usdPrice()).toString();
    onSuccess(usdPrice);
}

export async function getEthUsd(characterMintContract, onSuccess = () => {}) {
    const ethUsdPrice = (await characterMintContract.getLatestPrice()).toString() / Math.pow(10, 8);

    onSuccess(ethUsdPrice);
}

export async function buyATicket(characterMintContract, account, numberTickets, providerEthers, onSuccess = () => { }, onError = () => { }) {

    try {
        if (!characterMintContract) {
            characterMintContract = new ethers.Contract(CHARACTER_MINT_CONTRACT_ADDRESS, characterMintABI, providerEthers);
        }
        const signer = providerEthers.getSigner(account);
        const ethUsdPrice = (await characterMintContract.getLatestPrice()).toString() / Math.pow(10, 8);

        const usdPrice = (await characterMintContract._usdPrice()).toString();

        const etherCost = (numberTickets*usdPrice / ethUsdPrice).toFixed(9);

        const options = { value: ethers.utils.parseEther(etherCost) };

        const tickets = await characterMintContract.connect(signer).buyTicket(numberTickets, options);
        if (tickets) {
            onSuccess(tickets);
        }
    } catch (error) {
        if (error.code === "UNPREDICTABLE_GAS_LIMIT" || error.code === -32000) {
            onError(error.error.message);
        } else {
            onError(error.message);
        }
    }
}

export async function transferNftToCustodyAccountEth(characterUri, characterMintContract, account, providerEthers, onSuccess = () => {}, onError = () => { }) {
    try {
        if (!characterMintContract) {
            characterMintContract = new ethers.Contract(CHARACTER_MINT_CONTRACT_ADDRESS, characterMintABI, providerEthers);
        }
        const signer = providerEthers.getSigner(account);
        const characterMint = await characterMintContract.connect(signer).mintCharacter(characterUri);
        if (characterMint) {
            onSuccess(characterMint);
        }
    } catch (error) {
        if (error.code === "UNPREDICTABLE_GAS_LIMIT" || error.code === -32000) {
            onError(error.error.message);
        } else {
            onError(error.message);
        }
    }
}
