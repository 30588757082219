import { createContext, useContext, useEffect, useState } from "react";
import { getGameCharacterTiers } from "@api/Api";
import { useServerAccount } from "./AccountContext";

const CharacterSupportContext = createContext();

const CharacterSupportProvider = ({ children }) => {
    const [ levelTiers, setLevelTiers ] = useState([]);
    const { serverAccountInfo } = useServerAccount();

    useEffect(() => {
        if (serverAccountInfo) {
            getGameCharacterTiers((response) => setLevelTiers(response), () => setLevelTiers([]));
        } else {
            setLevelTiers([]);
        }
    }, [ serverAccountInfo ]);

    return (
        <CharacterSupportContext.Provider value={{ levelTiers }}>
            { children }
        </CharacterSupportContext.Provider>
    );
};

const useCharacterLevelTiers = () => {
    const context = useContext(CharacterSupportContext);
    const { levelTiers } = context;

    return levelTiers;
};

export { CharacterSupportProvider, useCharacterLevelTiers };
