import styled from '@emotion/styled';

const LayerContainer = styled.div(({ pieceIndex }) => ({
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: pieceIndex
}));

const ImageContainer = styled.img(({ theme }) => ({
    width: '100%',
    borderRadius: theme.borderRadius.xl,
    [ theme.mediaQuery.largeMobileOnly ]: {
        borderRadius: '10%'
    }
}));

function PieceLayer({ category = "", selectedPiece = "", pieces = {}, pieceIndex = 0 }) {
    const renderPieceImage = () => {
        if (pieces) {
            const selected = pieces.find(p => p.label === selectedPiece);
            if (selected) {
                return <ImageContainer alt={category} src={selected.image} />;
            }
        }
    };

    return (
        <LayerContainer pieceIndex={pieceIndex}>
            {renderPieceImage()}
        </LayerContainer>
    );
}

export default PieceLayer;
