import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@emotion/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { Chains } from '@wharfkit/common';
import Theme from '@styles/Theme';
import routes from '@resources/Routes';
import './index.css';
import './App.css';

import App from './App';
import Home from '@contents/Home';
import Content from '@contents/Content';
import ServerLoginForm from '@contents/auth/ServerLogin';
import Register from '@contents/auth/Register';
import UALLogin from '@contents/auth/UALLogin';
import NFTNotFound from '@contents/NFTNotFound';
import PageNotFound from '@contents/PageNotFound';
import CharacterCreatorEvent from '@contents/character_creator/CharacterCreatorEvent';
import ResetPassword from '@contents/auth/ResetPassword';
import RequestAccountActivation from '@contents/auth/RequestAccountActivation';
import BowlingGame from '@contents/game/BowlingGame';

import { AccountProvider } from '@hooks/AccountContext';
import { GeneralProvider } from '@hooks/GeneralContext';
import { EthereumProvider } from "@hooks/EthereumContext";
import { FeatureFlagsProvider } from "@hooks/FeatureFlagsContext";
import { CharacterSupportProvider } from "@hooks/CharacterSupportContext";

import ChainProvider from "./hooks/ChainContext";

// WAX Setup
const waxChain = process.env.REACT_APP_IS_TESTNET === 'true' ? Chains.WAXTestnet : Chains.WAX;

// Router Setup
const routesConfig = [
    { path: routes.root, element: <Content /> },
    { path: routes.home, element: <Home /> },
    { path: routes.login, element: <ServerLoginForm /> },
    { path: routes.register, element: <Register /> },
    { path: routes.resetPassword, element: <ResetPassword /> },
    { path: routes.requestAccountActivation, element: <RequestAccountActivation /> },
    { path: routes.ualLogin, element: <UALLogin /> },
    { path: routes.nftNotFound, element: <NFTNotFound /> },
    { path: routes.characterCreator, element: <CharacterCreatorEvent /> },
    { path: routes.game + '/*', element: <BowlingGame /> },
    { path: '*', element: <PageNotFound /> }
];

const mountRouteElement = (route) => {
    let children = [];

    if (route.subRoutes) {
        children = route.subRoutes.map((subRoute) => mountRouteElement(subRoute));
    }

    return (
        <Route key={route.path} path={route.path} element={route.element}>
            {children}
        </Route>
    );
};

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={Theme}>
            <ChainProvider chains={[ waxChain ]}>
                <FeatureFlagsProvider>
                    <GeneralProvider>
                        <EthereumProvider>
                            <AccountProvider>
                                <CharacterSupportProvider>
                                    <BrowserRouter>
                                        <Routes>
                                            <Route key={routes.root} element={<App />}>
                                                {routesConfig.map(route => mountRouteElement(route))}
                                            </Route>
                                        </Routes>
                                    </BrowserRouter>
                                </CharacterSupportProvider>
                            </AccountProvider>
                        </EthereumProvider>
                    </GeneralProvider>
                </FeatureFlagsProvider>
            </ChainProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
