import { getReasonPhrase } from 'http-status-codes';
import { getServerAccountInfo } from '@hooks/AccountContext';

const commonHeaders = { "Access-Control-Allow-Origin": "*" };
export const CONTENT_TYPES = {
    json: "application/json;",
    xWwwFormUrlEncoded: "application/x-www-form-urlencoded",
    multipartFormData: "multipart/form-data"
};

const getHeaders = (contentType) => {
    let headers = { ...commonHeaders };
    // Fetch fails if you have multipart form data as header
    // https://stackoverflow.com/questions/48284011/how-to-post-image-with-fetch/58032429#58032429
    if (contentType !== CONTENT_TYPES.multipartFormData) {
        headers["Content-Type"] = contentType;
    }
    return headers;
};

async function httpRequestHandler(url, request, onSuccess = () => {}, onError = () => {}, onEnd = () => {}) {
    let response = await fetch(url, request);

    // 204 is empty response and because of that, line 16 fails
    if (response.status === 204) {
        onSuccess({});
    } else {
        let result = await response.json();

        if (response.status >= 200 && response.status < 300) {
            await onSuccess(result);
        } else {
            let errorMessage = `Error ${response.status}: ${getReasonPhrase(response.status)}`;
            if (result) {
                if (result.detail && result.detail[0].msg) {
                    errorMessage = "";
                    for (let error of result.detail) {
                        if (error.msg) {
                            errorMessage += error.msg + " ";
                        }
                    }
                    errorMessage += `(Error ${response.status})`;
                } else if (result.msg) {
                    errorMessage = `${result.msg} (Error ${response.status})`;
                }
            }

            await onError(errorMessage);
        }
    }
    onEnd();
}

export async function get(url, onSuccess = () => {}, onError = () => {}, onEnd = () => {}, contentType = CONTENT_TYPES.json, authToken = null) {
    let request = {
        headers: getHeaders(contentType),
        method: 'GET'
    };

    if (authToken) {
        request.headers["Authorization"] = `Bearer ${authToken}`;
    }

    httpRequestHandler(url, request, onSuccess, onError, onEnd);
}

export async function post(url, payload, onSuccess = () => {}, onError = () => {}, onEnd = () => {}, contentType = CONTENT_TYPES.json, authToken = null) {
    let request = {
        headers: getHeaders(contentType),
        method: 'POST',
        body: contentType === CONTENT_TYPES.json ? JSON.stringify(payload) : payload
    };

    if (authToken) {
        request.headers["Authorization"] = `Bearer ${authToken}`;
    }

    httpRequestHandler(url, request, onSuccess, onError, onEnd);
}

export async function patch(url, payload, onSuccess = () => {}, onError = () => {}, onEnd = () => {}, contentType = CONTENT_TYPES.json, authToken = null) {
    let request = {
        headers: getHeaders(contentType),
        method: 'PATCH',
        body: contentType === CONTENT_TYPES.json ? JSON.stringify(payload) : payload
    };

    if (authToken) {
        request.headers["Authorization"] = `Bearer ${authToken}`;
    }

    httpRequestHandler(url, request, onSuccess, onError, onEnd);
}

export async function del(url, onSuccess = () => {}, onError = () => {}, onEnd = () => {}, contentType = CONTENT_TYPES.json, authToken = null) {
    let request = {
        headers: getHeaders(contentType),
        method: 'DELETE'
    };

    if (authToken) {
        request.headers["Authorization"] = `Bearer ${authToken}`;
    }

    httpRequestHandler(url, request, onSuccess, onError, onEnd);
}

export const getWithAuth = (url, onSuccess = () => { }, onError = () => { }, onEnd = () => { }, contentType = CONTENT_TYPES.json) => {
    const serverAccountInfo = getServerAccountInfo();

    if (serverAccountInfo) {
        get(url, onSuccess, onError, onEnd, contentType, serverAccountInfo.access_token);
    }
};

export const postWithAuth = (url, payload, onSuccess = () => { }, onError = () => { }, onEnd = () => { }, contentType = CONTENT_TYPES.json) => {
    const serverAccountInfo = getServerAccountInfo();

    if (serverAccountInfo) {
        post(url, payload, onSuccess, onError, onEnd, contentType, serverAccountInfo.access_token);
    }
};

export const patchWithAuth = (url, payload = {}, onSuccess = () => { }, onError = () => { }, onEnd = () => { }, contentType = CONTENT_TYPES.json) => {
    const serverAccountInfo = getServerAccountInfo();

    if (serverAccountInfo) {
        patch(url, payload, onSuccess, onError, onEnd, contentType, serverAccountInfo.access_token);
    }
};

export const delWithAuth = async (url, onSuccess = () => { }, onError = () => { }, onEnd = () => { }, contentType = CONTENT_TYPES.json) => {
    const serverAccountInfo = getServerAccountInfo();

    if (serverAccountInfo) {
        del(url, onSuccess, onError, onEnd, contentType, serverAccountInfo.access_token);
    }
};
