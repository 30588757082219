const strings = {
    productName: "PinMaster Bowling Game",

    productMainDescription: "The PinMaster Character Creator event is here! Mix and match all of the available looks to find one that fits your bowling style. When you feel like you've nailed it, hit the Save button to submit your Character!",
    productReleaseWAXNote: "You're free to resubmit a new look for your Character as long as the event lasts, but remember that submitted Characters must be unique. Make your unique character quick to secure your customized look.",
    productReleaseETHNote: "Once submitted, an ETH NFT will be minted to your account and you will not be able to edit the character anymore. Each submitted Character must be unique. Make your unique character quick to secure your customized look.",

    NFTNotFoundTitle: "Character Creator NFT not found.",
    NFTNotFoundNote: "The required NFT for this PinMaster event has not been found in your WAX Account.",
    NFTNotFoundHelp: "If you believe this is a system error, please contact us via our email: info@pinmaster.io",

    characterCreatorEventEnded: "The Character Creator event has ended. You can now only view your created characters.",

    eventEndedTitle: "Character Creator Event ended.",
    eventEndedNote: "The PinMaster Character Creator event has ended!",
    eventEndedHelp: "If you were unable to submit your custom character in time, please contact us via our email: info@pinmaster.io",

    ethAvailable: "Character minting has ended for this cycle",
    newPeople: "Just got here?",
    startWithWax: "If you already have a WAX account, sign up with WAX",
    ualLoginNote: "The PinMaster Character Creator event is here! Please log in with your WAX or your ETH Account below to continue.",
    ualLoginLinkAccountNote: "If you already have a WAX (or ETH) account and you want to link the other account, you will be able to link them from the Home Page, once logged in.",
    ualLoginHelp: "You will later be required to create an account to use the Character Creator if you own the required NFT.",

    existingAccountDisclaimer: "If you already have a PinMaster account, you'll be able to link your Metamask AFTER you sign in",
    noSafari: "We recommend you use updated versions of Chrome or Firefox",
    NFTMarketNote: "You can create your own Character by purchasing a Ticket on the secondary market using the link below:",
    NFTMarketLink: "https://neftyblocks.com/c/pinmasterio1/marketplace?sort=created&order=desc&collection_name=pinmasterio1",

    buyATicket: "Buy a Ticket",
    buyTickets: "Buy Tickets",
    youCanBuyMoreTicketsWAX: "You can buy more WAX Tickets on the secondary market using the link below:",
    youCanBuyMoreTicketsETH: "You can buy more ETH Tickets by clicking the button below:",

    youCanJoinDiscord: "You can join the PinMaster Discord through the following link:",
    joinDiscord: "Join PinMaster Discord",
    discordLink: "https://discord.gg/PGmW83SSPS",

    unknownErrorOccurred: "Unknown Error has occurred.",

    characterCreator: "Character Creator",
    uploadYourCharacter: "Upload your Character",

    create: "Create",
    upload: "Upload",
    preview: "Preview",
    mint: "Mint",

    character: "Character",
    createNewCharacter: "Create New Character",
    characterName: "Character Name",
    characterInactive: "We have detected that the Ticket NFT for this character was not correctly transferred.",
    nftNotFoundInWallet: "The Ticket NFT was not detected in your wallet. It may take some time for the transfer action to be completed. Please check again in a few minutes.",
    NFTNotSend: "The NFT was not send. Please, try again",

    ualLoginPage: "UAL Login Page",
    pageNotFound: "Page not Found",

    ok: "OK",
    cancel: "Cancel",
    success: "Success",
    error: "Error",

    previous: "Previous",
    next: "Next",

    back: "Back",

    random: "Random",

    save: "Save",
    saveSuccessfulNote: "Your PinMaster Character was successfully saved!",
    uploadSuccessfulNote: "Your custom PinMaster Character was successfully uploaded!",

    ticketsRemaining: "tickets remaining",
    noTicketsLeft: "no tickets left",
    youHave: "You have",
    tickets: 'tickets',
    ticketsReduction: "Once you mint your NFT, the tickets amount will be updated",

    logOut: "Sign Out",
    logIn: "Sign In",
    existingAccount: "Sign in with your WAX account",
    onlyEthLinked: "Sign in using only your Metamask",
    waxSignup: "I already have a WAX account",
    ethSignup: "I only use Metamask",
    linkWAX: "Link WAX account",
    waxLoggedIn: "Welcome, {#1}!",
    searchingInventory: "We're searching your inventory...",
    noNFT: "You don't have the required NFT in your account",

    username: "Username",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    password: "Password",
    forgotMyPassword: "Forgot my Password",
    forgotMyUsername: "Forgot my Username",
    confirmPassword: "Confirm Password",
    newPassword: "New Password",
    sendEmail: "Send Email",

    passwordsDontMatch: "The passwords don't match.",
    emptyFields: "Form fields cannot be left empty.",
    fillEmail: "Please type your email so we can send the password reset instructions.",

    resetPassword: "Reset Password",
    resetPasswordSuccessfulNote: "Your password was successfully reset!",

    requestResetPassword: "A link to Reset your Password will be sent to the email registered for the '{0}' WAX Account",
    requestRecoverUsername: "Your Username will be sent to the email registered for the '{0}' WAX Account",
    requestResetPasswordSuccess: "Password reset email sent successfully.",
    requestRecoverUsernameSuccess: "Username recovery email sent successfully",
    requestResetPasswordSuccessfulNote: "Please check your email for next steps on how to reset your password.",
    requestRecoverUsernameSuccessfulNote: "Please check your email to view your username.",

    delete: "Delete",
    deleteSuccessfulNote: "Your character was successfully deleted.",
    deleteConfirmationMessage: "Are you sure you want to delete your character? This action cannot be reverted and the Ticket can take a while to be returned.",

    activateAccount: "Activate Account",
    requestAccountActivation: "Request to Activate Account",
    requestAccountActivationSuccessfulNote: "Please check your email for next steps on how to activate your account.",

    passwordRequirements: "Password must be at least 8 characters long and contain at least one uppercase and one lowercase letter, one numeric character, and one special character (such as !, %, @, or #).",

    register: "Register",
    registerNow: "Register now",
    registration: "Registration",
    dontHaveAnAccountYet: "Don't have an account yet?",

    activationAccountSent: "Please check your email for next steps on how to activate your account.",

    transferSuccessTitle: "NFT Successfully Transferred",
    transferNft: "Transfer NFT",
    refreshToken: "Refresh Token",
    userNotLoggedIn: "User not logged in.",

    metamaskNotLoaded: "Metamask is not loaded. Close and open the website",
    metamaskRequestOpened: "You already have a request opened",
    metamaskNotInstalled: "Metamask is not installed",
    metamaskWrongMainNetwork: "You need to change the network to Ethereum Main Chain",
    metamaskWrongTestNetwork: "You need to change the network to Rinkeby Test Chain",

    connectMetamask: "Connect to Metamask",
    linkMetamask: "Link ETH Account",
    ethBuy: "You can still buy {0} tickets",
    outOf: "out of",

    waxAccountAlreadyRegistered: "This WAX account is already registered in the server. You'll be signed out and redirected to the home page.",
    ethAccountAlreadyRegistered: "This ETH address is already registered in the server. You'll be signed out and redirected to the home page.",

    nftWAXNotSelected: "You need to select a WAX Ticket first!",
    noTicketEth: "You don't have any ETH Ticket. If you want to create a character, buy more first",
    ticketsNoTransferable: "Once bought, the tickets will not be transferrable",
    totalCost: "Total Cost: ",

    previewEthTitle: "ETH Character Preview",
    previewEthMessage: "The pre visualization has been successfully created.",
    uploadImageEth: "If you are happy with the character you've just created, click the button below to mint the NFT Ethereum Mainnet. Otherwise, you can delete the image and upload it again",
    ticketPurchaseTitle: "ETH Ticket Purchase",
    buyEthSucceeded: "Your tickets were purchased correctly.",
    numberTicketsBuy: "How many tickets do you want to buy?",
    mintEthTitle: "Are you sure you want to save your character?",
    mintEthWarning: "Once submitted, an ETH NFT will be minted to your account and the character will not be able to be edited anymore",
    purchaseAbout: "You're about to purchase",

    linkNFTMarketplace: "You can see the NFT you've just created here:",
    linkToMarketPlace: "See your character in OpenSea",

    waxAccountNotLinked: "You don't have any WAX account associated. To play the game, please link a WAX account first",
    waxAccountNotConnected: "Your WAX account is not connected. To play the game, please connect to your WAX account first",

    titleBowlingGame: "Welcome",
    captionBowlingGame: "to the PinMaster Bowling Game",
    game: 'Play the game',

    stamina: "Stamina",
    practice: "Practice",
    practices: "Practices",
    tournament: "Tournament",
    tournaments: "Tournaments",
    matchHistory: "Match History",
    home: "Home",

    startNewMatch: "Start New Match",
    resumeMatch: "Resume Match",
    noMatchesPlayedYet: "No Matches played yet",
    finished: "Finished",
    ongoing: "Ongoing",

    manageCharacters: "Manage Characters",
    viewDetails: "View Details",
    selectCharacter: "Select Character",
    selected: "Selected",
    apply: "Apply",
    close: "Close",
    inventory: "Inventory",

    use: "Use",
    beingUsed: "Being Used...",
    comingSoon: "Coming Soon...",

    consumeNftConfirmationMessage: "Are you sure you want to use the {0} NFT?",
    consumeNftInformationMessage: "This NFT will be burned and the rewards will be credited to {0}. This operation can take a while to be completed due to chain confirmation delay.",
    consumeNftSuccessMessage: "The NFT was successfully used and the reward will be credited after confirmation that the NFT was burned.",

    orderBy: "Order By",
    name: "Name",
    rarity: "Rarity",
    category: "Category",

    ascending: "Ascending",
    descending: "Descending",

    allNftTypes: "All NFT Types",
    allRarities: "All Rarities",
    allCategories: "All Categories",
    consumable: "Consumable",
    equipment: "Equipment",

    common: "Common",
    rare: "Rare",
    epic: "Epic",
    legendary: "Legendary",
    mythical: "Mythical",

    ball: "Ball",
    wristGuard: "Wrist Guard",
    shoes: "Shoes",
    staminaBarBox: "Stamina Bar Box",
    accessory: 'Accessory',

    pins: "Pins",
    staminaReset: "Stamina Reset",
    rankUpgrade: "Rank Upgrade",
    prestigeUpgrade: "Prestige Upgrade",

    yourAccount: "your account",
    selectedCharacter: "the selected Character",

    match: "Match",
    play: "Play",
    skip: "Skip",
    total: "Total",

    status: "Status",
    date: "Date",
    score: "Score",
    rewards: "Rewards",
    xp: "XP",
    skillPoints: "Skill Points",

    quickPlay: "Quick Play",
    quickPlayWarning: "Quick Play will use all of your Character stamina to bowl in Practice matches",
    quickPlayResult: "All Character stamina used in Practice matches.",
    quickPlayTotalXp: "A total of {0} XP was earned.",
    warning: "Warning",

    congratulations: "Congratulations!",
    matchRewards: "Match Rewards",
    finalScore: "Final Score",
    and: "and",

    power: "Power",
    control: "Control",
    luck: "Luck",
    floor: "Floor",
    ceiling: "Ceiling",

    level: "Level",
    nextTier: "Next Tier",
    ascendCharacter: "Ascend Character",
    ascend: "Ascend",
    ascendCharacterConfirmationMessage: "Are you sure you want to Ascend this Character to {0} tier? This operation will cost {1} pins.",

    details: "Details",
    equipments: "Equipments",
    equip: "Equip",
    unequip: "Unequip",
    change: "Change",
    equipmentChange: "Equipment Change",
    locked: "Locked",
    transferring: "Transferring",
    unlocksIn: "Unlocks in",
    equipCharacterConfirmationMessage: "Are you sure you want to Equip this Character with {0}? This Equipment will be transferred to a custody account (which might take a few minutes) while equipped and locked to this Character for 24h.",
    unequipCharacterConfirmationMessage: "Are you sure you want to Unequip {0} from this Character?",
    noEquipmentsAvailable: "No Equipments Available"
};

export default strings;
