import styled from "@emotion/styled";

const Badge = styled.span(({ theme, styles }) => ({
    display: "block",
    color: theme.colors.common.background,
    backgroundColor: theme.colors.common.border,
    borderRadius: theme.borderRadius.l,
    width: theme.size.mintWidth,
    height: theme.size.mintHeight,
    top: 10,
    right: 0,
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: theme.size.mintHeight,
    zIndex: 20,
    [ theme.mediaQuery.tabletUp ]: {
        top: 0
    },
    ...styles
}));

export default Badge;
