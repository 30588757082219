import { useState } from 'react';
import { useTheme } from '@emotion/react';

import { requestGameCharacterEquipmentRemoval } from '@api/Api';

import Button from '@components/Button';
import ConfirmationModal from '@components/ConfirmationModal';
import InventoryCard from "@components/InventoryCard";
import Loading from '@components/Loading';
import CharacterEquipmentsModal from './CharacterEquipmentsModal';

import strings from "@resources/strings";

const equipmentType = {
    "Ball": 'ball',
    "Wrist Guard": 'wristGuard',
    "Shoes": 'shoes',
    "Accessory": 'accessory'
};

const slotImage = {
    [equipmentType["Ball"]]: '/assets/equip_ball_slot.svg',
    [equipmentType["Wrist Guard"]]: '/assets/equip_wristband_slot.svg',
    [equipmentType["Shoes"]]: '/assets/equip_shoes_slot.svg',
    [equipmentType["Accessory"]]: '/assets/equip_accessory_slot.svg'
};

const placeholderSlot = (equipment) => {
    return {
        name: strings[equipmentType[equipment.slot]],
        image: slotImage[equipmentType[equipment.slot]],
        description: strings[equipmentType[equipment.slot]],
        order: equipment.order,
        slot: equipment.slot,
        stats: equipment.stats
    };
};

export const CharacterEquipment = ({ equipment = {}, characterUuid = null, reload = () => {} }) => {
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ showEquipmentsModal, setShowEquipmentsModal ] = useState(false);
    const [ showUnequipConfirmationModal, setShowUnequipConfirmationModal ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const theme = useTheme();

    const unequip = () => {
        const success = () => {
            reload();
            setShowUnequipConfirmationModal(false);
            setLoading(false);
        };

        const error = (message) => {
            setShowUnequipConfirmationModal(false);
            setLoading(false);
            setErrorMessage(message);
            setShowErrorModal(true);
        };

        const payload = {
            inventory_uuid: equipment.value.uuid
        };

        setLoading(true);
        requestGameCharacterEquipmentRemoval(payload, success, error);
    };

    const renderControls = () => {
        const lockTime = Math.ceil((new Date(equipment?.value?.locked_until) - new Date()) / (1000 * 60 * 60));

        let render = null;

        if (equipment?.value?.to_be_transferred && !equipment?.value?.transferred) {
            render = <div style={{ color: theme.colors.primary.main, marginTop: theme.spacing.xxs }}>{strings.transferring}</div>;
        } else if (equipment?.value?.locked_until && lockTime > 0) {
            render = (
                <>
                    <Button style={{ padding: `0px ${theme.spacing.s}`, margin: theme.spacing.xxs }} outline disabled>{strings.locked}</Button>
                    <div style={{ color: theme.colors.primary.main, marginTop: theme.spacing.xxxxs }}>{strings.unlocksIn + ' ' + lockTime + 'h'}</div>
                </>
            );
        } else if (equipment?.value) {
            render = (
                <>
                    <Button style={{ padding: `0px ${theme.spacing.s}`, margin: theme.spacing.xxs }} onClick={() => setShowEquipmentsModal(true)}>{strings.change}</Button>
                    <Button style={{ padding: `0px ${theme.spacing.s}`, margin: theme.spacing.xxxxs }} outline onClick={() => setShowUnequipConfirmationModal(true)}>{strings.unequip}</Button>
                </>
            );
        } else {
            render = <Button style={{ padding: `0px ${theme.spacing.s}`, margin: theme.spacing.xxs }} onClick={() => setShowEquipmentsModal(true)}>{strings.equip}</Button>;
        }

        return render;
    };

    return (
        <>
            <InventoryCard nft={equipment?.value ?? placeholderSlot(equipment)} locked={equipment?.value?.to_be_transferred && !equipment?.value?.transferred} noDescription controls={renderControls} minified />
            <CharacterEquipmentsModal equipment={equipment?.value ? equipment : placeholderSlot(equipment)} characterUuid={characterUuid} show={showEquipmentsModal} onClose={() => setShowEquipmentsModal(false)} reload={reload} />
            <ConfirmationModal
                title={strings.unequip}
                confirmationButtonLabel={strings.unequip}
                show={showUnequipConfirmationModal}
                onConfirm={unequip}
                onClose={() => setShowUnequipConfirmationModal(false)}
            >
                {strings.unequipCharacterConfirmationMessage.replace("{0}", equipment?.value?.name ?? strings[equipment.slot])}
            </ConfirmationModal>
            <ConfirmationModal
                title={strings.error}
                show={showErrorModal}
                onConfirm={() => {setShowErrorModal(false); setErrorMessage("");}}
                onClose={() => {setShowErrorModal(false); setErrorMessage("");}}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
            { loading ? <Loading isFullScreen /> : null }
        </>
    );
};

export default CharacterEquipment;
