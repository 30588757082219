import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

const DropDownContainer = styled.div(({ theme }) => ({
    width: theme.size.defaultSelectWidth,
    margin: theme.spacing.xxxs,
    userSelect: "none"
}));

const DropDownHeader = styled.div(({ theme }) => ({
    padding: `${theme.spacing.xxs} ${theme.spacing.xs}`,
    color: theme.colors.common.text,
    display: "flex",
    alignItems: 'center',
    justifyContent: "space-between",
    background: theme.colors.common.background,
    border: `1px solid ${theme.colors.primary.main}`,
    borderRadius: theme.borderRadius.xxl,
    cursor: 'pointer'
}));

const DropDownText = styled.div(({ theme }) => ({
    marginRight: theme.spacing.xxs
}));

const Triangle = styled.div(({ theme, isOpen }) => ({
    width: 0,
    height: 0,
    border: `5px solid transparent`,
    borderTop: 0,
    borderBottom: `10px solid ${theme.colors.common.border}`,
    transition: 'transform 0.1s ease-in-out',
    transform: isOpen ? null : 'rotateX(180deg)'
}));

const DropDownListContainer = styled.div(({ theme }) => ({
    width: theme.size.defaultSelectWidth,
    position: "absolute",
    zIndex: 90
}));

const DropDownList = styled.ul(({ theme }) => ({
    margin: 0,
    padding: 0,
    marginTop: theme.spacing.xxxs,
    color: theme.colors.common.text,
    background: theme.colors.common.background,
    border: `1px solid ${theme.colors.primary.main}`,
    borderRadius: theme.borderRadius.l
}));

const ListItem = styled.li(({ theme, selected }) => ({
    listStyle: "none",
    color: null,
    backgroundColor: selected ? theme.colors.primary.main : null,
    padding: `${theme.spacing.xxs} ${theme.spacing.xs}`,
    textAlign: 'initial',
    cursor: 'pointer',
    borderRadius: theme.borderRadius.l,
    [ '&:hover' ]: {
        background: selected ? `${theme.colors.primary.gradientStart}B0` : theme.colors.common.backgroundLighter
    }
}));

const Select = ({ placeholder = "", options = [], selected = null, onSelectChange = () => { }, style = {} }) => {
    const [ isOpen, setIsOpen ] = useState(false);

    const dropdownRef = useRef(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ dropdownRef ]);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const onItemSelected = (option) => {
        onSelectChange(option);
        setIsOpen(false);
    };

    const renderOptions = () => {
        return options?.map((option, index) => <ListItem key={index} value={option.value} selected={option.value === selected?.value} onClick={() => onItemSelected(option)}>{option.label}</ListItem>);
    };

    return (
        <DropDownContainer style={style}>
            <DropDownHeader onClick={() => setIsOpen((previousValue) => !previousValue)}>
                <DropDownText>{selected?.label ?? placeholder}</DropDownText>
                <Triangle isOpen={isOpen} />
            </DropDownHeader>
            { isOpen && (
                <DropDownListContainer ref={dropdownRef} style={{ width: style?.width ?? null }}>
                    <DropDownList>
                        {renderOptions()}
                    </DropDownList>
                </DropDownListContainer>
            )}
        </DropDownContainer>
    );
};

export default Select;
