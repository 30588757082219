//IPFS
export const IPFS_ENDPOINT = process.env.REACT_APP_IPFS_ENDPOINT;

// WAX
export const ATOMICASSETS_ACCOUNT = 'atomicassets';
export const ATOMICASSETS_TRANSFER_ACTION = 'transfer';
export const ATOMICASSETS_BURN_ACTION = 'burnasset';
export const CUSTODY_ACCOUNT = 'custodypinmt';
export const CUSTODY_TABLE = 'assets';
export const WAX_RPC_ENDPOINT = process.env.REACT_APP_WAX_RPC;
export const AA_ENDPOINT = process.env.REACT_APP_AA_ENDPOINT;

// Collection
export const COLLECTION_NAME = process.env.REACT_APP_COLLECTION_NAME;
export const LOGIN_ENABLER_NFTS = process.env.REACT_APP_LOGIN_ENABLER_NFTS;

// Server
export const SERVER_URL = process.env.REACT_APP_SERVER_ENDPOINT;
export const UI_URL = process.env.REACT_APP_UI_ENDPOINT;

// Feature Flags
export const FEATURE_CHARACTER_DELETE = process.env.REACT_APP_CHARACTER_DELETE === 'true';

// Ethereum chain ID
export const ETHEREUM_CHAIN_ID = process.env.REACT_APP_ETHEREUM_CHAIN_ID;
export const ETHEREUM_CHAIN = process.env.REACT_APP_ETHEREUM_CHAIN;
export const ETHEREUM_CHAIN_NAME = process.env.REACT_APP_ETHEREUM_CHAIN_NAME;
export const ETHEREUM_CHAIN_RPC = process.env.REACT_APP_ETHEREUM_CHAIN_RPC;
export const CHARACTER_MINT_CONTRACT_ADDRESS = process.env.REACT_APP_CHARACTER_MINT_CONTRACT_ADDRESS;

// Pinata API Key
export const PINATA_API_KEY = process.env.REACT_APP_PINATA_API_KEY;
export const PINATA_API_KEY_SECRET = process.env.REACT_APP_PINATA_API_KEY_SECRET;

export const OPENSEA = process.env.REACT_APP_OPENSEA;
export const RARIBLE = process.env.REACT_APP_RARIBLE;