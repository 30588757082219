import { useState } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import Button from '@components/Button';
import ConfirmationModal from '@components/ConfirmationModal';
import strings from "@resources/strings";
import { requestGameCharacterTierUpgrade } from '@api/Api';
import { useUserPins } from '@hooks/AccountContext';

const NextTierContainer = styled.div(({ theme }) => ({
    fontSize: 18,
    margin: `${theme.spacing.xxs} 0`
}));

export const Ascension = ({ character, reload, nextTierInfo }) => {
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ showAscendConfirmationModal, setShowAscendConfirmationModal ] = useState(false);

    const theme = useTheme();
    const { refreshPins } = useUserPins();

    const ascendCharacter = () => {
        const success = () => {
            reload();
            setShowAscendConfirmationModal(false);
            refreshPins();
        };

        const error = (message) => {
            setErrorMessage(message);
            setShowErrorModal(true);
            refreshPins();
        };

        requestGameCharacterTierUpgrade(character.uuid, success, error);
    };

    return (
        <>
            <NextTierContainer>{strings.nextTier}: {character.next_tier}</NextTierContainer>
            <Button style={{ padding: `0px ${theme.spacing.s}` }} outline onClick={() => setShowAscendConfirmationModal(true)}>{strings.ascendCharacter}</Button>
            <ConfirmationModal
                title={strings.ascendCharacter}
                confirmationButtonLabel={strings.ascend}
                show={showAscendConfirmationModal}
                onConfirm={ascendCharacter}
                onClose={() => setShowAscendConfirmationModal(false)}
            >
                {strings.ascendCharacterConfirmationMessage.replace("{0}", nextTierInfo?.tier).replace("{1}", nextTierInfo?.cost)}
            </ConfirmationModal>
            <ConfirmationModal
                title={strings.error}
                show={showErrorModal}
                onConfirm={() => {setShowErrorModal(false); setErrorMessage("");}}
                onClose={() => {setShowErrorModal(false); setErrorMessage("");}}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
        </>
    );
};

export default Ascension;
