import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { requestAccountActivation } from "@api/Api";
import routes from "@resources/Routes";
import strings from "@resources/strings";
import Button from "@components/Button";
import ConfirmationModal from "@components/ConfirmationModal";
import Input from "@components/Input";
import Loading from "@components/Loading";

const Form = styled.form(() => ({
    display: 'inline-grid'
}));

const RequestAccountActivationForm = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const [ showSuccessModal, setShowSuccessModal ] = useState(false);
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ email, setEmail ] = useState('');
    const [ loading, setLoading ] = useState(false);

    const requestAccActivation = () => {
        if (!validateForm()) {
            return;
        }

        const resetAccInfo = {
            email: email
        };

        const success = () => {
            setLoading(false);
            setShowSuccessModal(true);
        };

        const error = (response) => {
            setLoading(false);
            setShowErrorModal(true);
            setErrorMessage(response);
        };

        setLoading(true);
        requestAccountActivation(resetAccInfo, success, error);
    };

    const validateForm = () => {
        if (!email) {
            setErrorMessage(strings.fillEmail);
            return false;
        }
        return true;
    };

    return (
        <>
            <Form onSubmit={(e) => e.preventDefault()}>
                <Input type="email" id="femail" value={email} label={strings.email} onChange={(e) => setEmail(e.target.value)} />
                <Button style={{ marginTop: theme.spacing.xs }} onClick={requestAccActivation}>{strings.activateAccount}</Button>
            </Form>
            <ConfirmationModal
                title={strings.requestAccountActivation + " " + strings.success}
                show={showSuccessModal}
                onConfirm={() => { setShowSuccessModal(false); navigate(routes.login); }}
                onClose={() => { setShowSuccessModal(false); navigate(routes.login); }}
                noCancel
            >
                {strings.requestAccountActivationSuccessfulNote}
            </ConfirmationModal>
            <ConfirmationModal
                title={strings.requestAccountActivation + " " + strings.error}
                show={showErrorModal}
                onConfirm={() => { setShowErrorModal(false); setErrorMessage(""); }}
                onClose={() => { setShowErrorModal(false); setErrorMessage(""); }}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
            { loading ? <Loading isFullScreen /> : null }
        </>
    );
};

export default RequestAccountActivationForm;
