import styled from "@emotion/styled";

const FrameContainer = styled.div(() => ({
    display: "block",
    fontWeight: 'bold',
    width: '10%'
}));

const FrameSquare = styled.div(() => ({
    borderStyle: 'solid',
    borderRadius: '10px',
    borderColor: 'white',
    borderWidth: 2,
    color: 'white',
    margin: 3,
    height: '80%',
    width: '90%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
}));

const RoundTitle = styled.span(() => ({
    fontSize: "large"
}));

const TotalScore = styled.span(() => ({
    fontSize: "x-large",
    marginBottom: 8,
    height: 28
}));

const PartialScoreContainer = styled.div(() => ({
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: 8,
    height: 18
}));

const PartialScore = styled.div(() => ({
    width: 18
}));

const Frame = ({ balls = [], score, round }) => {

    return (
        <FrameContainer>
            <RoundTitle>{round}</RoundTitle>
            <FrameSquare>
                <PartialScoreContainer>
                    {balls.map((b, i) => <PartialScore key={i}>{b}</PartialScore>)}
                </PartialScoreContainer>
                <hr style={{ width: '80%', borderWidth: 1, borderStyle: 'solid' }} />
                <TotalScore>{score}</TotalScore>
            </FrameSquare>
        </FrameContainer>
    );
};

export default Frame;
