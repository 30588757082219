import ResetPasswordForm from "@components/authentication/ResetPasswordForm";
import strings from "@resources/strings";

function ResetPassword () {
    return (
        <>
            <h1>{strings.resetPassword}</h1>
            <ResetPasswordForm />
        </>
    );
}

export default ResetPassword;
