import { useEffect } from "react";
import { useNavigate } from "react-router";
import ServerLoginForm from "@components/authentication/ServerLoginForm";
import { useServerAccount } from "@hooks/AccountContext";
import { useEthereum } from '@hooks/EthereumContext';
import { useSetNetwork } from "@hooks/GeneralContext";
import routes from "@resources/Routes";
import strings from "@resources/strings";
import { useChain } from "@hooks/ChainContext";

function ServerLogin() {
    const { actor } = useChain();
    const { serverAccountInfo } = useServerAccount();
    const { account, setDisconnect } = useEthereum();
    const { connectedNetwork } = useSetNetwork();

    const navigate = useNavigate();

    useEffect(() => {
        if (!connectedNetwork) {
            setDisconnect(true);
        } else if (serverAccountInfo) {
            navigate(routes.home);
        }
    }, [ account, actor ]);

    return (
        <>
            <h1>{strings.logIn}</h1>
            <ServerLoginForm />
        </>
    );
}

export default ServerLogin;
