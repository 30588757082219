const routes = {
    root: '/',
    home: '/home',
    login: '/login',
    register: '/register',
    resetPassword: '/reset-password',
    requestAccountActivation: '/request-account-activation',
    ualLogin: '/ual-login',
    nftNotFound: '/nft-not-found',
    characterCreator: '/character-creator-event',
    game: '/game',
    tournament: '/tournament',
    practice: '/practice',
    myCharacters: '/my-characters',
    inventory: '/inventory',
    matchHistory: '/matchHistory'
};

export default routes;
