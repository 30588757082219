import { useState, useEffect } from "react";
import { getCharacterPieceImages } from "@api/Api";
import styled from "@emotion/styled";
import PieceLayer from '@components/PieceLayer';
import PieceSelector from '@components/PieceSelector';
import Save from '@components/Save';
import strings from '@resources/strings';

import Button from "@components/Button";
import ConfirmationModal from '@components/ConfirmationModal';
import { useTheme } from "@emotion/react";
import { useSetNetwork } from "@hooks/GeneralContext";
import { useFeatureFlags } from "@hooks/FeatureFlagsContext";
import { CHARACTER_MINT_CONTRACT_ADDRESS, FEATURE_CHARACTER_DELETE, OPENSEA, RARIBLE } from "@resources/Globals";
import Link from "@components/Link";

const CharacterCreatorContainer = styled.div(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center',
    [ theme.mediaQuery.tabletUp ]: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        maxWidth: theme.size.characterLayerSizeXL
    }
}));

const LayerContainer = styled.div(({ border, theme }) => ({
    margin: `${theme.spacing.xs} 0px`,
    position: 'relative',
    width: '90vw',
    height: 'calc(90vw + 150px)',
    [ theme.mediaQuery.largeMobileUp ]: {
        height: 'calc(90vw + 210px)'
    },
    [ theme.mediaQuery.tabletUp ]: {
        margin: theme.spacing.s,
        height: border ? '672px' : theme.size.characterLayerSize,
        width: theme.size.characterLayerSize,
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    }
}));

const BorderContainer = styled.div(({ pieceIndex, theme }) => ({
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: pieceIndex,
    [ theme.mediaQuery.tabletUp ]: {
        top: -12
    }
}));

const ImageContainer = styled.img(({ theme }) => ({
    width: '100%',
    borderRadius: theme.borderRadius.xl
}));

const SelectorsContainer = styled.div(({ theme }) => ({
    padding: theme.spacing.xxs,
    width: theme.size.selectorsContainerWidth,
    [ theme.mediaQuery.tabletUp ]: {
        margin: theme.spacing.s
    }
}));

const SaveContainer = styled.div(({ theme }) => ({
    margin: theme.spacing.xs
}));

const CharacterCreator = ({ selectedArts = null, selectedNFT = "", uuid = null, selectedCharacter, onCharacterSubmitted = () => { }, isMethodSelected = "", onDeleteCharacter = () => { }, characterName, setNameErrorMessage = () => {} }) => {
    const [ selectedPieces, setSelectedPieces ] = useState({});
    const [ pieces, setPieces ] = useState({});
    const [ categories, setCategories ] = useState([]);
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const { connectedNetwork } = useSetNetwork();
    const [ showDeleteConfirmation, setShowDeleteConfirmation ] = useState(false);

    const theme = useTheme();
    const CHARACTER_CREATOR_ENABLED = useFeatureFlags().characterCreator;

    useEffect(() => {
        if (isMethodSelected || uuid) {
            getPieceImages();
        }
    }, [ isMethodSelected ]);

    useEffect(() => {
        selectArts();
    }, [ selectedArts, pieces, categories ]);

    const selectArts = () => {
        selectedArts ? setSelectedPieces(selectedArts) : randomizeSelectedPieces(pieces, categories);
    };

    const getPieceImages = () => {
        const success = (response) => {
            setPieces(response);
            setCategories(Object.keys(response));
        };

        const error = (response) => {
            setShowErrorModal(true);
            setErrorMessage(response);
        };

        getCharacterPieceImages(success, error);
    };

    const randomizeSelectedPieces = (pieces = {}, categories = []) => {
        let initialSelectedPieces = {};

        for (let category of categories) {
            initialSelectedPieces[category] = pieces[category][Math.floor(Math.random() * pieces[category].length)].label;
        }

        setSelectedPieces(initialSelectedPieces);
    };

    const onPartChange = (partName, newName) => {
        setSelectedPieces((prevSelectedPieces) => ({ ...prevSelectedPieces, [partName]: newName }));
    };

    return (
        <>
            { CHARACTER_CREATOR_ENABLED && connectedNetwork === "ethereum" && uuid && !selectedCharacter.active && (!selectedCharacter.data || !selectedCharacter.data.tx_hash)
                ?
                <SaveContainer>
                    {
                        connectedNetwork === "ethereum" ?
                            <p style={{ color: theme.colors.error.main }}>
                                <b>
                                    <i>{strings.NFTNotSend}</i>
                                </b>
                            </p> : null
                    }
                    <Save name={characterName} selectedPieces={selectedPieces} selectedNFT={selectedNFT} uuid={uuid} onCharacterSubmitted={onCharacterSubmitted} setNameErrorMessage={setNameErrorMessage} />
                    {
                        CHARACTER_CREATOR_ENABLED && FEATURE_CHARACTER_DELETE ?
                            <Button outline onClick={() => setShowDeleteConfirmation(true)}>
                                {strings.delete} {selectedCharacter.name}
                            </Button>
                            : null
                    }
                </SaveContainer>
                : null}
            <CharacterCreatorContainer>
                <LayerContainer border={uuid}>
                    {categories.map((category) => <PieceLayer key={category} category={category} selectedPiece={selectedPieces[category]} pieces={pieces[category]} pieceIndex={categories.indexOf(category)} />)}
                    {uuid ? <BorderContainer pieceIndex={10}><ImageContainer src='/assets/border_gray.png' /></BorderContainer> : null}
                </LayerContainer>
                { CHARACTER_CREATOR_ENABLED && (!uuid || connectedNetwork === "wax") ?
                    <SelectorsContainer>
                        <Button styles={{ width: 150, marginBottom: theme.spacing.xxs }} onClick={() => randomizeSelectedPieces(pieces, categories)}>{strings.random}</Button>
                        {categories.map((category) => <PieceSelector key={category} category={category} selectedPiece={selectedPieces[category]} pieces={pieces[category]} onSelectedPieceChange={onPartChange} />)}
                        <SaveContainer>
                            <Save name={characterName} selectedPieces={selectedPieces} selectedNFT={selectedNFT} uuid={uuid} onCharacterSubmitted={onCharacterSubmitted} setNameErrorMessage={setNameErrorMessage} />
                        </SaveContainer>
                    </SelectorsContainer>
                    : null
                }
            </CharacterCreatorContainer>
            {connectedNetwork === "ethereum" && selectedCharacter.nft_hash ?
                <Link href={`${OPENSEA}/assets/${CHARACTER_MINT_CONTRACT_ADDRESS}/${selectedCharacter.nft_hash}`}  target="_blank">{strings.linkToMarketPlace}</Link>
                : null}
            { CHARACTER_CREATOR_ENABLED && FEATURE_CHARACTER_DELETE && connectedNetwork === "wax" && selectedCharacter.uuid !== 0 ? (
                <Button onClick={() => setShowDeleteConfirmation(true)}>
                    {strings.delete} {selectedCharacter.name}
                </Button>
            ) : null}
            <ConfirmationModal
                title={strings.error}
                show={showErrorModal}
                onConfirm={() => {setShowErrorModal(false); setErrorMessage("");}}
                onClose={() => {setShowErrorModal(false); setErrorMessage("");}}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
            <ConfirmationModal
                title={strings.delete + " " + selectedCharacter.name}
                show={showDeleteConfirmation}
                confirmationButtonLabel={strings.delete}
                onConfirm={() => {
                    onDeleteCharacter(selectedCharacter.nft_hash);
                    setShowDeleteConfirmation(false);
                }}
                onClose={() => {
                    setShowDeleteConfirmation(false);
                }}
            >
                {strings.deleteConfirmationMessage}
            </ConfirmationModal>
        </>
    );
};

export default CharacterCreator;
