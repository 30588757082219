import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import routes from "@resources/Routes";
import BowlingGameLandingPage from "./BowlingGameLandingPage";
import Practice from "./Practice";
import Tournament from "./Tournament";
import MyCharacters from "./MyCharacters";
import Inventory from "./Inventory";
import { useSetNetwork } from "@hooks/GeneralContext";
import { getServerAccountInfo, useServerAccount, useUserPins } from "@hooks/AccountContext";
import { useChain } from "@hooks/ChainContext";

const BowlingGame = () => {
    const { actor } = useChain();
    const navigate = useNavigate();
    const { connectedNetwork, setDisconnect } = useSetNetwork();
    const { serverAccountInfo } = useServerAccount();
    const { pins, refreshPins } = useUserPins();
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname.endsWith(routes.game) || pins === null) {
            refreshPins();
        }
    }, [ pathname, pins ]);

    useEffect(() => {
        const serverInfo = serverAccountInfo || getServerAccountInfo();
        if (serverInfo && connectedNetwork) {
            if (connectedNetwork !== "wax") {
                navigate(routes.home);
            } else if (actor && serverInfo.wax_account && serverInfo.wax_account !== actor) {
                setDisconnect(true);
            }
        } else {
            navigate(routes.root);
        }

    }, [ serverAccountInfo, actor, connectedNetwork ]);

    return (
        <Routes>
            <Route path="/" element={<MyCharacters />} />
            <Route path={routes.practice} element={<Practice />} />
            <Route path={routes.practice + '/:uuid'} element={<Practice />} />
            <Route path={routes.tournament} element={<Tournament />} />
            <Route path={routes.matchHistory} element={<BowlingGameLandingPage />} />
            <Route path={routes.inventory} element={<Inventory />} />
        </Routes>
    );
};

export default BowlingGame;
