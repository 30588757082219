import { useState } from "react";
import { addTxHashCharacter, deleteCharacter, deleteEthCharacter, saveCharacter, saveNftImagePinata, updateCharacter } from '@api/Api';
import { activeUserFromSession, transferNftToCustodyAccount } from "@api/AtomicHubApi";
import { transferNftToCustodyAccountEth } from "@api/EthApi";
import { useServerAccount } from "@hooks/AccountContext";
import { useEthereum } from "@hooks/EthereumContext";
import { useSetNetwork } from "@hooks/GeneralContext";
import { useFeatureFlags } from "@hooks/FeatureFlagsContext";
import { CHARACTER_MINT_CONTRACT_ADDRESS, OPENSEA, RARIBLE } from "@resources/Globals";
import strings from '@resources/strings';
import Button from '@components/Button';
import ConfirmationModal from '@components/ConfirmationModal';
import Link from "@components/Link";
import Loading from "@components/Loading";
import { useChain } from "@hooks/ChainContext";

function Save({
    name = "",
    selectedPieces = {},
    selectedNFT = "",
    uuid = null,
    onCharacterSubmitted = () => { },
    setNameErrorMessage = () => { }
}) {
    const { session } = useChain();
    const [ showSuccessModal, setShowSuccessModal ] = useState(false);
    const [ showConfirmationModal, setShowConfirmationModal ] = useState(false);
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ savedUuid, setSavedUuid ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ tokenId, setTokenId ] = useState(null);
    const { characterMintContract, account, providerEthers,
        accountTickets, lastCharacterId, internalRefreshCreatorAccountNftsEth } = useEthereum();
    const { serverAccountInfo } = useServerAccount();
    const CHARACTER_CREATOR_ENABLED = useFeatureFlags().characterCreator;

    const { connectedNetwork } = useSetNetwork();

    const mintCharacter = async (uri, uuid) => {
        const success = (response) => {
            console.debug(response.hash);
            mintEvent();
            setSavedUuid(uuid);
            addTxHashCharacter(uuid, response.hash);
        };

        const error = (response) => {
            deleteEthCharacter(uuid);
            setLoading(false);
            setShowErrorModal(true);
            setErrorMessage(response);
        };

        const mintEvent = async () => {
            characterMintContract.on("CharacterCreated", (creator, characterId) => {
                if (Number(characterId.toString()) !== lastCharacterId && creator.toLowerCase() === account) {
                    setLoading(false);
                    setShowSuccessModal(true);
                    setTokenId(characterId.toString());
                }
            });
        };

        setLoading(true);
        await transferNftToCustodyAccountEth(uri, characterMintContract, account, providerEthers, success, error);

    };

    const storeNftImagePinata = async (uuid) => {
        const success = async (response) => {
            console.debug("SUCCESS", response);
            const ipfsHash = `ipfs://${response.data.pined_json.IpfsHash}`;
            await mintCharacter(ipfsHash, uuid);
        };

        const error = (response) => {
            console.debug("ERROR", response);
            deleteEthCharacter(uuid);
            setLoading(false);
            setShowErrorModal(true);
            setErrorMessage(response);
        };

        setLoading(true);
        await saveNftImagePinata(uuid, success, error);
    };

    const update = () => {
        const payload = {
            name: name,
            selected_arts: selectedPieces,
            nft_origin: "WAX"
        };

        const success = (response) => {
            setLoading(false);
            setShowSuccessModal(true);
            setSavedUuid(response.uuid);
        };

        const error = (response) => {
            setLoading(false);
            setShowErrorModal(true);
            setErrorMessage(response);
        };

        setLoading(true);
        updateCharacter(payload, uuid, success, error);
    };

    const save = () => {
        const payload = {
            user_uuid: serverAccountInfo.uuid,
            name: name,
            selected_arts: selectedPieces
        };

        if (connectedNetwork === "wax") {
            payload.nft_hash = selectedNFT;
            payload.nft_origin = "WAX";
        }

        if (connectedNetwork === "ethereum") {
            payload.nft_origin = "ETH";
        }

        const success = (response) => {
            setSavedUuid(response.uuid);
            transferNft(response.uuid);
        };

        const error = (response) => {
            setLoading(false);
            setShowErrorModal(true);
            setErrorMessage(response);
        };

        setLoading(true);
        saveCharacter(payload, success, error);
    };

    const transferNft = async (uuid) => {
        const success = () => {
            setLoading(false);
            setShowSuccessModal(true);
        };

        const error = (response) => {
            if (connectedNetwork === "wax") {
                deleteCharacter(uuid);
            } else {
                deleteEthCharacter(uuid);
            }
            setLoading(false);
            setShowErrorModal(true);
            setErrorMessage(response);
        };

        if (connectedNetwork === "ethereum") {
            storeNftImagePinata(uuid);
        } else {
            transferNftToCustodyAccount(activeUserFromSession(session), selectedNFT, uuid, success, error);
        }
    };

    const saveAction = () => {
        if (name === "") {
            setNameErrorMessage("Name cannot be empty");
            return;
        }

        if (connectedNetwork === "wax") {
            if (uuid) {
                update();
            } else {
                if (!selectedNFT) {
                    setErrorMessage(strings.nftWAXNotSelected);
                    setShowErrorModal(true);
                    return;
                }
                save();
            }
        } else if (connectedNetwork === "ethereum") {
            if (accountTickets === 0) {
                setErrorMessage(strings.noTicketEth);
                setShowErrorModal(true);
                return;
            }
            setShowConfirmationModal(true);
        }
    };

    return (
        CHARACTER_CREATOR_ENABLED ?
            <>
                <Button styles={{ width: 150 }} onClick={saveAction}>{connectedNetwork === "wax" ? strings.save : strings.mint}</Button>
                <ConfirmationModal
                    title={strings.save + " " + strings.success}
                    show={showSuccessModal}
                    onConfirm={() => {
                        setShowSuccessModal(false); onCharacterSubmitted(savedUuid); if (connectedNetwork === "ethereum") { internalRefreshCreatorAccountNftsEth(); }
                        setTokenId(null);
                    }}
                    onClose={() => {
                        setShowSuccessModal(false); onCharacterSubmitted(savedUuid); if (connectedNetwork === "ethereum") { internalRefreshCreatorAccountNftsEth(); }
                        setTokenId(null);
                    }}
                    noCancel
                >
                    {strings.saveSuccessfulNote}
                    {connectedNetwork === "ethereum" ?
                        <>
                            <p>{strings.linkNFTMarketplace}</p>
                            <Link href={`${OPENSEA}/assets/${CHARACTER_MINT_CONTRACT_ADDRESS}/${tokenId}`}  target="_blank" onClick={() => {
                                setShowSuccessModal(false);
                                onCharacterSubmitted(savedUuid);
                                setTokenId(null);
                            }}
                            >{name}</Link>
                        </>
                        : null}
                </ConfirmationModal>
                <ConfirmationModal
                    title={strings.save + " " + strings.error}
                    show={showErrorModal}
                    onConfirm={() => {setShowErrorModal(false); setErrorMessage("");}}
                    onClose={() => {setShowErrorModal(false); setErrorMessage("");}}
                    noCancel
                >
                    {errorMessage}
                </ConfirmationModal>
                <ConfirmationModal
                    title={strings.mintEthTitle}
                    show={showConfirmationModal}
                    onConfirm={() => {setShowConfirmationModal(false); save();}}
                    onClose={() => {setShowConfirmationModal(false); }}
                >
                    {strings.mintEthWarning}
                </ConfirmationModal>
                { loading ? <Loading isFullScreen /> : null }
            </>
            : null
    );
}

export default Save;
