import Button from '@components/Button';
import { useChain } from "@hooks/ChainContext";
import { useCallback } from "react";

function UALLoginButton({ label, setNetwork, linking = false, setIsLinking, ...otherProps }) {
    const { login } = useChain();

    const handleLogin = useCallback(async () => {
        if (linking) {
            setIsLinking(true);
        }

        await login();

        setNetwork("wax");
    }, [ login ]);

    return <Button onClick={handleLogin} {...otherProps}>{label}</Button>;
}

export default UALLoginButton;
