import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getServerAccountInfo, useCreatorAccountNfts, useServerAccount } from "@hooks/AccountContext";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import Select from "react-select/creatable";
import CharacterCreator from "./CharacterCreator";
import UploadYourCharacter from "./UploadYourCharacter";
import Alert from "@components/Alert";
import Button from "@components/Button";
import Input from "@components/Input";
import Tabs from "@components/Tabs";
import ConfirmationModal from "@components/ConfirmationModal";
import Link from "@components/Link";
import Loading from "@components/Loading";
import Container from '@components/Container';
import { addTxHashCharacter, deleteCharacter, deleteEthCharacter, getCreatedCharacters } from "@api/Api";
import { getNft } from "@api/AtomicHubApi";
import routes from "@resources/Routes";
import { useEthereum } from "@hooks/EthereumContext";
import { buyATicket } from "@api/EthApi";
import { useSetNetwork } from "@hooks/GeneralContext";
import theme from "@styles/Theme";
import { findAssetTableWax } from "@api/WaxApi";
import { useFeatureFlags } from '@hooks/FeatureFlagsContext';
import strings from "@resources/strings";
import { useChain } from "@hooks/ChainContext";

const BuyTicketsEthText = styled.div(({ theme }) => ({
    ...theme.typography.h4
}));

const Paragraph = styled.div(({ theme }) => ({
    ...theme.typography.h4,
    fontWeight: 300,
    maxWidth: '60ch'
}));

const NumberSpan = styled.span(({ theme }) => ({
    ...theme.typography.h4,
    fontWeight: 800
}));

const BuyTicketsValues = styled.div(({ theme }) => ({
    ...theme.typography.pTiny,
    display: 'flex',
    flexDirection: 'column'
}));

const BuyTicketsButton = {
    ...theme.typography.h6,
    fontWeight: 700,
    paddingRight: theme.spacing.s,
    paddingLeft: theme.spacing.s
};

const TitleContainer = styled.h1(({ theme }) => ({
    ...theme.typography.h1,
    margin: theme.spacing.xs,
    color: theme.colors.common.text,
    maxWidth: "60ch"
}));

//TODO CSS: Remove TextContainer
const TextContainer = styled.h4(({ theme }) => ({
    ...theme.typography.h4,
    margin: theme.spacing.xs,
    color: theme.colors.common.text,
    textAlign: "start",
    maxWidth: "60ch"
}));

const ParagraphContainer = styled.h5(({ theme }) => ({
    ...theme.typography.p,
    margin: theme.spacing.xs,
    color: theme.colors.common.text,
    textAlign: "start",
    maxWidth: "60ch"
}));

const TicketsRemainingText = styled.div(({ theme }) => ({
    ...theme.typography.h4
}));

const TicketsRemainingNumber = styled.div(({ theme }) => ({
    ...theme.typography.h1,
    color: theme.colors.common.text
}));

const TicketsRemainingWarning =  styled.div(({ theme }) => ({
    ...theme.typography.pTiny,
    color: theme.colors.common.text
}));

const SelectContainer = styled.div(({ theme }) => ({
    margin: theme.spacing.xs
}));

const TabsContainer = styled.div(({ theme }) => ({
    margin: theme.spacing.xs,
    [theme.mediaQuery.tabletUp]: {
        width: "70vw",
        maxWidth: theme.size.characterLayerSizeXL
    }
}));

const InputContainer = styled.div(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing.xxs,
    maxWidth: theme.size.characterLayerSize
}));

const ButtonsContainer = styled.div(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
    textAlign: "center",
    marginBottom: theme.spacing.xs,
    [theme.mediaQuery.tabletUp]: {
        flexDirection: "row"
    }
}));

const LinkContainer = styled.div(({ theme }) => ({
    width: "100%",
    textAlign: "center",
    marginBottom: theme.spacing.xs
}));

function CharacterCreatorEvent() {
    const { isAuthenticated, actor, permission } = useChain();
    const navigate = useNavigate();
    const [ loading, setLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ showBuyModal, setShowBuyModal ] = useState(false);
    const [ showBuySucceededModal, setShowBuySucceededModal ] = useState(false);
    const [ successMessage, setSuccessMessage ] = useState("");
    const [ successTitle, setSuccessTitle ] = useState("");
    const [ showSuccessModal, setShowSuccessModal ] = useState(false);
    const { serverAccountInfo, setServerAccountInfo } = useServerAccount();
    const [ characters, setCharacters ] = useState([]);
    const [ selectedCharacter, setSelectedCharacter ] = useState({});
    const [ isMethodSelected, setIsMethodSelected ] = useState(false);
    const [ isCreationSelected, setIsCreationSelected ] = useState(false);
    const [ selectedNFT, setSelectedNFT ] = useState({});
    const [ numberTickets, setNumberTickets ] = useState(1);
    const [ waxTickets, setWaxTickets ] = useState([]);
    const [ deletedCharactersTicket, setDeletedCharacterTickets ] = useState([]);
    const [ nameErrorMessage, setNameErrorMessage ] = useState("");
    const [ characterName, setCharacterName ] = useState("");

    const CHARACTER_CREATOR_ENABLED = useFeatureFlags().characterCreator;
    const {
        account,
        accountTickets,
        ticketsLeft,
        characterMintContract,
        internalRefreshCreatorAccountNftsEth,
        providerEthers,
        usdPrice,
        ethUsdPrice
    } = useEthereum();

    const { creatorAccountNfts, refreshCreatorAccountNfts } = useCreatorAccountNfts();
    const { connectedNetwork } = useSetNetwork();

    const theme = useTheme();

    useEffect(() => {
        const success = () => {
            getCharactersList(selectedCharacter.uuid, true, connectedNetwork);
        };

        const checkTransaction = async () => {
            const tx = await providerEthers.getTransactionReceipt(selectedCharacter.data.tx_hash);
            console.debug("Transaction", tx);
            if (!tx) {return;}
            if (tx.blockHash && tx.status === 1) {
                if (tx.confirmations > 5) {
                    getCharactersList();
                }
            } else {
                addTxHashCharacter(selectedCharacter.uuid, null, success);
            }

        };
        if (selectedCharacter && selectedCharacter.uuid !== 0 && selectedCharacter.name) {
            setCharacterName(selectedCharacter.name);
            if (providerEthers && !selectedCharacter.active && selectedCharacter.data && selectedCharacter.data.tx_hash) {
                checkTransaction();
            }
        }
    }, [ selectedCharacter ]);

    useEffect(() => {
        if (connectedNetwork === "wax") {
            updateCharactersManually(deletedCharactersTicket);
        }
    }, [ creatorAccountNfts, connectedNetwork, characters ]);

    useEffect(() => {
        const serverInfo = getServerAccountInfo();
        if (serverInfo && connectedNetwork) {
            getCharactersList(undefined, true, connectedNetwork);
        } else {
            navigate(routes.root);
        }

    }, [ serverAccountInfo, actor, account, connectedNetwork ]);

    const updateCharactersManually = async (deletedCharacters) => {
        if (connectedNetwork === "wax" && characters) {
            let waxCurrentTickets = {};
            const characterWaxTickets = characters.map(character => character.nft_hash);
            const ticketsApi = creatorAccountNfts ? Object.keys(creatorAccountNfts) : [];
            for (let i = 0; i < ticketsApi.length; ++i) {
                const find = characterWaxTickets.find(ticket => ticket === ticketsApi[i]);
                if (!find) {
                    waxCurrentTickets[ticketsApi[i]] = Object.values(creatorAccountNfts)[i];
                }
            }

            const deletedTickets = [];
            for (let i = 0; i < deletedCharacters.length; ++i) {
                if (!ticketsApi.find(ticket => ticket === deletedCharacters[i])) {
                    deletedTickets.push(deletedCharacters[i]);
                    const nft = await getNft(deletedCharacters[i]);
                    waxCurrentTickets[deletedCharacters[i]] = nft;
                }
            }

            setDeletedCharacterTickets(deletedTickets);
            setWaxTickets(waxCurrentTickets);
            setSelectedNFT(Object.values(waxCurrentTickets)[0]);
        } else {
            setSelectedNFT({});
        }
    };

    const buyTicket = async () => {
        const onSuccess = (response) => {
            buyEvent();
            console.debug(response.hash);
        };

        const onError = (response) => {
            setErrorMessage(response);
            setShowErrorModal(true);
            setLoading(false);
        };

        const buyEvent = async() => {
            characterMintContract.on("TicketBought", (buyer, tickets) => {
                if (Number(tickets.toString()) > accountTickets && buyer.toLowerCase() === account) {
                    setLoading(false);
                    setShowBuySucceededModal(true);
                    internalRefreshCreatorAccountNftsEth();
                }
            });
        };

        setLoading(true);
        await buyATicket(characterMintContract, account, numberTickets, providerEthers, onSuccess, onError);
    };

    const onCharacterSubmitted = (uuid) => {
        if (connectedNetwork === "wax") {
            refreshCreatorAccountNfts();
        } else {
            internalRefreshCreatorAccountNftsEth();
        }
        setIsMethodSelected(false);
        setIsCreationSelected(false);
        getCharactersList(uuid, true, connectedNetwork);
    };

    const getCharactersList = (uuid = null, setCharacter = false, connectedNetwork) => {
        const serverAccount = serverAccountInfo ? serverAccountInfo : getServerAccountInfo();
        if ((isAuthenticated && actor !== serverAccount.wax_account) ||
            (account && account !== serverAccount.eth_account)) {
            return;
        }
        const success = (response) => {
            let createdCharactersList = response;
            let i = 1;

            // As name is optional, we want to fill in the name field with a default value if it's empty
            if (createdCharactersList && createdCharactersList[0] && createdCharactersList[0].uuid) {
                for (let character of createdCharactersList) {
                    if (character.uuid) {
                        if (!character.name) {
                            character.name = strings.character + " 0" + i;
                            i += 1;
                        }
                    }
                }
            }

            if (CHARACTER_CREATOR_ENABLED) {
                createdCharactersList.push({ uuid: 0, name: strings.createNewCharacter });
            }

            const origin = connectedNetwork === "wax" ? "WAX" : "ETH";

            const createdCharactersListNetwork = createdCharactersList.filter(
                (character) => character.nft_origin === origin
            );

            if (CHARACTER_CREATOR_ENABLED) {
                createdCharactersListNetwork.unshift({ uuid: 0, name: strings.createNewCharacter });
            }

            setCharacters(createdCharactersListNetwork);

            if (setCharacter) {
                let selectedIndex = 0;
                if (uuid !== null) {
                    selectedIndex = createdCharactersListNetwork.findIndex((character) => character.uuid === uuid);
                }

                setSelectedCharacter(createdCharactersListNetwork[selectedIndex]);
            }
        };

        const error = (response) => {
            setShowErrorModal(true);
            setErrorMessage(response);
        };

        if (serverAccount) {
            getCreatedCharacters(serverAccount.uuid, success, error);
        }
    };

    const onCharacterChange = (uuid) => {
        let newSelectedCharacter = characters.find((character) => character.uuid === uuid);

        if (newSelectedCharacter.uuid === 0) {
            setIsMethodSelected(false);
            setCharacterName("");
        } else {
            setCharacterName(newSelectedCharacter.name);
        }

        setSelectedCharacter(newSelectedCharacter);
    };

    const onDeleteCharacter = (nft_hash) => {
        const success = () => {
            setSuccessTitle(strings.delete + " " + strings.success);
            setSuccessMessage(strings.deleteSuccessfulNote);
            setShowSuccessModal(true);
            setLoading(false);
            getCharactersList(undefined, true, connectedNetwork);
            if (connectedNetwork === "wax") {
                const deletedCharacters = [ ...deletedCharactersTicket, nft_hash ];
                setDeletedCharacterTickets(deletedCharacters);
                refreshCreatorAccountNfts();
            }
        };

        const error = (response) => {
            setErrorMessage(response);
            setShowErrorModal(true);
            setLoading(false);
        };

        setLoading(true);
        if (connectedNetwork === "wax") {
            deleteCharacter(selectedCharacter.uuid, success, error);
        } else {
            deleteEthCharacter(selectedCharacter.uuid, success, error);
        }
    };

    const getTicketsCost = () => {
        if (usdPrice && ethUsdPrice) {
            return `${ (numberTickets * usdPrice / ethUsdPrice).toFixed(9) } ETH`;
        } else {
            return "";
        }
    };

    const getUsdPrice = () => {
        if (usdPrice) {
            return `${numberTickets * usdPrice} USD`;
        } else {
            return "";
        }
    };

    const bigButtonStyle = { height: 240, width: 240, margin: theme.spacing.xs, fontSize: 28 };

    return (
        <>
            {console.debug("SELECTED CHARACTER", selectedCharacter)}
            {/* TODO CSS: BUTTON NEEDS TO BE BEAUTIFIED */}
            <Button onClick={() => navigate(routes.home)}>{strings.back}</Button>
            <TitleContainer>
                {strings.productName}
            </TitleContainer>
            {CHARACTER_CREATOR_ENABLED ?
                <div style={{ display: "flex" }}>
                    {connectedNetwork === "wax" ? (
                        <>
                            <TextContainer>{`${Object.keys(waxTickets).length} ${strings.ticketsRemaining}`}</TextContainer>
                            <SelectContainer>
                                <Select
                                    value={selectedNFT}
                                    onChange={setSelectedNFT}
                                    options={waxTickets ? Object.values(waxTickets) : [ "" ]}
                                    getOptionLabel={(option) =>
                                        option.template_mint
                                            ? `${option.template.immutable_data.name} #${option.template_mint}`
                                            : strings.noTicketsLeft
                                    }
                                    getOptionValue={(option) => option.asset_id}
                                    placeholder={strings.noTicketsLeft}
                                    styles={theme.select}
                                />
                            </SelectContainer>
                        </>
                    ) : (
                        <>
                            {accountTickets != null ?
                                <Container direction="column" margin={theme.spacing.xs}>
                                    <TicketsRemainingText>
                                        {strings.youHave}
                                    </TicketsRemainingText>
                                    {accountTickets > 0 ?
                                        <TicketsRemainingNumber>
                                            {accountTickets}
                                        </TicketsRemainingNumber>
                                        : null
                                    }
                                    <TicketsRemainingText>
                                        {accountTickets > 0 ? strings.ticketsRemaining : strings.noTicketsLeft}
                                    </TicketsRemainingText>
                                    <TicketsRemainingWarning>
                                        {strings.ticketsReduction}
                                    </TicketsRemainingWarning>
                                </Container>
                                : ""
                            }
                        </>
                    )}
                </div> : null}
            {CHARACTER_CREATOR_ENABLED && connectedNetwork === "ethereum" ? (
                <Container
                    direction="column"
                    margin={theme.spacing.m}
                    padding={theme.spacing.l}
                    style={{
                        border: `1px solid ${theme.colors.common.backgroundLighter}`,
                        borderRadius: theme.borderRadius.m
                    }}
                    isFullWidth={false}
                >
                    <Container direction="column" style={{ marginBottom: theme.spacing.l }}>
                        <BuyTicketsEthText>{strings.ethBuy.replace('{0}', ticketsLeft)}</BuyTicketsEthText>
                        <div>{`${strings.outOf} 4000`}</div>
                    </Container>
                    <Container gap={theme.spacing.xs}>
                        <Container direction="column" isFullWidth={false}>
                            <InputContainer>
                                <Input
                                    type="number"
                                    min={1}
                                    max={ticketsLeft ? ticketsLeft : 4000}
                                    label={strings.numberTicketsBuy}
                                    placeholder="0"
                                    value={numberTickets}
                                    onChange={(e) => setNumberTickets(e.target.value)}
                                />
                            </InputContainer>
                            <BuyTicketsValues>
                                {getUsdPrice()} / {getTicketsCost()}
                            </BuyTicketsValues>
                        </Container>
                        <Button style={BuyTicketsButton} onClick={() => setShowBuyModal(true)}>{strings.buyTickets}</Button>
                    </Container>
                </Container>
            ) : null}
            <TabsContainer>
                <Tabs tabs={characters} onChange={onCharacterChange} selectedTab={selectedCharacter.uuid} />
            </TabsContainer>
            {
                connectedNetwork === "wax" &&
                characters.length > 0 &&
                selectedCharacter.uuid !== 0 &&
                !selectedCharacter.active ?
                    !findAssetTableWax(selectedCharacter.nft_hash)
                        ? <InputContainer>
                            <Alert>{strings.nftNotFoundInWallet}</Alert>
                        </InputContainer>
                        : null
                    : null
            }
            { connectedNetwork ?
                selectedCharacter.uuid === 0 ?
                    <>
                        {isMethodSelected ? (
                            <>
                                { CHARACTER_CREATOR_ENABLED ?
                                    connectedNetwork === "wax" || (connectedNetwork === "ethereum" && !selectedCharacter.active && !selectedCharacter.uuid) ?
                                        <InputContainer>
                                            <Input
                                                style={{ alignItems: "center" }}
                                                label={strings.characterName}
                                                value={characterName}
                                                onChange={(e) => {
                                                    if (nameErrorMessage) {
                                                        setNameErrorMessage("");
                                                    }
                                                    setCharacterName(e.target.value);
                                                }}
                                            />
                                            <p style={{ color: theme.colors.error.main }}>
                                                <b>
                                                    <i>{nameErrorMessage}</i>
                                                </b>
                                            </p>
                                        </InputContainer>
                                        : null
                                    : <TextContainer style={{ textAlign: 'center' }} >{characterName}</TextContainer> }
                                {isCreationSelected ? (
                                    <CharacterCreator
                                        selectedNFT={connectedNetwork === "wax" && selectedNFT ? selectedNFT.asset_id : ""}
                                        onCharacterSubmitted={onCharacterSubmitted}
                                        isMethodSelected={isMethodSelected}
                                        selectedCharacter={selectedCharacter}
                                        onDeleteCharacter={onDeleteCharacter}
                                        characterName={characterName}
                                        setNameErrorMessage={setNameErrorMessage}

                                    />
                                ) : (
                                    <UploadYourCharacter
                                        selectedNFT={connectedNetwork === "wax" && selectedNFT ? selectedNFT.asset_id : ""}
                                        onCharacterSubmitted={onCharacterSubmitted}
                                        selectedCharacter={selectedCharacter}
                                        onDeleteCharacter={onDeleteCharacter}
                                        characterName={characterName}
                                        setNameErrorMessage={setNameErrorMessage}
                                    />
                                )}
                                <Button
                                    onClick={() => {
                                        setIsMethodSelected(false);
                                        setIsCreationSelected(false);
                                    }}
                                    style={{ width: 150, margin: theme.spacing.xxs }}
                                >
                                    {strings.back}
                                </Button>
                            </>
                        ) : (
                            <ButtonsContainer>
                                <Button
                                    onClick={() => {
                                        setIsMethodSelected(true);
                                        setIsCreationSelected(true);
                                    }}
                                    style={bigButtonStyle}
                                >
                                    {<img style={{ width: 76, margin: theme.spacing.m }} src="/assets/plus.svg" />}
                                    {strings.create}
                                </Button>
                                <Button
                                    onClick={() => {
                                        setIsMethodSelected(true);
                                        setIsCreationSelected(false);
                                    }}
                                    style={bigButtonStyle}
                                >
                                    {<img style={{ width: 76, margin: theme.spacing.m }} src="/assets/upload.svg" />}
                                    {strings.upload}
                                </Button>
                            </ButtonsContainer>
                        )}
                    </>
                    : (
                        <>
                            {Object.keys(selectedCharacter).length > 0 ? (
                                <>
                                    {CHARACTER_CREATOR_ENABLED && (connectedNetwork === "wax" || (connectedNetwork === "ethereum" && !selectedCharacter.active && !selectedCharacter.uuid)) ?
                                        <InputContainer>
                                            <Input
                                                style={{ alignItems: "center" }}
                                                label={strings.characterName}
                                                value={characterName}
                                                onChange={(e) => {
                                                    if (nameErrorMessage) {
                                                        setNameErrorMessage("");
                                                    }
                                                    setCharacterName(e.target.value);
                                                }}
                                            />
                                            <p style={{ color: theme.colors.error.main }}>
                                                <b>
                                                    <i>{nameErrorMessage}</i>
                                                </b>
                                            </p>
                                        </InputContainer> : null}
                                    {!selectedCharacter.upload_only && selectedCharacter.uuid !== 0 ? (
                                        <CharacterCreator
                                            onCharacterSubmitted={onCharacterSubmitted}
                                            selectedArts={selectedCharacter.selected_arts}
                                            isMethodSelected={isMethodSelected}
                                            uuid={selectedCharacter.uuid}
                                            selectedCharacter={selectedCharacter}
                                            onDeleteCharacter={onDeleteCharacter}
                                            characterName={characterName}
                                            setNameErrorMessage={setNameErrorMessage}

                                        />
                                    ) : (
                                        <UploadYourCharacter
                                            onCharacterSubmitted={onCharacterSubmitted}
                                            uuid={selectedCharacter.uuid}
                                            selectedCharacter={selectedCharacter}
                                            onDeleteCharacter={onDeleteCharacter}
                                            characterName={characterName}
                                            setNameErrorMessage={setNameErrorMessage}
                                        />
                                    )}

                                </>
                            ) : null}
                        </>
                    )
                : null}
            {CHARACTER_CREATOR_ENABLED ?
                connectedNetwork === "wax" ? (
                    <>
                        <TextContainer>{strings.productReleaseWAXNote}</TextContainer>
                        <TextContainer>{strings.youCanBuyMoreTicketsWAX}</TextContainer>
                        <LinkContainer>
                            <Link href={strings.NFTMarketLink} target="_blank">
                                {strings.buyATicket}
                            </Link>
                        </LinkContainer>
                    </>
                ) : (
                    <TextContainer>{strings.productReleaseETHNote }</TextContainer>
                ) : null }
            <TextContainer>{strings.youCanJoinDiscord}</TextContainer>
            <LinkContainer>
                <Link href={strings.discordLink} target="_blank">
                    {strings.joinDiscord}
                </Link>
            </LinkContainer>
            <ConfirmationModal
                show={showErrorModal}
                title={strings.error}
                onClose={() => {
                    setErrorMessage("");
                    setShowErrorModal(false);
                }}
                onConfirm={() => {
                    setErrorMessage("");
                    setShowErrorModal(false);
                }}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
            <ConfirmationModal
                title={successTitle}
                show={showSuccessModal}
                onConfirm={() => {
                    setShowSuccessModal(false);
                    setSuccessMessage("");
                    setSuccessTitle("");
                }}
                onClose={() => {
                    setShowSuccessModal(false);
                    setSuccessMessage("");
                    setSuccessTitle("");
                }}
                noCancel
            >
                {successMessage}
            </ConfirmationModal>
            <ConfirmationModal
                title={strings.ticketPurchaseTitle}
                show={showBuySucceededModal}
                onConfirm={() => {
                    internalRefreshCreatorAccountNftsEth();
                    setShowBuySucceededModal(false);
                }}
                onClose={() => {
                    internalRefreshCreatorAccountNftsEth();
                    setShowBuySucceededModal(false);
                }}
                noCancel
            >
                {strings.buyEthSucceeded}
            </ConfirmationModal>
            <ConfirmationModal
                show={showBuyModal}
                title={strings.ticketPurchaseTitle}
                onConfirm={async () => {
                    await buyTicket();
                    setShowBuyModal(false);
                }}
                onClose={() => {
                    setShowBuyModal(false);
                }}
            >
                <>
                    <Paragraph>
                        {strings.purchaseAbout}
                    </Paragraph>
                    <Paragraph>
                        <NumberSpan>{numberTickets}</NumberSpan>
                        { numberTickets > 1 ? ' tickets for ' : ' ticket for ' }
                        <NumberSpan>{'$'}{numberTickets * usdPrice}</NumberSpan>
                    </Paragraph>
                    <ParagraphContainer style={{ textAlign: 'center' }}>
                        {strings.ticketsNoTransferable}
                    </ParagraphContainer>
                </>
            </ConfirmationModal>
            {loading ? <Loading isFullScreen /> : null}
        </>
    );
}

export default CharacterCreatorEvent;
