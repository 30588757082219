import styled from "@emotion/styled";
import { useEffect, useState } from "react";

const PinsContainer = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'min-content',
    float: 'right',
    userSelect: "none"
}));

const Pin = styled.div(({ knocked, theme }) => ({
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 20,
    borderColor: 'white',
    color: knocked ? 'transparent' : 'white',
    width: 20,
    height: 20,
    margin: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

const RowContainer = styled.div(({ index = 1 }) => ({
    display: 'flex',
    zIndex: index
}));

const Minimap = ({ pins = [] }) => {
    const [ pinsArray, setPinsArray ] = useState([]);

    useEffect(() => {
        let pinsArray = [];
        for (let i = 1; i <= 10; i++) {
            pinsArray.push(<Pin key={i} knocked={pins.includes(i)}>{i}</Pin>);
        }
        setPinsArray(pinsArray);
    }, [ pins ]);

    return (
        <PinsContainer>
            <RowContainer index={1}>{pinsArray.slice(6, 10)}</RowContainer>
            <RowContainer index={2}>{pinsArray.slice(3, 6)}</RowContainer>
            <RowContainer index={3}>{pinsArray.slice(1, 3)}</RowContainer>
            <RowContainer index={4}>{pinsArray[0]}</RowContainer>
        </PinsContainer>
    );
};

export default Minimap;
