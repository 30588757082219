import { Outlet, useLocation } from "react-router";
import styled from "@emotion/styled";

import Header from "@components/Header";
import Breadcrumb from "@components/Breadcrumb";

const ContentContainer = styled.div(() => ({
    paddingTop: 80,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center'
}));

const App = () => {
    const location = useLocation();
    return (
        <>
            {location.pathname !== '/ual-login' ? <Header /> : null}
            <ContentContainer>
                <Breadcrumb />
                <Outlet />
            </ContentContainer>
        </>
    );
};

export default App;
