import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import RegistrationForm from "@components/authentication/RegistrationForm";
import routes from "@resources/Routes";
import strings from "@resources/strings";
import ConfirmationModal from "@components/ConfirmationModal";
import { useSetNetwork } from "@hooks/GeneralContext";

function Register() {
    const [ showErrorModal, setShowErrorModal ] = useState(false);

    const { connectedNetwork } = useSetNetwork();
    const navigate = useNavigate();

    return (
        <>
            <h1>{strings.registration}</h1>
            <RegistrationForm />
            <ConfirmationModal
                show={showErrorModal}
                title={strings.error}
                onConfirm={() => { navigate(routes.login); setShowErrorModal(false); }}
                onClose={() => { navigate(routes.login); setShowErrorModal(false); }}
                noCancel
            >
                {connectedNetwork === "ethereum" ? strings.ethAccountAlreadyRegistered : strings.waxAccountAlreadyRegistered}
            </ConfirmationModal>
        </>
    );
}

export default Register;

