import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { refreshAuthenticationToken, validateUser, getPins, logoutUser } from '@api/Api';
import { getCharacterCreatorNfts } from '@api/AtomicHubApi';
import ConfirmationModal from '@components/ConfirmationModal';
import strings from '@resources/strings';
import { UI_URL } from '@resources/Globals';
import { useSetNetwork } from '@hooks/GeneralContext';
import { useFeatureFlags } from "@hooks/FeatureFlagsContext";
import { useChain } from "./ChainContext";

const AccountContext = createContext();

const AUTO_REFRESH_TIMEOUT = 15000;

export const LocalStorageServerAccountInfoKey = "ServerAccountInfo";

export const getServerAccountInfo = () => {
    const accountInfo = localStorage.getItem(LocalStorageServerAccountInfoKey);
    return accountInfo != null ? JSON.parse(accountInfo) : null;
};

const reduceNfts = (state, { type, values }) => {
    switch (type) {
    case "clear":
        return {};
    case "reset":
        return null;
    case "refresh":
        if (!state) {
            state = {};
        }
        Object.assign(state, values);
        for (let nft in state) {
            if (!values[nft]) {
                delete state[nft];
            }
        }
        return state;
    default:
        throw new Error();
    }
};

let serverTimeout = null;
let nftInterval = null;

function AccountProvider({ children }) {
    const { isAuthenticated, actor, logout: chainLogout } = useChain();

    const [ creatorAccountNfts, dispatchCreatorAccountNfts ] = useReducer(reduceNfts, null);
    const [ serverAccountInfo, internalSetServerAccountInfo ] = useState(null);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ creatorAccountNftsLength, setCreatorAccountNftsLength ] = useState(0);
    const [ pins, setPins ] = useState(null);

    const { setActiveAccounts } = useSetNetwork();
    const CHARACTER_CREATOR_ENABLED = useFeatureFlags().characterCreator;

    useEffect(() => {
        const storedServerAccountInfo = getServerAccountInfo();
        if (storedServerAccountInfo != null) {
            internalSetServerAccountInfo(storedServerAccountInfo);
            if (serverTimeout != null) {
                clearTimeout(serverTimeout);
            }
            validateUserInformation();
        }

        return () => {
            if (serverTimeout != null) {
                clearTimeout(serverTimeout);
            }

            if (nftInterval != null) {
                clearInterval(nftInterval);
            }
        };
    }, []);

    useEffect(() => {
        setActiveAccounts(serverAccountInfo);
    }, [ serverAccountInfo ]);

    useEffect(() => {
        if (isAuthenticated && creatorAccountNfts === null) {
            refreshCreatorAccountNfts();
        }
    }, [ isAuthenticated, creatorAccountNfts ]);

    const refreshCreatorAccountNfts = () => {
        if (nftInterval != null) {
            clearInterval(nftInterval);
        }
        if (CHARACTER_CREATOR_ENABLED !== false) {
            internalRefreshCreatorAccountNfts();
            nftInterval = setInterval(internalRefreshCreatorAccountNfts, AUTO_REFRESH_TIMEOUT);
        }
    };

    const internalRefreshCreatorAccountNfts = () => {
        const success = (result) => {
            dispatchCreatorAccountNfts({
                type: "refresh", values: result.reduce((obj, currentVal) => {
                    return {
                        ...obj,
                        [currentVal.asset_id]: currentVal
                    };
                }, {})
            });
            setCreatorAccountNftsLength(result.length);
        };

        const error = () => {
            dispatchCreatorAccountNfts({ type: "clear" });
            setCreatorAccountNftsLength(0);
            setShowErrorModal(true);
            setErrorMessage(errorMessage);
        };

        getCharacterCreatorNfts(actor, success, error);
    };

    const isCreatorAccount = () => {
        return creatorAccountNfts !== null && Object.keys(creatorAccountNfts).length > 0;
    };

    const setServerAccountInfo = (newInfo, login) => {
        if (newInfo == null) {
            localStorage.setItem(LocalStorageServerAccountInfoKey, null);
            internalSetServerAccountInfo(null);
            clearTimeout(serverTimeout);
        } else if (login) {
            let accInfo = { ...newInfo };

            const tokenBody = JSON.parse(atob(accInfo.access_token.split('.')[1]));

            accInfo.uuid = tokenBody.sub;
            accInfo.accountName = tokenBody.username;
            accInfo.eth_account = tokenBody.eth_account;
            accInfo.wax_account = tokenBody.wax_account;

            internalSetServerAccountInfo(accInfo);
            localStorage.setItem(LocalStorageServerAccountInfoKey, JSON.stringify(accInfo));
        } else {
            let accInfo = getServerAccountInfo();
            if (newInfo.eth_account) {
                accInfo.eth_account = newInfo.eth_account;
            }

            if (newInfo.wax_account) {
                accInfo.wax_account = newInfo.wax_account;
            }

            internalSetServerAccountInfo(accInfo);
            localStorage.setItem(LocalStorageServerAccountInfoKey, JSON.stringify(accInfo));
        }
    };

    const validateUserInformation = () => {
        const success = () => {
            serverTimeout = setTimeout(validateUserInformation, AUTO_REFRESH_TIMEOUT);
        };

        const error = () => {
            refreshToken();
        };

        validateUser(success, error);
    };

    const refreshToken = () => {
        if (serverAccountInfo == null) {
            setServerAccountInfo(null);
            window.location = UI_URL;
        }

        let tokenInfo = {
            grant_type: "refresh_token",
            token: serverAccountInfo.refresh_token
        };

        const success = (data) => {
            setServerAccountInfo(data, true);
            serverTimeout = setTimeout(validateUserInformation, AUTO_REFRESH_TIMEOUT);
        };

        const error = (response) => {
            setShowErrorModal(true);
            setErrorMessage(response);
            setServerAccountInfo(null);
            window.location = UI_URL;
        };

        refreshAuthenticationToken(tokenInfo, success, error);
    };

    const refreshPins = () => {
        const success = (response) => {
            setPins(response.pins);
        };

        getPins(success);
    };

    const logout = async () => {
        await chainLogout();
        logoutUser();
        setServerAccountInfo(null);
        clearInterval(nftInterval);
        dispatchCreatorAccountNfts({ type: 'reset' });
        setCreatorAccountNftsLength(0);
    };

    return (
        <AccountContext.Provider value={
            {
                creatorAccountNfts: creatorAccountNfts,
                creatorAccountNftsLength,
                serverAccountInfo,
                logout,
                setServerAccountInfo,
                refreshCreatorAccountNfts: refreshCreatorAccountNfts,
                internalRefreshCreatorAccountNfts,
                isCreatorAccount,
                refreshPins,
                pins
            }
        }
        >
            {children}
            <ConfirmationModal
                title={strings.error}
                show={showErrorModal}
                onConfirm={() => {
                    setShowErrorModal(false);
                    setErrorMessage("");
                }}
                onClose={() => {
                    setShowErrorModal(false);
                    setErrorMessage("");
                }}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
        </AccountContext.Provider>
    );
}

function useCreatorAccountNfts() {
    const { creatorAccountNfts, creatorAccountNftsLength, refreshCreatorAccountNfts, isCreatorAccount } = useContext(AccountContext);

    return { creatorAccountNfts, creatorAccountNftsLength, refreshCreatorAccountNfts, isCreatorAccount };
}

function useServerAccount() {
    const { serverAccountInfo, setServerAccountInfo } = useContext(AccountContext);

    return { serverAccountInfo, setServerAccountInfo };
}

function useLogout() {
    const { logout } = useContext(AccountContext);

    return logout;
}

function useUserPins() {
    const { pins, refreshPins } = useContext(AccountContext);

    return { pins, refreshPins };
}

export { AccountProvider, useCreatorAccountNfts, useServerAccount, useLogout, useUserPins };
