import styled from "@emotion/styled";

const StyledPin = styled.div(({ theme, knocked }) => ({
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: '15%',
    height: "90%",
    backgroundImage: 'url("/assets/match/pin.svg")',
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    opacity: knocked ? 0.05 : 1,
    color: theme.colors.primary.main
}));

const PinNumber = styled.span(({ theme }) => ({
    marginTop: 35
}));

const Pin = ({ number, knocked }) => {
    return (
        <StyledPin knocked={knocked}>
            <PinNumber>{number}</PinNumber>
        </StyledPin>
    );
};

export default Pin;
