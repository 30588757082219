import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { newMatch, getOngoingMatch } from "@api/Api";

import Match from "@components/match/Match";
import ConfirmationModal from "@components/ConfirmationModal";
import Loading from "@components/Loading";

import strings from '@resources/strings';
import routes from "@resources/Routes";

const Practice = () => {
    const [ currentMatchUuid, setCurrentMatchUuid ] = useState('');
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ loading, setLoading ] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        getCurrentMatch();
    }, []);

    const getCurrentMatch = () => {
        const success = (response) => {
            if (response.result.length > 0) {
                setCurrentMatchUuid(response.result[0].uuid);
            } else {
                createNewMatch();
            }
        };

        const error = (response) => {
            setShowErrorModal(true);
            setErrorMessage(response);
            setLoading(false);
        };

        getOngoingMatch(success, error);
    };

    const createNewMatch = () => {
        const success = (match) => {
            setCurrentMatchUuid(match.uuid);
        };

        const error = (response) => {
            setShowErrorModal(true);
            setErrorMessage(response);
            setLoading(false);
        };

        newMatch(success, error);
    };

    return (
        <div>
            <Match uuid={currentMatchUuid} setLoading={setLoading} />
            <ConfirmationModal
                title={strings.match + " " + strings.error}
                show={showErrorModal}
                onConfirm={() => {setShowErrorModal(false); setErrorMessage(""); navigate(routes.game); }}
                onClose={() => {setShowErrorModal(false); setErrorMessage(""); navigate(routes.game);}}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
            { loading ? <Loading isFullScreen /> : null }
        </div>
    );
};

export default Practice;
