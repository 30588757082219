import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import StyledLink from '@components/Link';
import routes from '@resources/Routes';
import strings from '@resources/strings';
import BurgerIcon from '@components/Burger';
import { useServerAccount, useLogout, getServerAccountInfo, useUserPins } from '@hooks/AccountContext';
import { updateChainAccount } from '@api/Api';
import { useEthereum } from '@hooks/EthereumContext';
import Button from '@components/Button';
import { useNavigate, useLocation } from 'react-router';
import { useSetNetwork } from '@hooks/GeneralContext';
import UALLoginButton from '@components/UALLoginButton';
import MetamaskLoginButton from '@components/MetamaskLoginButton';
import Dropdown from '@components/Dropdown';
import ConfirmationModal from "@components/ConfirmationModal";
import { useChain } from "@hooks/ChainContext";

const logo = '/assets/logo.png';

const StyledHeader = styled.div(({ theme }) => ({
    padding: `${theme.spacing.xs} ${theme.spacing.s}`,
    height: theme.size.headerHeight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 999,
    backgroundColor: theme.colors.common.background
}));

const Links = styled.div(({ theme, open }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing.s,
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100vw',
    backgroundColor: theme.colors.common.background,
    transform: open ? 'translateY(100%)' : 'translateY(-100%)',
    transition: theme.transition.default,
    padding: theme.spacing.xs,
    [theme.mediaQuery.tabletLandscapeUp]: {
        transform: 'none',
        flexDirection: 'row',
        position: 'relative',
        top: 'initial',
        left: 'initial',
        width: 'initial',
        padding: 0,
        backgroundColor: 'transparent'
    }
}));

const Logo = styled.a(({ theme }) => ({
    cursor: 'pointer',
    height: 45,
    zIndex: 999,
    '&:hover': {
        textDecoration: 'none'
    }
}));

const PinsContainer = styled.span(({ theme }) => ({
    fontWeight: "bold"
}));

const PinsNumber = styled.span(({ theme }) => ({
    padding: `${theme.spacing.xxxs} ${theme.spacing.xxs}`,
    borderRadius: theme.borderRadius.l,
    borderStyle: 'solid',
    borderWidth: 2
}));

function Header() {
    const { isAuthenticated, actor, login } = useChain();
    const [ loading, setLoading ] = useState(false);
    const [ isLogging, setIsLogging ] = useState(null);
    const [ isLinking, setIsLinking ] = useState(false);
    const [ burgerOpen, setBurgerOpen ] = useState(false);
    const [ dropdownControl, setDropdownControl ] = useState(false);
    const { serverAccountInfo, setServerAccountInfo } = useServerAccount();
    const { account, disconnect, setDisconnect, logoutMetamask, connectToMetamask } = useEthereum();
    const { connectedNetwork, activeUserAccounts, logoutNetwork, setNetwork, setActiveAccounts } = useSetNetwork();
    const [ successMessage, setSuccessMessage ] = useState("");
    const [ successTitle, setSuccessTitle ] = useState("");
    const [ showSuccessModal, setShowSuccessModal ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ willDisconnect, setWillDisconnect ] = useState(false);

    const logout = useLogout();
    const { pins } = useUserPins();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (serverAccountInfo) {
            setActiveAccounts(serverAccountInfo);
        }
    }, []);

    useEffect(() => {
        setBurgerOpen(false);
        setDropdownControl(false);
    }, [ dropdownControl ]);

    useEffect(() => {
        if ((isLogging === "wax" || !connectedNetwork) && isAuthenticated) {
            setNetwork("wax");
            setIsLogging(null);
        }

        if ((isLogging === "ethereum" || !connectedNetwork) && account) {
            setNetwork("ethereum");
            setIsLogging(null);
        }
    }, [ isAuthenticated, account, isLogging ]);

    useEffect(() => {
        const successWax = () => {
            setLoading(false);
            setServerAccountInfo({ wax_account: actor }, false);
            setSuccessTitle("Link Succeeded");
            setSuccessMessage("Your WAX account has been successfully linked");
            setShowSuccessModal(true);
        };

        const successEth = () => {
            setLoading(false);
            setIsLinking(false);
            setServerAccountInfo({ eth_account: account }, false);
            setSuccessTitle("Link Succeeded");
            setSuccessMessage("Your ETH account has been successfully linked");
            setShowSuccessModal(true);
        };

        const error = (response) => {
            setLoading(false);
            setIsLinking(false);
            setErrorMessage(response);
            setShowErrorModal(true);
            setWillDisconnect(true);
        };

        const serverInfo = serverAccountInfo || getServerAccountInfo();
        if (serverInfo && connectedNetwork) {
            if (isAuthenticated && serverInfo.wax_account && serverInfo.wax_account !== actor) {
                setDisconnect(true);
            }

            if (account && serverInfo.eth_account && serverInfo.eth_account !== account) {
                setDisconnect(true);
            }

            if (isLinking && !serverInfo.wax_account && isAuthenticated && !loading) {
                setLoading(true);
                updateChainAccount({ wax_account: actor }, successWax, error);
            }

            if (isLinking && !serverInfo.eth_account && account && !loading) {
                setLoading(true);
                updateChainAccount({ eth_account: account }, successEth, error);
            }
        }

    }, [ serverAccountInfo, isAuthenticated, actor, account, connectedNetwork, isLinking ]);

    useEffect(() => {
        if (disconnect) {
            setDisconnect(false);
            onLogout();
        }
    });

    const handleBurgerOpen = () => {
        setBurgerOpen(false);
    };

    const onLogout = async () => {
        logout();
        logoutMetamask();
        logoutNetwork();
        setServerAccountInfo(null);
        navigate(routes.root);
    };

    const changeNetwork = async (network) => {
        setIsLogging(network);
        changeAccount(network);
    };

    const changeAccount = async (network) => {
        if (network === "wax") {
            if (isAuthenticated && actor !== serverAccountInfo.wax_account) {
                await onLogout();
            } else {
                await login();
            }
        } else {
            if (account && account !== serverAccountInfo.eth_account) {
                await onLogout();
            } else {
                await connectToMetamask();
            }
        }
    };

    const shortEthAccount = (ethAddress) => ethAddress.slice(0, 5) + '...' + ethAddress.slice(-5, -1);

    const renderGameEntries = () => {
        return (
            <>
                <StyledLink header onClick={() => navigate(routes.game)}>{strings.home}</StyledLink>
                <StyledLink header onClick={() => navigate(routes.game + routes.matchHistory)}>{strings.matchHistory}</StyledLink>
                <StyledLink header onClick={() => navigate(routes.game + routes.inventory)}>{strings.inventory}</StyledLink>
            </>
        );
    };

    const getOptions = () => {
        const userAccounts = [];
        const waxAccount = activeUserAccounts.find(user => user.wax_account).wax_account;
        if (waxAccount) {
            userAccounts.push({
                label: waxAccount, onClick: () => changeNetwork("wax")
            });
        }

        if (!location.pathname.includes("/game")) {
            const ethAccount = activeUserAccounts.find(user => user.eth_account).eth_account;
            if (ethAccount) {
                userAccounts.push({
                    label: shortEthAccount(ethAccount), onClick: () => changeNetwork("ethereum")
                });
            }
        }

        return userAccounts;
    };

    const renderPins = () => {
        return (
            <PinsContainer>{strings.pins} <PinsNumber>{pins !== null ? pins : '?'}</PinsNumber></PinsContainer>
        );
    };

    return (
        <>
            <StyledHeader>
                <Logo href={routes.root} onClick={handleBurgerOpen}>
                    <img style={{ height: '100%', objectFit: 'contain' }} src={logo} />
                </Logo>
                <Links open={burgerOpen}>
                    { location.pathname.includes(routes.game) ? renderGameEntries() : null }
                    <div>{serverAccountInfo ? serverAccountInfo.accountName : null}</div>
                    <div>
                        {
                            serverAccountInfo
                                ? activeUserAccounts.length === 2 && !location.pathname.includes("/game")
                                    ? <Dropdown label={connectedNetwork === "wax" ? serverAccountInfo.wax_account : connectedNetwork === "ethereum" ? shortEthAccount(serverAccountInfo.eth_account) : null} options={getOptions()} />
                                    : <StyledLink >
                                        {connectedNetwork === "wax" && isAuthenticated ? actor : connectedNetwork === "ethereum" && account && !location.pathname.includes("/game") ? shortEthAccount(account) : null}
                                    </StyledLink>
                                : <StyledLink >
                                    {connectedNetwork === "wax" && isAuthenticated ? actor : connectedNetwork === "ethereum" && account && !location.pathname.includes("/game") ? shortEthAccount(account) : null}
                                </StyledLink>
                        }
                    </div>
                    {!location.pathname.includes("/game") && serverAccountInfo && !serverAccountInfo.eth_account ?
                        <MetamaskLoginButton label={strings.linkMetamask} setNetwork={setNetwork} connectToMetamaskWallet={connectToMetamask} setIsLinking={setIsLinking} linking /> : null
                    }
                    {!location.pathname.includes("/game") && serverAccountInfo && !serverAccountInfo.wax_account ?
                        <UALLoginButton label={strings.linkWAX} setNetwork={setNetwork} setIsLinking={setIsLinking} linking /> : null
                    }
                    { location.pathname.includes("/game") && serverAccountInfo ? renderPins() : null }
                    <Button outline onClick={onLogout}>{strings.logOut}</Button>
                </Links>
                <BurgerIcon burgerOpen={burgerOpen} setBurgerOpen={setBurgerOpen} />
            </StyledHeader>
            <ConfirmationModal
                show={showErrorModal}
                title={strings.error}
                onClose={() => {
                    setErrorMessage("");
                    setShowErrorModal(false);
                    if (willDisconnect) {
                        setDisconnect(true);
                    }
                }}
                onConfirm={() => {
                    setErrorMessage("");
                    setShowErrorModal(false);
                    if (willDisconnect) {
                        setDisconnect(true);
                    }
                }}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
            <ConfirmationModal
                title={successTitle}
                show={showSuccessModal}
                onConfirm={() => {
                    setShowSuccessModal(false);
                    setSuccessMessage("");
                    setSuccessTitle("");
                }}
                onClose={() => {
                    setShowSuccessModal(false);
                    setSuccessMessage("");
                    setSuccessTitle("");
                }}
                noCancel
            >
                {successMessage}
            </ConfirmationModal>
        </>

    );
}

export default Header;
