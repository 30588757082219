import styled from '@emotion/styled';

const StyledBurger = styled.button(({ theme, burgerOpen }) => ({
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '2rem',
    height: '2rem',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    zIndex: 10,

    '&:focus': {
        outline: 'none'
    },

    'div': {
        width: '2rem',
        height: '0.25rem',
        background: theme.colors.primary.main,
        borderRadius: 10,
        transition: `all 0.3s linear`,
        position: 'relative',
        transformOrigin: 1,

        '&:first-of-type': {
            transform: burgerOpen ? 'rotate(45deg)' : 'rotate(0)'
        },

        '&:nth-of-type(2)': {
            opacity: burgerOpen ? '0' : '1',
            transform: burgerOpen ? 'translateX(20px)' : 'translateX(0)'
        },

        '&:nth-of-type(3)': {
            transform: burgerOpen ? 'rotate(-45deg)' : 'rotate(0)'
        }
    },

    [theme.mediaQuery.tabletLandscapeUp]: {
        display: 'none'
    }
}));

function BurgerIcon({ burgerOpen, setBurgerOpen }) {

    return (
        <StyledBurger burgerOpen={burgerOpen} onClick={() => setBurgerOpen(!burgerOpen)}>
            <div />
            <div />
            <div />
        </StyledBurger>
    );
}

export default BurgerIcon;
