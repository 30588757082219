import Frame from '@components/match/Frame';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import strings from '@resources/strings';

const ScoreContainer = styled.div(() => ({
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: 'auto',
    width: '100%'
}));

const Score = ({ frames = [], currentThrow = 0, totalScore }) => {
    const [ renderedFrames, setRenderedFrames ] = useState([]);

    useEffect(() => {
        let renderedFrames = [];

        for (let i = 0; i < 10; i++) {
            let props = {
                key: i,
                round: i+1
            };
            if (i < frames.length && frames[i]) {
                props.score = i < frames.length - 1 || (currentThrow === frames[i].throws.length - 1 || frames[i].throws[currentThrow].total_pins === 10) ? frames[i].score : null;
                props.balls = getThrows(i);
            }
            renderedFrames.push(<Frame {...props} />);
        }
        renderedFrames.push(<Frame key={11} round={strings.total} score={totalScore} />);

        setRenderedFrames(renderedFrames);
    }, [ frames, currentThrow ]);

    const getThrows = (index) => {
        const throws = frames[index].throws;
        let formatedThrows = [];
        for (let ballThrow = 0; ballThrow < throws.length; ballThrow++) {
            if (index === frames.length - 1 && ballThrow > currentThrow) {
                formatedThrows.push(null);
            } else if (throws[ballThrow].spare) {
                formatedThrows.push('/');
            } else if (throws[ballThrow].strike) {
                formatedThrows.push('X');
            } else if (index === 9 && ballThrow === 2 && !throws[ballThrow - 2]?.strike && !throws[ballThrow - 1]?.spare) {
                formatedThrows.push(null);
            } else if (index === 9 && throws[ballThrow - 1]?.strike) {
                formatedThrows.push(throws[ballThrow].total_pins);
            } else if (throws[ballThrow - 1]?.strike) {
                formatedThrows.push(null);
            } else {
                formatedThrows.push(throws[ballThrow].total_pins);
            }
        }
        return formatedThrows;
    };

    return (
        <ScoreContainer>
            { renderedFrames }
        </ScoreContainer>
    );
};

export default Score;
