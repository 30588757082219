import { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { selectGameCharacter, assignStatPoints,  consumeAllStamina } from "@api/Api";

import Button from "@components/Button";
import Modal from "@components/Modal";
import ConfirmationModal from '@components/ConfirmationModal';
import Badge from "@components/Badge";
import Ascension from "@components/Ascension";
import Tabs from "@components/Tabs";
import CharacterEquipment from '@components/CharacterEquipment';
import Loading from "@components/Loading";
import { useCharacterLevelTiers } from "@hooks/CharacterSupportContext";

import strings from "@resources/strings";
import routes from '@resources/Routes';

const pendingImage = '/assets/pending.png';

const CardContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    margin: theme.spacing.xxs
}));

const ImageContainer = styled.img(({ minified = false, theme }) => ({
    width: '100%',
    maxWidth: 'unset',
    maxHeight: '100%',
    borderRadius: theme.borderRadius.xl,
    margin: 0,
    [ theme.mediaQuery.largeMobileUp ]: {
        borderRadius: theme.borderRadius.xl
    },
    [ theme.mediaQuery.largeMobileOnly ]: {
        borderRadius: '10%'
    }
}));

const LayerContainer = styled.div(({ theme }) => ({
    margin: `${theme.spacing.xs} 0px`,
    position: 'relative',
    width: '80vw',
    maxWidth: theme.size.characterLayerSize,
    height: 'calc(80vw + 150px)',
    maxHeight: 682,
    [ theme.mediaQuery.largeMobileUp ]: {
        height: 'calc(80vw + 170px)'
    },
    [ theme.mediaQuery.tabletUp ]: {
        margin: theme.spacing.xxxs,
        height: '414px',
        width: theme.size.characterLayerSizeS,
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    }
}));

const LayerWidthContainer = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '80vw',
    maxWidth: theme.size.characterLayerSize,
    [ theme.mediaQuery.tabletUp ]: {
        width: theme.size.characterLayerSizeS
    }
}));

const BorderContainer = styled.div(({ pieceIndex, theme }) => ({
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: pieceIndex,
    [ theme.mediaQuery.tabletUp ]: {
        top: -12
    }
}));

const BorderImageContainer = styled.img(({ theme }) => ({
    width: '100%'
}));

const ButtonContainer = styled.div(({ theme }) => ({
    margin: theme.spacing.xxs
}));

const NameContainer = styled.h5(({ theme }) => ({
    ...theme.typography.h5,
    fontWeight: 'bold',
    margin: theme.spacing.xs,
    color: theme.colors.common.text,
    maxWidth: theme.size.characterLayerSizeS,
    whiteSpace: 'nowrap',
    overflowY: 'clip',
    textOverflow: 'ellipsis'
}));

const XPContainer = styled.h6(({ theme }) => ({
    ...theme.typography.h6,
    fontWeight: 'bold',
    margin: 'unset',
    paddingRight: theme.spacing.xxxs,
    color: theme.colors.common.text
}));

const StatsContentContainer = styled.div(({ pieceIndex, theme }) => ({
    borderRadius: theme.borderRadius.m,
    height: 93,
    width: '70%',
    position: 'absolute',
    top: '83vw',
    left: '21vw',
    zIndex: pieceIndex,
    [ '@media (min-width: 640px)' ]: {
        top: 535,
        left: 135
    },
    [ theme.mediaQuery.tabletUp ]: {
        top: 310,
        left: 80
    }
}));

const StatsNameContainer = styled.h6(({ theme }) => ({
    ...theme.typography.h6,
    width: 100,
    textAlign: 'right',
    fontWeight: 'bold',
    margin: 'unset',
    paddingRight: theme.spacing.xxs
}));

const StatsContainer = styled.div(({ stat, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    margin: theme.spacing.xxs,
    color: theme.colors.characterStats[stat]
}));

const StatsValueContainer = styled.span(({ stat, theme }) => ({
    width: 90,
    margin: `0px ${theme.spacing.xxs}`,
    padding: `${theme.spacing.xxxs} ${theme.spacing.xs}`,
    color: theme.colors.characterStats[stat],
    border: `2px solid`,
    borderColor: theme.colors.characterStats[stat],
    borderRadius: theme.borderRadius.xxl
}));

const StatsValueControlContainer = styled.span(({ styles, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    height: 10,
    padding: `${theme.spacing.xxs} ${theme.spacing.xxs}`,
    border: '2px solid',
    borderRadius: theme.borderRadius.xxl,
    cursor: 'pointer',
    userSelect: 'none',
    ...styles
}));

const StatsBarContainer = styled.div(({ stat, theme }) => ({
    backgroundColor: '#00000044',
    width: '93%',
    padding: theme.spacing.xxxxs,
    margin: theme.spacing.xxxs,
    borderRadius: theme.borderRadius.xl,
    border: `2px solid ${theme.colors.characterStats[stat]}`,
    boxShadow: theme.shadow.dropShadow
}));

const StatsBarFillContainer = styled.div(({ stat, fill, theme }) => ({
    width: `${fill}%`,
    height: 10,
    borderRadius: theme.borderRadius.xl,
    backgroundColor: theme.colors.characterStats[stat],
    boxShadow: theme.shadow.dropShadow
}));

const StaminaContainer = styled.h5(({ styles, theme }) => ({
    ...theme.typography.h5,
    fontWeight: 'bold',
    margin: theme.spacing.xs,
    color: theme.colors.secondary.main,
    ...styles
}));

const StaminaValueContainer = styled.span(({ theme }) => ({
    margin: theme.spacing.xxs,
    padding: `${theme.spacing.xxxs} ${theme.spacing.xs}`,
    color: theme.colors.secondary.main,
    borderRadius: theme.borderRadius.xxl,
    border: `2px solid ${theme.colors.secondary.main}`
}));

const CardDetailsModalContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center'
}));

const CardDetailsContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    margin: theme.spacing.xs
}));

const CardDetailsImageContainer = styled.img(({ theme, styles }) => ({
    width: theme.size.characterLayerSizeS,
    height: theme.size.characterLayerSizeS,
    borderRadius: theme.borderRadius.xl,
    ...styles
}));

const EquipmentsContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'start',
    textAlign: 'center',
    justifyContent: 'center',
    margin: theme.spacing.xs
}));

const LevelContainer = styled.div(({ theme }) => ({
    fontSize: 20
}));

const ExperienceContainer = styled.div(({ theme }) => ({
    fontSize: 15,
    fontWeight: "normal"
}));

function CharacterCard({ character = {}, reload = () => {}, minified = false, management = false, noStamina = false, noStats = false }) {
    const stats = [ 'stamina', 'power', 'control', 'luck', 'floor', 'ceiling' ];
    const statsInitialValues = { stamina: 0, power: 0, control: 0, luck: 0, floor: 0, ceiling: 0 };
    const floorCeilingGap = 80;
    const tabs = [{ name: strings.details, uuid: 0 }, { name: strings.equipments, uuid: 1 }];

    const [ nextTierInfo, setNextTierInfo ] = useState(null);
    const [ assignedPoints, setAssignedPoints ] = useState(statsInitialValues);
    const [ totalAssignedPoints, setTotalAssignedPoints ] = useState(0);
    const [ showDetails, setShowDetails ] = useState(false);
    const [ showQuickPlayConfirmation, setShowQuickPlayConfirmation ] = useState(false);
    const [ showQuickPlayResult, setShowQuickPlayResult ] = useState(false);
    const [ selectedTab, setSelectedTab ] = useState(tabs[0]);
    const [ quickPlayTotalXp, setQuickPlayTotalXp ] = useState(0);
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ loading, setLoading ] = useState(false);

    const navigate = useNavigate();
    const theme = useTheme();
    const tiers = useCharacterLevelTiers();

    useEffect(() => {
        if (tiers && character) {
            setNextTierInfo(tiers.filter(t => t.tier === character.next_tier)?.[0]);
        }
    }, [ tiers, character ]);

    const selectCharacter = () => {
        const success = () => {
            reload();
        };

        const error = (response) => {
            setShowErrorModal(true);
            setErrorMessage(response);
        };

        selectGameCharacter(character.uuid, success, error);
    };

    const quickPlay = () => {
        const success = (response) => {
            setQuickPlayTotalXp(response.reduce((sum, game) => sum + parseInt(game.game_data.experience), 0));
            setShowQuickPlayConfirmation(false);
            setLoading(false);
            setShowQuickPlayResult(true);
        };

        const error = (response) => {
            setShowErrorModal(true);
            setErrorMessage(response);
            setLoading(false);
        };

        setLoading(true);
        const allStaminaPayload = { game_character_uuid: character.uuid };
        consumeAllStamina(allStaminaPayload, success, error);
    };

    const getCharacterExperiencePercentage = () => {
        if (character?.stats?.experience) {
            const { current, min_value, max_value } = character.stats.experience;
            return Math.min((current - min_value)/(max_value - min_value) * 100, 100);
        } else {
            return 0;
        }
    };

    const getCharacterExperienceLabel = () => {
        if (character?.stats?.experience) {
            const { current, min_value, max_value } = character.stats.experience;
            return `${current - min_value} / ${max_value - min_value}`;
        } else {
            return '? / ?';
        }
    };

    const applySkillPoints = () => {
        let updatedStats = {};

        for (let stat of stats) {
            if (assignedPoints[stat] > 0) {
                updatedStats[stat] = (character.stats[stat].max_upgrade ? character.stats[stat].max_value : character.stats[stat].current) + assignedPoints[stat];
            }
        }

        const success = () => {
            reload();
            setTotalAssignedPoints(0);
            setAssignedPoints(statsInitialValues);
            setLoading(false);
        };

        const error = (response) => {
            setLoading(false);
            setShowErrorModal(true);
            setErrorMessage(response);
        };

        setLoading(true);
        assignStatPoints(character.uuid, updatedStats, success, error);
    };

    const updateStatValue = (stat, value) => {
        setAssignedPoints((assignedPoints) => ({ ...assignedPoints, [stat]: assignedPoints[stat] + value }));
        setTotalAssignedPoints((totalAssignedPoints) => totalAssignedPoints + value);
    };

    const decreasePoints = (stat) => {
        // Specific check for Floor over Ceiling points assignment
        if (stat === 'ceiling') {
            if (character.stats[stat].current + assignedPoints[stat] > character.stats['floor'].current + assignedPoints['floor'] + floorCeilingGap) {
                updateStatValue(stat, -1);
            }
        } else if (assignedPoints[stat] > 0) {
            updateStatValue(stat, -1);
        }
    };

    const increasePoints = (stat) => {
        if (totalAssignedPoints < character.unassigned_points) {
            // In case of the Stamina stat, as it behaves differently with the max_upgrade field
            if (character.stats[stat].max_upgrade) {
                if (character.stats[stat].max_value + assignedPoints[stat] < character.stats[stat].max_upgrade) {
                    updateStatValue(stat, 1);
                }
            // Specific check for Floor over Ceiling points assignment
            } else if (stat === 'floor' && character.stats[stat].current + assignedPoints[stat] < character.stats[stat].max_value + assignedPoints['ceiling']) {
                updateStatValue(stat, 1);
            // For all the other stats
            } else if (character.stats[stat].current + assignedPoints[stat] < character.stats[stat].max_value) {
                updateStatValue(stat, 1);
            }
        }
    };

    const onTabChange = (uuid) => {
        setSelectedTab(tabs.find((tab) => tab.uuid === uuid));
    };

    const renderCharacterDetailsStat = (stat, upgradeable) => {
        return (
            <StatsContainer key={stat} stat={strings[stat]}>
                <StatsNameContainer>{strings[stat]}</StatsNameContainer>
                { upgradeable ?
                    <StatsValueControlContainer onClick={() => decreasePoints(stat)}>{'<'}</StatsValueControlContainer>
                    :
                    null
                }
                <StatsValueContainer>{character?.stats?.[stat]?.current != null ? Math.max((character?.stats[stat]?.max_upgrade ? character.stats[stat].max_value : character.stats[stat].current) + assignedPoints[stat], 0) + ' / ' + (character?.stats[stat]?.max_upgrade ?? character.stats[stat].max_value) : '?'}</StatsValueContainer>
                { upgradeable ?
                    <StatsValueControlContainer onClick={() => increasePoints(stat)}>{'>'}</StatsValueControlContainer>
                    :
                    null
                }
                <StatsValueContainer style={{ width: 32 }}>+ {character?.stats?.[stat]?.equipment ?? '0'}</StatsValueContainer>
            </StatsContainer>
        );
    };

    const renderCharacterDetailsStats = () => {
        const upgradeable = character && character.unassigned_points != null && character.unassigned_points > 0;

        return (
            stats.map(stat => renderCharacterDetailsStat(stat, upgradeable))
        );
    };

    const renderCharacterDetails = () => {
        return (
            <>
                { character?.details ?
                    <div style={{ margin: theme.spacing.xs }}>
                        <Badge styles={{ position: "relative", top: 0, marginLeft: 'auto', borderRadius: `0 ${theme.borderRadius.l}` }}>#{character?.nft_metadata?.template_mint}</Badge>
                        <CardDetailsImageContainer styles={{ position: "relative", marginTop: `-${theme.size.mintHeight}`, border: `7px solid ${theme.colors.common.border}` }} alt={character.details.name} src={character?.details?.final_art ? character.details.final_art : pendingImage} />
                        <LevelContainer>{strings.level} {character.level}{character.tier !== "None" ? ` - ${character.tier}` : null}</LevelContainer>
                        <LayerWidthContainer style={{ marginRight: 'auto', marginLeft: 'auto', width: 'auto' }}>
                            <XPContainer>{strings.xp}</XPContainer>
                            <StatsBarContainer stat={strings.xp}><StatsBarFillContainer stat={strings.xp} fill={getCharacterExperiencePercentage()} /></StatsBarContainer>
                        </LayerWidthContainer>
                        <ExperienceContainer>{getCharacterExperienceLabel()}</ExperienceContainer>
                        { nextTierInfo?.min_level === character.level ? <Ascension character={character} reload={reload} tiers={tiers} nextTierInfo={nextTierInfo} /> : null }
                    </div>
                    :
                    null
                }
                <CardDetailsContainer>
                    { character?.unassigned_points > 0 ?
                        <StaminaContainer styles={{ color: theme.colors.common.text }}>{strings.skillPoints}<StatsValueContainer>{character?.unassigned_points != null ? character.unassigned_points - totalAssignedPoints : '?'}</StatsValueContainer></StaminaContainer>
                        :
                        null
                    }
                    {renderCharacterDetailsStats()}
                    { character?.unassigned_points > 0 ?
                        <ButtonContainer><Button style={{ padding: `0px ${theme.spacing.s}` }} onClick={applySkillPoints} disabled={totalAssignedPoints === 0} outline>{strings.apply}</Button></ButtonContainer>
                        :
                        null
                    }
                </CardDetailsContainer>
            </>
        );
    };

    const renderCharacterEquipments = () => {
        return (
            <EquipmentsContainer>
                { character?.equipments ?
                    character.equipments.map(equipment => <CharacterEquipment key={equipment?.value?.uuid ?? equipment.order} equipment={equipment} characterUuid={character?.uuid} reload={reload} />)
                    :
                    null
                }
            </EquipmentsContainer>
        );
    };

    return (
        <>
            <CardContainer>
                <NameContainer>{character?.details?.name ? character.details.name : '?'}</NameContainer>
                { character?.details ?
                    <LayerContainer>
                        { character && character.stats && !noStats ?
                            <StatsContentContainer pieceIndex={20}>
                                <StatsBarContainer stat={strings.power}><StatsBarFillContainer stat={strings.power} fill={character.stats.power && character.stats.power.current != null ? Math.min(character.stats.power.current/character.stats.power.max_value * 100, 100) : 0} /></StatsBarContainer>
                                <StatsBarContainer stat={strings.control}><StatsBarFillContainer stat={strings.control} fill={character.stats.control && character.stats.control.current != null ? Math.min(character.stats.control.current/character.stats.control.max_value * 100, 100) : 0} /></StatsBarContainer>
                                <StatsBarContainer stat={strings.luck}><StatsBarFillContainer stat={strings.luck} fill={character.stats.luck && character.stats.luck.current != null ? Math.min(character.stats.luck.current/character.stats.luck.max_value * 100, 100) : 0} /></StatsBarContainer>
                                <StatsBarContainer stat={strings.ceiling}><StatsBarFillContainer stat={strings.ceiling} fill={character.stats.ceiling && character.stats.ceiling.current != null ? Math.min(character.stats.ceiling.current/character.stats.ceiling.max_value * 100, 100) : 0} /></StatsBarContainer>
                            </StatsContentContainer>
                            :
                            null
                        }
                        <BorderContainer pieceIndex={10}>
                            <BorderImageContainer src='/assets/border_gray.png' />
                        </BorderContainer>
                        <BorderContainer pieceIndex={12}>
                            <Badge styles={{ marginLeft: 'auto', borderRadius: `0 ${theme.spacing.xs}` }}>#{character?.nft_metadata?.template_mint}</Badge>
                        </BorderContainer>
                        <BorderContainer>
                            <ImageContainer minified={minified} alt={character.details.name} src={character?.details?.final_art ? character.details.final_art : pendingImage} />
                        </BorderContainer>
                    </LayerContainer>
                    :
                    null
                }
                <LevelContainer>{strings.level} {character.level}{character.tier !== "None" ? ` - ${character.tier}` : null}</LevelContainer>
                <LayerWidthContainer>
                    <XPContainer>{strings.xp}</XPContainer>
                    <StatsBarContainer stat={strings.xp}><StatsBarFillContainer stat={strings.xp} fill={getCharacterExperiencePercentage()} /></StatsBarContainer>
                </LayerWidthContainer>
                { noStamina ?
                    null
                    :
                    <StaminaContainer styles={{ marginTop: theme.spacing.xxs }}>{strings.stamina} <StaminaValueContainer>{character?.stats?.stamina?.current != null ? Math.max(character.stats.stamina.current, 0) + ' / ' + (character.stats.stamina.max_value + character.stats.stamina.equipment) : "?"}</StaminaValueContainer></StaminaContainer>
                }
                { minified ?
                    null
                    :
                    management ?
                        <>
                            <ButtonContainer><Button style={{ width: theme.size.characterLayerSizeS }} onClick={() => setShowDetails(true)}>{strings.viewDetails}</Button></ButtonContainer>
                            <ButtonContainer><Button style={{ width: theme.size.characterLayerSizeS }} onClick={selectCharacter} disabled={character?.selected}>{character?.selected ? strings.selected : strings.selectCharacter}</Button></ButtonContainer>
                            <ButtonContainer><Button style={{ width: theme.size.characterLayerSizeS }} onClick={() => setShowQuickPlayConfirmation(true)} disabled={character?.stats?.stamina?.current <= character?.stats?.stamina?.min_value}>{strings.quickPlay}</Button></ButtonContainer>
                        </>
                        :
                        <ButtonContainer><Button style={{ width: theme.size.characterLayerSizeS }} onClick={() => navigate(routes.game)}>{strings.manageCharacters}</Button></ButtonContainer>
                }
            </CardContainer>
            <Modal show={showDetails} style={{ width: '80vw' }} onClose={() => setShowDetails(false)}>
                <NameContainer style={{ margin: "auto", marginBottom: theme.spacing.xxs }}>{character?.details?.name ? character.details.name : "?"}</NameContainer>
                <Tabs tabs={tabs} onChange={onTabChange} selectedTab={selectedTab.uuid} secondary />
                <CardDetailsModalContainer>
                    { selectedTab.uuid === 0 ? renderCharacterDetails() : renderCharacterEquipments() }
                </CardDetailsModalContainer>
                <CardDetailsModalContainer>
                    <ButtonContainer><Button style={{ padding: `0px ${theme.spacing.s}` }} onClick={() => character?.selected ? navigate(routes.game + routes.matchHistory) : selectCharacter()}>{character?.selected ? strings.matchHistory : strings.selectCharacter}</Button></ButtonContainer>
                    <ButtonContainer><Button style={{ padding: `0px ${theme.spacing.s}` }} onClick={() => setShowDetails(false)} outline>{strings.close}</Button></ButtonContainer>
                </CardDetailsModalContainer>
            </Modal>
            <ConfirmationModal
                title={strings.warning}
                show={showQuickPlayConfirmation}
                onConfirm={quickPlay}
                onClose={() => setShowQuickPlayConfirmation(false)}
                confirmationButtonLabel={strings.quickPlay}
            >
                {strings.quickPlayWarning}
            </ConfirmationModal>
            <ConfirmationModal
                title={strings.quickPlay}
                show={showQuickPlayResult}
                onConfirm={() => {reload(); setShowQuickPlayResult(false);}}
                onClose={() => {reload(); setShowQuickPlayResult(false);}}
                confirmationButtonLabel={strings.close}
                noCancel
            >
                {strings.quickPlayResult} {strings.quickPlayTotalXp.replace('{0}', quickPlayTotalXp)}
            </ConfirmationModal>
            <ConfirmationModal
                title={strings.error}
                show={showErrorModal}
                onConfirm={() => {setShowErrorModal(false); setErrorMessage("");}}
                onClose={() => {setShowErrorModal(false); setErrorMessage("");}}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
            { loading ? <Loading isFullScreen /> : null }
        </>
    );
}

export default CharacterCard;
