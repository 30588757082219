import styled from '@emotion/styled';

const CustomInputLabel = styled.label(({ theme, style }) => ({
    ...style,
    ...theme.typography.pBold,
    color: theme.colors.common.text,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginBottom: theme.spacing.xs,
    input: {
        textAlign: 'left'
    }
}));

const CustomInput = styled.input(({ theme, inputStyle }) => ({
    ...theme.typography.p,
    color: theme.colors.common.text,
    border: `1px solid ${theme.colors.primary.main}`,
    borderRadius: theme.borderRadius.xxl,
    marginTop: theme.spacing.xxxs,
    padding: `${theme.spacing.xxxs} ${theme.spacing.xs}`,
    outline: 'none',
    backgroundColor: theme.colors.common.background,
    width: 'calc(100% - 34px)',
    '&:placeholder': {
        color: theme.colors.placeholder.main,
        opacity: 0.5
    },
    '&:focus, &:active': {
        borderColor: theme.colors.primary.dark
    },
    ...inputStyle
}));

const InfoText = styled.div(({ theme }) => ({
    ...theme.typography.pTiny,
    color: theme.colors.common.text,
    marginTop: theme.spacing.xxxs
}));

function Input({ label, placeholder, type, infoText, style, inputStyle, ...otherProps }) {
    return (
        <CustomInputLabel style={style}>
            {label}
            <CustomInput type={type} placeholder={placeholder} inputStyle={inputStyle} {...otherProps} />
            {infoText ? <InfoText>{infoText}</InfoText> : null}
        </CustomInputLabel>
    );
}

export default Input;
