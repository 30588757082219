export const pieces = {
    "background": [
        {
            "uuid": "27dee4a5-1d77-4601-a8e3-166379785206",
            "label": "wood",
            "image": "/assets/background-0-wood.png"
        },
        {
            "uuid": "81a40843-799a-486f-a4cb-45c442fc210f",
            "label": "blue_alley",
            "image": "/assets/background-0-blue_alley.png"
        },
        {
            "uuid": "a4cc3113-0053-4bb7-aeb1-0fc1421943b0",
            "label": "galaxy",
            "image": "/assets/background-0-galaxy.png"
        },
        {
            "uuid": "8a121d8d-db32-469d-a750-5f750cea6741",
            "label": "full_alley",
            "image": "/assets/background-0-full_alley.png"
        },
        {
            "uuid": "1840beea-5c52-4de9-ba7a-d400b9e8c394",
            "label": "neon_alley",
            "image": "/assets/background-0-neon_alley.png"
        },
        {
            "uuid": "3244c559-a16e-4d12-8efc-7b394c91b3f4",
            "label": "carousel",
            "image": "/assets/background-0-carousel.png"
        },
        {
            "uuid": "138780c3-d40f-4c13-a5dd-b972995e4b19",
            "label": "colorful_alley",
            "image": "/assets/background-0-colorful_alley.png"
        },
        {
            "uuid": "63e3a67f-2018-4bbd-8c30-8ec1be295843",
            "label": "strike_blue",
            "image": "/assets/background-0-strike_blue.png"
        },
        {
            "uuid": "e0c129b2-e6c0-4a0e-b094-35107f94d664",
            "label": "strike_pink",
            "image": "/assets/background-0-strike_pink.png"
        },
        {
            "uuid": "15ef5f20-4fe6-4684-baab-727d7253b5f0",
            "label": "retro",
            "image": "/assets/background-0-retro.png"
        },
        {
            "uuid": "c68649f7-779c-4cb7-832b-156826ae0633",
            "label": "theer_yellow",
            "image": "/assets/background-0-theer_yellow.png"
        },
        {
            "uuid": "13952d68-214c-445b-b945-bfe884d6226a",
            "label": "theer_og",
            "image": "/assets/background-0-theer_og.png"
        },
        {
            "uuid": "b86303f4-e38c-4851-bb43-6374f5b8d0f8",
            "label": "theer_pink",
            "image": "/assets/background-0-theer_pink.png"
        },
        {
            "uuid": "badc5549-de59-4457-bb98-bc851b658a25",
            "label": "yellow",
            "image": "/assets/background-0-yellow.png"
        },
        {
            "uuid": "304aab7b-99d6-4938-8f1f-c5bbe3fed3b8",
            "label": "vintage",
            "image": "/assets/background-0-vintage.png"
        },
        {
            "uuid": "89c67185-d6b2-49f5-a1fc-b655d9a1bd32",
            "label": "bowling_balls_2",
            "image": "/assets/background-0-bowling_balls_2.png"
        },
        {
            "uuid": "0895cab4-0615-443b-ab05-2958ddb5fb3d",
            "label": "acid",
            "image": "/assets/background-0-acid.png"
        },
        {
            "uuid": "e4713b02-8ad0-455e-93ca-5dfbd5d5a1c6",
            "label": "bowling_balls_1",
            "image": "/assets/background-0-bowling_balls_1.png"
        },
        {
            "uuid": "ca14d3ba-ad4c-4ead-a90c-10cea5b0a2d5",
            "label": "blue",
            "image": "/assets/background-0-blue.png"
        },
        {
            "uuid": "a573c71a-e132-4217-88a1-1da75ff0466d",
            "label": "bowling_alley",
            "image": "/assets/background-0-bowling_alley.png"
        },
        {
            "uuid": "ca7bd6cb-32e8-4f2b-ac01-186f85514e78",
            "label": "bowling_pins",
            "image": "/assets/background-0-bowling_pins.png"
        },
        {
            "uuid": "c7fb49aa-03c1-4a05-8c5c-dba760cf2c5c",
            "label": "crypto_flags",
            "image": "/assets/background-0-crypto_flags.png"
        },
        {
            "uuid": "9f90d8b5-844c-44aa-a74f-c345f5ff8697",
            "label": "bowling_pins_rainbow",
            "image": "/assets/background-0-bowling_pins_rainbow.png"
        },
        {
            "uuid": "4583a8c8-b66b-44bb-bc09-dc85059b505c",
            "label": "crypto_flags_3D",
            "image": "/assets/background-0-crypto_flags_3D.png"
        },
        {
            "uuid": "c8e071e4-c8ba-4d7f-809e-49f5e4d04b44",
            "label": "crypto_flag",
            "image": "/assets/background-0-crypto_flag.png"
        },
        {
            "uuid": "4c6bb813-6d00-468c-aaf7-e3246ac47008",
            "label": "dizzy_green",
            "image": "/assets/background-0-dizzy_green.png"
        },
        {
            "uuid": "1222de5f-443d-4e4a-946c-25942334b27d",
            "label": "cubes",
            "image": "/assets/background-0-cubes.png"
        },
        {
            "uuid": "789d1cc0-5c89-48dd-9258-a5fd9ddacf4d",
            "label": "flames",
            "image": "/assets/background-0-flames.png"
        },
        {
            "uuid": "d24895d3-748b-4dc7-b487-436c76d67b64",
            "label": "dizzy_brown",
            "image": "/assets/background-0-dizzy_brown.png"
        },
        {
            "uuid": "1de0967a-5ccf-4d22-961a-193cfd7a97b9",
            "label": "flowers",
            "image": "/assets/background-0-flowers.png"
        },
        {
            "uuid": "adf8c404-1ecf-46bf-873f-76231bab32f2",
            "label": "green",
            "image": "/assets/background-0-green.png"
        },
        {
            "uuid": "5965a628-d48c-4667-b66a-bef1c65cd18a",
            "label": "pink",
            "image": "/assets/background-0-pink.png"
        },
        {
            "uuid": "23acef62-f767-4a67-97f6-8554afa1e071",
            "label": "rainbow",
            "image": "/assets/background-0-rainbow.png"
        },
        {
            "uuid": "4b97e2cc-b506-4a78-aa99-425a10146085",
            "label": "orange",
            "image": "/assets/background-0-orange.png"
        },
        {
            "uuid": "e29e1bb8-d0f2-4faf-91bd-fe5a36c73a86",
            "label": "leaf",
            "image": "/assets/background-0-leaf.png"
        },
        {
            "uuid": "e70498ce-518f-44db-b360-1be18962087b",
            "label": "space",
            "image": "/assets/background-0-space.png"
        },
        {
            "uuid": "828d8867-561d-4c58-97df-6b7dae25e5a6",
            "label": "red",
            "image": "/assets/background-0-red.png"
        }
    ],
    "head": [
        {
            "uuid": "aa2b59fd-d4bc-4f75-bccd-081a91d87836",
            "label": "human_b",
            "image": "/assets/head-1-human_b.png"
        },
        {
            "uuid": "6bd9a4ce-8151-4db6-a9cd-88bd19b27659",
            "label": "human_c",
            "image": "/assets/head-1-human_c.png"
        },
        {
            "uuid": "5f39fac5-08c1-4069-918c-336cc580b8d5",
            "label": "human_d",
            "image": "/assets/head-1-human_d.png"
        },
        {
            "uuid": "57163077-8cdd-487f-a81c-1ac173c95471",
            "label": "human_a",
            "image": "/assets/head-1-human_a.png"
        },
        {
            "uuid": "8d190772-c203-4371-953b-3e0d77c7fbf7",
            "label": "alien",
            "image": "/assets/head-1-alien.png"
        },
        {
            "uuid": "c1170862-e7a1-4bf1-ad78-02d809dc1aa2",
            "label": "demon",
            "image": "/assets/head-1-demon.png"
        }
    ],
    "eyes": [
        {
            "uuid": "4ff82046-5754-40ec-9c04-fcafd3dc6bae",
            "label": "eye_roll",
            "image": "/assets/eyes-2-eye_roll.png"
        },
        {
            "uuid": "38f9145d-2cbe-44cd-96b5-12fe2777b5b6",
            "label": "blackout",
            "image": "/assets/eyes-2-blackout.png"
        },
        {
            "uuid": "b9ffe4aa-4a34-4d04-a221-33269934fa9b",
            "label": "alien",
            "image": "/assets/eyes-2-alien.png"
        },
        {
            "uuid": "1ca0b2ed-5301-4ec6-90d6-b92bcf5dbcd4",
            "label": "angry",
            "image": "/assets/eyes-2-angry.png"
        },
        {
            "uuid": "e259c2ea-fcef-44f1-a484-1a3c026a6dce",
            "label": "bloodshot",
            "image": "/assets/eyes-2-bloodshot.png"
        },
        {
            "uuid": "5b82c34f-25d7-43b6-8350-0b938d12c754",
            "label": "demon",
            "image": "/assets/eyes-2-demon.png"
        },
        {
            "uuid": "ea0123bb-8aef-45ea-863d-e6b56409add5",
            "label": "golden",
            "image": "/assets/eyes-2-golden.png"
        },
        {
            "uuid": "21dfcb95-0785-466c-bd5e-7ec3c9117e11",
            "label": "flames",
            "image": "/assets/eyes-2-flames.png"
        },
        {
            "uuid": "46b9c4d8-c3bb-4c74-8303-c1991fa182d9",
            "label": "normal",
            "image": "/assets/eyes-2-normal.png"
        },
        {
            "uuid": "2d7102ea-c85b-4a8e-99e3-ba2a8089e06d",
            "label": "sunglasses",
            "image": "/assets/eyes-2-sunglasses.png"
        },
        {
            "uuid": "14eda7b9-124a-436b-b6ae-6eef37946938",
            "label": "wink",
            "image": "/assets/eyes-2-wink.png"
        },
        {
            "uuid": "bc02e953-a359-489a-8e00-f832776f24a8",
            "label": "stoner",
            "image": "/assets/eyes-2-stoner.png"
        },
        {
            "uuid": "14d7123a-beb3-42bb-8f79-e60d982691cc",
            "label": "snake",
            "image": "/assets/eyes-2-snake.png"
        },
        {
            "uuid": "9db334a9-c675-4308-a503-0022303883af",
            "label": "squint",
            "image": "/assets/eyes-2-squint.png"
        }
    ],
    "mouth": [
        {
            "uuid": "a4646722-7f1c-48fc-9e2d-c25a68ce8119",
            "label": "drool",
            "image": "/assets/mouth-3-drool.png"
        },
        {
            "uuid": "16d904c9-9383-490a-bed0-faedabfd15ee",
            "label": "bling_teeth",
            "image": "/assets/mouth-3-bling_teeth.png"
        },
        {
            "uuid": "41920a77-7a4a-423b-baa7-c3e720f2f559",
            "label": "tooth_pick",
            "image": "/assets/mouth-3-tooth_pick.png"
        },
        {
            "uuid": "ee83f311-8d13-427c-a007-79f222625979",
            "label": "regular",
            "image": "/assets/mouth-3-regular.png"
        },
        {
            "uuid": "87b7141d-c024-413b-ad46-221241bff04e",
            "label": "tongue",
            "image": "/assets/mouth-3-tongue.png"
        },
        {
            "uuid": "f3b5d1da-f218-47f9-b442-e9b2fbaa9861",
            "label": "smile",
            "image": "/assets/mouth-3-smile.png"
        },
        {
            "uuid": "54ffaa1a-5c26-4a43-95ec-b4120f0d2d9f",
            "label": "teeth_gap",
            "image": "/assets/mouth-3-teeth_gap.png"
        },
        {
            "uuid": "a489184e-5fa8-48e9-8a1d-6f4b1070163f",
            "label": "cig",
            "image": "/assets/mouth-3-cig.png"
        },
        {
            "uuid": "36cea0bb-b319-4055-aac2-04b780344e46",
            "label": "burp",
            "image": "/assets/mouth-3-burp.png"
        },
        {
            "uuid": "09068d5a-34c8-4a96-8a7b-7da76a5611c0",
            "label": "joint",
            "image": "/assets/mouth-3-joint.png"
        },
        {
            "uuid": "7eecaf9e-8ab5-4e08-a1f8-6ae132c9cf20",
            "label": "gold_teeth",
            "image": "/assets/mouth-3-gold_teeth.png"
        }
    ],
    "outfit": [
        {
            "uuid": "95bf4dcc-fecf-4f59-8a4c-c90e806b77f1",
            "label": "blue_shirt",
            "image": "/assets/outfit-4-blue_shirt.png"
        },
        {
            "uuid": "5f5557a6-3583-43b4-906c-2953c26e5818",
            "label": "pink_ball_shirt",
            "image": "/assets/outfit-4-pink_ball_shirt.png"
        },
        {
            "uuid": "640e86aa-f415-49a1-9367-866f8ecfacb0",
            "label": "blue_jacket",
            "image": "/assets/outfit-4-blue_jacket.png"
        },
        {
            "uuid": "5d4f517c-8a76-4fcf-9b5c-277cb3999d1d",
            "label": "red_shirt",
            "image": "/assets/outfit-4-red_shirt.png"
        },
        {
            "uuid": "79ca34e3-58ba-4c3c-bf0c-1de9473ea906",
            "label": "bowling_flame",
            "image": "/assets/outfit-4-bowling_flame.png"
        },
        {
            "uuid": "be5e7a68-3867-42f1-b00a-383c9ed02b17",
            "label": "bowling_god",
            "image": "/assets/outfit-4-bowling_god.png"
        },
        {
            "uuid": "fd0e53cf-620c-44dd-a48d-ff88ef2c438a",
            "label": "full_leather",
            "image": "/assets/outfit-4-full_leather.png"
        },
        {
            "uuid": "a3642e3a-5890-4c6d-a215-5b8cf0313763",
            "label": "bowling_pink_fury",
            "image": "/assets/outfit-4-bowling_pink_fury.png"
        },
        {
            "uuid": "a2e7c07d-f6d1-477f-ac86-1a490962f1c9",
            "label": "high_jacket",
            "image": "/assets/outfit-4-high_jacket.png"
        },
        {
            "uuid": "3e52c739-c514-487c-9960-ac52d8088828",
            "label": "doctor_bloody",
            "image": "/assets/outfit-4-doctor_bloody.png"
        },
        {
            "uuid": "a3568bd2-bcb7-4ee2-9953-c0fd362fe1be",
            "label": "military",
            "image": "/assets/outfit-4-military.png"
        },
        {
            "uuid": "e39078e5-8d13-4ab3-83b8-bdd112a84109",
            "label": "pink_shirt",
            "image": "/assets/outfit-4-pink_shirt.png"
        },
        {
            "uuid": "2a7ad4ec-2508-4c92-91a1-4ec99507d708",
            "label": "theer_jacket",
            "image": "/assets/outfit-4-theer_jacket.png"
        },
        {
            "uuid": "39607da6-a698-4d1c-927c-e9a25a3d8939",
            "label": "bowling_demon",
            "image": "/assets/outfit-4-bowling_demon.png"
        },
        {
            "uuid": "e793c2bf-87a0-48f5-8ce7-e8510bcd07e9",
            "label": "patched_leather_jacket",
            "image": "/assets/outfit-4-patched_leather_jacket.png"
        },
        {
            "uuid": "0844e0d7-c485-490d-8b04-270bbfabe802",
            "label": "green_ball_shirt",
            "image": "/assets/outfit-4-green_ball_shirt.png"
        },
        {
            "uuid": "dd11e673-d4a3-4104-b4b0-479d2a9539c9",
            "label": "rainbow_jacket",
            "image": "/assets/outfit-4-rainbow_jacket.png"
        },
        {
            "uuid": "4056cccb-3a06-4524-9006-caaf66d1e6d0",
            "label": "red_pin_shirt",
            "image": "/assets/outfit-4-red_pin_shirt.png"
        },
        {
            "uuid": "9cfba8ae-0e2c-4973-981a-4e8f7eff3bd6",
            "label": "blue_pin_shirt",
            "image": "/assets/outfit-4-blue_pin_shirt.png"
        },
        {
            "uuid": "70823354-dd7a-4e58-929c-bd64a6f54164",
            "label": "crypto_leather_jacket",
            "image": "/assets/outfit-4-crypto_leather_jacket.png"
        },
        {
            "uuid": "b33318ac-09f0-4d24-be2a-66cffff5aa14",
            "label": "bowling_plain",
            "image": "/assets/outfit-4-bowling_plain.png"
        },
        {
            "uuid": "7c86a532-0aee-435b-8d59-b76e7c2f7b4b",
            "label": "doctor",
            "image": "/assets/outfit-4-doctor.png"
        },
        {
            "uuid": "f21f0abb-7cff-4899-b9b9-3fc2fced7540",
            "label": "green_shirt",
            "image": "/assets/outfit-4-green_shirt.png"
        },
        {
            "uuid": "02af5c7b-cddb-430e-9328-188d50d721a3",
            "label": "leather_jacket",
            "image": "/assets/outfit-4-leather_jacket.png"
        },
        {
            "uuid": "babd7242-faf4-4b41-9111-47c63925dac6",
            "label": "nft_draft_a",
            "image": "/assets/outfit-4-nft_draft_a.png"
        },
        {
            "uuid": "5cd29a29-2cc0-4ec2-aa20-00ad81fe94df",
            "label": "patched_blue_jacket",
            "image": "/assets/outfit-4-patched_blue_jacket.png"
        },
        {
            "uuid": "39226498-6aa9-4d9f-938a-a2a1f6cc309c",
            "label": "yellow_shirt",
            "image": "/assets/outfit-4-yellow_shirt.png"
        },
        {
            "uuid": "b1887064-6528-429a-8991-9cdc24eac4b1",
            "label": "nft_draft_b",
            "image": "/assets/outfit-4-nft_draft_b.png"
        },
        {
            "uuid": "c8869218-0da0-449d-b492-b430ebd76656",
            "label": "theer_leather_jacket",
            "image": "/assets/outfit-4-theer_leather_jacket.png"
        }
    ],
    "accessory": [
        {
            "uuid": "4999c914-0dd9-476f-8580-2e9107f6df50",
            "label": "patch",
            "image": "/assets/accessory-5-patch.png"
        },
        {
            "uuid": "7ff104d3-d878-435e-944a-f082449532de",
            "label": "chain",
            "image": "/assets/accessory-5-chain.png"
        },
        {
            "uuid": "9395ab4f-2cc3-4e84-bc35-5b5dd8eb4e99",
            "label": "bowling_patch",
            "image": "/assets/accessory-5-bowling_patch.png"
        },
        {
            "uuid": "57fb93d1-efad-4a2c-a2e4-1eda00c99aee",
            "label": "bowling_face_tattoos",
            "image": "/assets/accessory-5-bowling_face_tattoos.png"
        },
        {
            "uuid": "3c4f7fe0-21ff-49ee-8c07-d7ed8e23a15f",
            "label": "chain_pin",
            "image": "/assets/accessory-5-chain_pin.png"
        },
        {
            "uuid": "084fdc93-9b42-46c1-adcb-61fa4cfda7d9",
            "label": "big_pink_bowling_patch",
            "image": "/assets/accessory-5-big_pink_bowling_patch.png"
        },
        {
            "uuid": "298c136a-18e2-4704-98f9-de92b75b69ca",
            "label": "strike_patch",
            "image": "/assets/accessory-5-strike_patch.png"
        },
        {
            "uuid": "62162ea6-6938-4604-a548-931eb7b2511b",
            "label": "big_blue_bowling_patch",
            "image": "/assets/accessory-5-big_blue_bowling_patch.png"
        },
        {
            "uuid": "3eeb97ec-4a9a-4d63-b1ca-df37bad5131e",
            "label": "chain_pins",
            "image": "/assets/accessory-5-chain_pins.png"
        },
        {
            "uuid": "380ce6eb-fab4-4c42-885d-7d4c71ed6839",
            "label": "pin_patch",
            "image": "/assets/accessory-5-pin_patch.png"
        },
        {
            "uuid": "59a5f301-5e3b-478a-894a-e20265f876b0",
            "label": "blue_bowling_patch",
            "image": "/assets/accessory-5-blue_bowling_patch.png"
        },
        {
            "uuid": "4598109a-5d54-4a54-a9b4-52c241eccd46",
            "label": "pink_bowling_patch",
            "image": "/assets/accessory-5-pink_bowling_patch.png"
        },
        {
            "uuid": "a3bbe6da-705a-45a0-97f2-8d5810b905fc",
            "label": "pins_patch",
            "image": "/assets/accessory-5-pins_patch.png"
        },
        {
            "uuid": "452382a3-4960-438f-a728-559ae7ce9f3a",
            "label": "pin",
            "image": "/assets/accessory-5-pin.png"
        },
        {
            "uuid": "79ae00a6-02db-49e2-9097-4e9cb0c4029e",
            "label": "empty",
            "image": "/assets/accessory-5-empty.png"
        }
    ],
    "hat": [
        {
            "uuid": "e0b7ec8d-e591-4af9-b06b-87fc20ffd31e",
            "label": "blue_bowling_ball",
            "image": "/assets/hat-6-blue_bowling_ball.png"
        },
        {
            "uuid": "b4aea8d0-ebbb-4c71-b7db-8477a5dc11d7",
            "label": "blue_punk",
            "image": "/assets/hat-6-blue_punk.png"
        },
        {
            "uuid": "48df4ab8-e674-431d-b5ae-ba28e800e90c",
            "label": "red_bowling_ball",
            "image": "/assets/hat-6-red_bowling_ball.png"
        },
        {
            "uuid": "b871a9eb-a861-44c4-9617-1fbaf620a3a9",
            "label": "visor",
            "image": "/assets/hat-6-visor.png"
        },
        {
            "uuid": "43b2e0b2-a12c-4bd7-a39c-3a138e1af956",
            "label": "yellow_bowling_ball",
            "image": "/assets/hat-6-yellow_bowling_ball.png"
        },
        {
            "uuid": "98c1965a-597e-48ae-b565-52a4752205b3",
            "label": "top_curl",
            "image": "/assets/hat-6-top_curl.png"
        },
        {
            "uuid": "6172f910-b218-4b42-9372-3004aa7df8d6",
            "label": "crown",
            "image": "/assets/hat-6-crown.png"
        },
        {
            "uuid": "d6f5a664-146c-462d-8eae-7dbc851d5193",
            "label": "dark_short_hair",
            "image": "/assets/hat-6-dark_short_hair.png"
        },
        {
            "uuid": "9e4e71a5-ed77-4fc7-a844-88bdcae169a0",
            "label": "dark_hair",
            "image": "/assets/hat-6-dark_hair.png"
        },
        {
            "uuid": "750be84b-ed41-4026-91e5-1a34bf4d886c",
            "label": "cowboy",
            "image": "/assets/hat-6-cowboy.png"
        },
        {
            "uuid": "ee151337-c67f-44ef-a3ec-4d782651e1ab",
            "label": "classic",
            "image": "/assets/hat-6-classic.png"
        },
        {
            "uuid": "97d6271e-7ef2-4f04-ae6a-ec74545f32bb",
            "label": "green_punk",
            "image": "/assets/hat-6-green_punk.png"
        },
        {
            "uuid": "b149e915-ade5-40e8-828a-cae75ac1d868",
            "label": "green_bowling_ball",
            "image": "/assets/hat-6-green_bowling_ball.png"
        },
        {
            "uuid": "18daaa84-7eb4-4b64-990d-80818530fe41",
            "label": "drink",
            "image": "/assets/hat-6-drink.png"
        },
        {
            "uuid": "33eb499f-fc93-47f3-a3c1-904a1d25c6a8",
            "label": "empty",
            "image": "/assets/hat-6-empty.png"
        },
        {
            "uuid": "b93276fc-a4e5-4442-9d40-29f25f8f68cb",
            "label": "dark_spiky_hair",
            "image": "/assets/hat-6-dark_spiky_hair.png"
        },
        {
            "uuid": "bbdb0949-9565-490a-a918-ba1e9fe27c86",
            "label": "horns",
            "image": "/assets/hat-6-horns.png"
        },
        {
            "uuid": "fe585929-3747-46f8-b11c-fdc28a1b2ee3",
            "label": "halo",
            "image": "/assets/hat-6-halo.png"
        },
        {
            "uuid": "24355d7d-1ff5-4ca1-a51d-4e0eb5152f1f",
            "label": "ponytail",
            "image": "/assets/hat-6-ponytail.png"
        },
        {
            "uuid": "79e30a97-f18c-4a58-9ba3-1aef3b52d6a7",
            "label": "cap",
            "image": "/assets/hat-6-cap.png"
        },
        {
            "uuid": "a3d64591-4993-493e-a252-8bea17f9e4b0",
            "label": "long_beard",
            "image": "/assets/hat-6-long_beard.png"
        },
        {
            "uuid": "b63429dd-2eb8-449e-a2dc-4f5abaaeb24a",
            "label": "bowling_flames",
            "image": "/assets/hat-6-bowling_flames.png"
        },
        {
            "uuid": "f5ab5008-b908-4616-99a7-e7730c013b57",
            "label": "chin_beard",
            "image": "/assets/hat-6-chin_beard.png"
        },
        {
            "uuid": "552ac5ea-aa55-4d7d-b61f-1869b682fd19",
            "label": "chops",
            "image": "/assets/hat-6-chops.png"
        },
        {
            "uuid": "0eb90561-eda4-4c2d-9954-6cceb6e8c8b2",
            "label": "cap_high",
            "image": "/assets/hat-6-cap_high.png"
        },
        {
            "uuid": "04ad871e-7869-4741-a91b-11fc12dd6268",
            "label": "leather_mask",
            "image": "/assets/hat-6-leather_mask.png"
        },
        {
            "uuid": "01cfbbba-edb8-45e8-b7c5-4fe148b67c0a",
            "label": "rainbow_bowling_ball",
            "image": "/assets/hat-6-rainbow_bowling_ball.png"
        },
        {
            "uuid": "44b72bc0-882a-4b60-878e-f1c1942b9f88",
            "label": "blonde_spiky_hair",
            "image": "/assets/hat-6-blonde_spiky_hair.png"
        },
        {
            "uuid": "68069dd3-a6e0-42ec-8a5c-a8426b43399f",
            "label": "bonnet",
            "image": "/assets/hat-6-bonnet.png"
        },
        {
            "uuid": "79c64d78-1aac-40b5-b07f-6d50b7ebcdb9",
            "label": "blonde_sweatband",
            "image": "/assets/hat-6-blonde_sweatband.png"
        },
        {
            "uuid": "4504b043-c841-4c99-a842-be90518c0b97",
            "label": "senior",
            "image": "/assets/hat-6-senior.png"
        },
        {
            "uuid": "9bc42c86-e77e-487c-8c7b-1b9ab351ebce",
            "label": "rainbow_punk",
            "image": "/assets/hat-6-rainbow_punk.png"
        },
        {
            "uuid": "9de2b3f3-83c4-4953-8ea8-2254dfeb288e",
            "label": "red_punk",
            "image": "/assets/hat-6-red_punk.png"
        }
    ]
};

export const categories = Object.keys(pieces);
