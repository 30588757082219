import { useEffect, useState } from "react";
import styled from '@emotion/styled';

import { getGameCharactersList } from "@api/Api";

import ConfirmationModal from '@components/ConfirmationModal';
import CharacterCard from "@components/CharacterCard";
import Pagination from "@components/Pagination";
import Loading from "@components/Loading";

import strings from "@resources/strings";
import { DEFAULT_PAGE_SIZE, QUERY_KEYS } from "@resources/DataModel";

const CardGridContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'space-evenly',
    [ theme.mediaQuery.tabletUp ]: {
        flexDirection: 'row'
    }
}));

function MyCharacters() {
    const [ characters, setCharacters ] = useState([]);
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ page, setPage ] = useState(1);
    const [ maxPage, setMaxPage ] = useState(1);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        getCharacters();
    }, []);

    const getCharacters = (currentPage = null) => {
        const success = (response) => {
            setPage(response.current_page);
            setMaxPage(response.max_page);
            setCharacters(response.result);
            setLoading(false);
        };

        const error = (response) => {
            setShowErrorModal(true);
            setErrorMessage(response);
            setLoading(false);
        };

        const queryObj = {
            [QUERY_KEYS.page]: currentPage ?? page,
            [QUERY_KEYS.pageSize]: DEFAULT_PAGE_SIZE,
            [QUERY_KEYS.order]: [ "-stamina", "-level", "created_at" ]
        };

        setLoading(true);
        getGameCharactersList(queryObj, success, error);
    };

    const onPageChange = (newPage) => {
        getCharacters(newPage);
    };

    return (
        <>
            <CardGridContainer>
                { characters ?
                    characters.map(character => (<CharacterCard key={character.uuid} character={character} reload={getCharacters} management />))
                    :
                    null
                }
            </CardGridContainer>
            <Pagination currentPage={page} totalPages={maxPage} onPageChange={onPageChange} />
            <ConfirmationModal
                title={strings.error}
                show={showErrorModal}
                onConfirm={() => {setShowErrorModal(false); setErrorMessage("");}}
                onClose={() => {setShowErrorModal(false); setErrorMessage("");}}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
            { loading ? <Loading isFullScreen /> : null }
        </>
    );
}

export default MyCharacters;
