import styled from "@emotion/styled";
import { useTheme } from '@emotion/react';

import Badge from "@components/Badge";

const pendingImage = '/assets/pending.png';

const CardContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    margin: theme.spacing.xxs
}));

const NameContainer = styled.h5(({ theme, minified }) => ({
    ...theme.typography.h5,
    fontWeight: 'bold',
    margin: minified ? `${theme.spacing.xxxs} 0px` : theme.spacing.xs,
    color: theme.colors.common.text,
    fontSize: minified ? '18px' : null,
    maxWidth: minified ? theme.size.characterLayerSizeXS : theme.size.nftDescriptionMaxWidth,
    whiteSpace: 'nowrap',
    overflowY: 'clip',
    textOverflow: 'ellipsis'
}));

const DescriptionContainer = styled.h6(({ theme, minified }) => ({
    ...theme.typography.h6,
    fontWeight: 'bold',
    margin: theme.spacing.xs,
    color: theme.colors.common.text,
    maxWidth: minified ? theme.size.characterLayerSizeXS : theme.size.nftDescriptionMaxWidth,
    whiteSpace: 'nowrap',
    overflowY: 'clip',
    textOverflow: 'ellipsis'
}));

const LayerContainer = styled.div(({ theme, minified }) => ({
    margin: `${theme.spacing.xs} 0px`,
    position: 'relative',
    width: minified ? theme.size.characterLayerSizeXS : '80vw',
    maxWidth: minified ? theme.size.characterLayerSizeXS : theme.size.characterLayerSize,
    height: minified ? 212 : 'calc(80vw + 150px)',
    maxHeight: minified ? 212 : 682,
    [ theme.mediaQuery.largeMobileUp ]: {
        height: minified ? 'calc(40vw + 170px)' : 'calc(80vw + 170px)'
    },
    [ theme.mediaQuery.tabletUp ]: {
        margin: theme.spacing.xxxs,
        height: minified ? 212 : 414,
        width: minified ? theme.size.characterLayerSizeXS : theme.size.characterLayerSizeS,
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    }
}));

const ImageContainer = styled.img(({ theme }) => ({
    borderRadius: '6%',
    width: '100%',
    maxWidth: 'unset',
    maxHeight: '100%',
    margin: 0
}));

const InventoryCard = ({ nft = null, noDescription = false, controls = () => {}, minified = false, locked = false }) => {
    const theme = useTheme();

    return (
        <CardContainer>
            <NameContainer minified={minified}>{nft?.name ?? '?'}</NameContainer>
            <LayerContainer minified={minified}>
                <Badge style={
                    {
                        position: "relative",
                        top: 0, marginLeft: 'auto',
                        borderRadius: `0 ${theme.borderRadius.l}`,
                        background: `linear-gradient(90deg, ${theme.colors.rarities[nft?.rarity]?.start} 50%, ${theme.colors.rarities[nft?.rarity]?.end} 100%)`,
                        color: '#be1522',
                        filter: locked ? 'grayscale(0.8)' : null,
                        textShadow: '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff'
                    }}
                >
                    { nft?.template_mint ? `#${nft?.template_mint}` : "" }
                </Badge>
                <ImageContainer style={{ position: "relative", marginTop: `-${theme.size.mintHeight}`, filter: locked ? 'grayscale(0.8)' : null }} alt={nft?.description ?? '?'} src={nft?.image ?? pendingImage} />
            </LayerContainer>
            { !noDescription && nft?.description ?
                <DescriptionContainer text={nft?.description} minified={minified}>{nft?.description}</DescriptionContainer>
                :
                null
            }
            { controls() }
        </CardContainer>
    );
};

export default InventoryCard;
