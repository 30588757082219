const { JsonRpc } = require("eosjs");
import * as GLOBAL_VARS from '@resources/Globals';

export async function findAssetTableWax(asset_id) {

    const rpc = new JsonRpc(GLOBAL_VARS.WAX_RPC_ENDPOINT, { fetch });

    try {
        const resp = await rpc.get_table_rows({
            json: true,               // Get the response as json
            code: GLOBAL_VARS.CUSTODY_ACCOUNT,      // Contract that we target
            scope: GLOBAL_VARS.CUSTODY_ACCOUNT,         // Account that owns the data
            table: GLOBAL_VARS.CUSTODY_TABLE,        // Table name
            limit: 1,                // Maximum number of rows that we want to get
            upper_bound: asset_id,
            lower_bound: asset_id
        });
        if (resp.rows[0] && resp.rows[0].asset_id === asset_id) {
            return true;
        } else {
            return false;
        }
        //return lastTransactions;
    } catch (error) {
        console.debug(error);
        return false;
    }

}