import styled from "@emotion/styled";

const CustomButton = styled.button(({ secondary, outline, styles, theme }) => {
    let border = `solid ${theme.size.buttonBorder} ${theme.colors.primary.main}`;
    let background = `linear-gradient(130deg, ${theme.colors.primary.gradientStart} 30%, ${theme.colors.primary.gradientEnd} 70%)`;
    let color = secondary && !outline ? theme.colors.common.background : !secondary && outline ? theme.colors.primary.main : theme.colors.common.text;
    if (secondary) {
        border = `solid ${theme.size.buttonBorder} ${theme.colors.common.text}`;
        background = theme.colors.common.text;
    }
    if (outline) {
        background = 'transparent';
    }
    return {
        borderRadius: theme.borderRadius.xxl,
        fontWeight: 600,
        border,
        background,
        color,
        height: theme.size.buttonHeight,
        minWidth: theme.size.buttonHeight,
        textTransform: 'uppercase',
        cursor: 'pointer',
        ...styles,
        '&:disabled': {
            filter: 'grayscale(0.8)',
            cursor: 'not-allowed',
            color: outline ? theme.colors.common.disabled : color,
            border: outline ? `solid ${theme.size.buttonBorder} ${theme.colors.common.disabled}` : border
        }
    };
});

const Button = ({ children, styles, secondary, outline, ...otherProps }) => {
    return <CustomButton secondary={secondary} outline={outline} styles={styles} {...otherProps}>{children}</CustomButton>;
};

export default Button;
