import RequestAccountActivationForm from "@components/authentication/RequestAccountActivationForm";
import strings from "@resources/strings";

const RequestAccountActivation = () => {
    return (
        <>
            <h1>{strings.activateAccount}</h1>
            <RequestAccountActivationForm />
        </>
    );
};

export default RequestAccountActivation;
