import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTheme } from '@emotion/react';
import styled from "@emotion/styled";
import UALLoginButton from '@components/UALLoginButton';
import ConfirmationModal from '@components/ConfirmationModal';
import PreviewCharacters from '@components/PreviewCharacters';
import strings from '@resources/strings';
import routes from '@resources/Routes';
import { useEthereum } from '@hooks/EthereumContext';
import MetamaskLoginButton from '@components/MetamaskLoginButton';
import { useSetNetwork } from '@hooks/GeneralContext';
import { useFeatureFlags } from '@hooks/FeatureFlagsContext';
import Container from '@components/Container';
import { isIOS, isMobile } from 'react-device-detect';
import { useChain } from "@hooks/ChainContext";

const CenteredParagraph = styled.p(({ theme }) => ({
    ...theme.typography.p,
    alignText: 'center',
    maxWidth: '60ch'
}));

const CenteredSubtitle = styled.div(({ theme }) => ({
    ...theme.typography.h2,
    color: theme.colors.common.text,
    marginBottom: theme.spacing.xs,
    marginTop: theme.spacing.xs
}));

function UALLogin () {
    const navigate = useNavigate();
    const theme = useTheme();
    const { isAuthenticated } = useChain();

    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState("");
    const { account, connectToMetamask } = useEthereum();
    const { setNetwork, connectedNetwork } = useSetNetwork();
    const CHARACTER_CREATOR_ENABLED = useFeatureFlags().characterCreator;

    useEffect(() => {
        if ((account && connectedNetwork === "ethereum") || (isAuthenticated && connectedNetwork === "wax")) {
            navigate(routes.login);
        }
    }, [ account, isAuthenticated, connectedNetwork ]);

    return (
        <>
            <CenteredSubtitle>
                {strings.productName}
            </CenteredSubtitle>
            {isMobile && isIOS ?
                null :
                <>
                    <PreviewCharacters />
                    <PreviewCharacters animation={"translate_2"} />
                    <PreviewCharacters animation={"translate_3"} />
                </> }
            <Container
                direction="column"
                margin={`${theme.spacing.xs} auto ${theme.spacing.xs}`}
                gap={theme.spacing.xs}
            >
                {CHARACTER_CREATOR_ENABLED ?
                    <CenteredSubtitle>
                        {strings.ethAvailable}
                    </CenteredSubtitle>
                    : null}
                <Container
                    direction="column"
                    gap={theme.spacing.xs}
                >
                    <UALLoginButton label={strings.existingAccount} setNetwork={setNetwork} />
                    <MetamaskLoginButton
                        outline
                        secondary
                        label={strings.onlyEthLinked}
                        connectToMetamaskWallet={connectToMetamask}
                        setNetwork={setNetwork}
                        style={{
                            ...theme.typography.pTiny,
                            textTransform: 'none'
                        }}
                    />
                </Container>
                <CenteredParagraph>
                    {strings.existingAccountDisclaimer}
                </CenteredParagraph>
                <CenteredParagraph style={{ fontWeight: 600 }}>
                    {strings.noSafari}
                </CenteredParagraph>
            </Container>
            <ConfirmationModal
                title={strings.error}
                show={showErrorModal}
                onConfirm={() => {setShowErrorModal(false); setErrorMessage("");}}
                onClose={() => {setShowErrorModal(false); setErrorMessage("");}}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
        </>
    );
}

export default UALLogin;
