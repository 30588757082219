import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const Spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const LoadingBackgroundOverlay = styled.div(() => ({
    backgroundColor: 'rgba(6,2,1,0.5)',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 999
}));

const LoadingContainer = styled.div(({ theme }) => ({
    paddingTop: theme.spacing.m,
    display: 'block',
    textAlign: 'center',
    justifyContent: 'center'
}));

const LoadingSpinner = styled.div(({ theme }) => ({
    width: theme.size.loadingWidth,
    height: theme.size.loadingHeight,
    margin: 'auto',
    border: `${theme.size.loadingBorder} solid ${theme.colors.primary.main}`,
    borderTop: `${theme.size.loadingBorder} solid ${theme.colors.common.background}`,
    borderRadius: '50%',
    animation: `${Spin} 1.5s linear infinite`
}));

const LoadingCaption = styled.h1(({ theme }) => ({
    marginTop: theme.spacing.xs,
    color: theme.colors.secondaryDark
}));

function Loading({ message, isFullScreen = false }) {
    const renderLoading = () => {
        return (
            <LoadingContainer>
                <LoadingSpinner />
                <LoadingCaption>{message}</LoadingCaption>
            </LoadingContainer>
        );
    };

    return isFullScreen ? <LoadingBackgroundOverlay onClick={e => { e.preventDefault(); e.stopPropagation(); }}>{renderLoading()}</LoadingBackgroundOverlay> : renderLoading();
}

export default Loading;
