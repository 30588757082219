import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import SessionKit from "@wharfkit/session";
import { WalletPluginAnchor } from "@wharfkit/wallet-plugin-anchor";
import { WalletPluginCloudWallet } from "@wharfkit/wallet-plugin-cloudwallet";
import { WebRenderer } from "@wharfkit/web-renderer";

const ChainContext = createContext({
    isAuthenticated: null,
    login: () => Promise.resolve(),
    logout: () => Promise.resolve()
});

export default function ChainProvider({ chains, children }) {
    const [ session, setSession ] = useState();
    const isAuthenticated = session != null ? !!session?.actor.toString() : null;

    const { sessionKit, localStorageSessionKey } = useMemo(() => {
        const internalAppNameSlug = 'pinmaster';

        const internalSessionKit = new SessionKit({
            appName: internalAppNameSlug,
            chains,
            ui: new WebRenderer(),
            walletPlugins: [ new WalletPluginAnchor(), new WalletPluginCloudWallet() ]
        });

        const internalLocalStorageSessionKey = `wharf-${internalSessionKit.appName}-session`;

        return {
            sessionKit: internalSessionKit,
            appNameSlug: internalAppNameSlug,
            localStorageSessionKey: internalLocalStorageSessionKey
        };
    }, [ chains ]);

    const logout = useCallback(async () => {
        await sessionKit.logout(session);
        setSession(undefined);
    }, [ session, sessionKit ]);

    const login = useCallback(async () => {
        const response = await sessionKit.login();
        setSession(response.session);
    }, [ sessionKit ]);

    const restoreSession = useCallback(async () => {
        try {
            const restoredSession = await sessionKit.restore();
            setSession(restoredSession);
        } catch {
            setSession(undefined);
        }
    }, [ sessionKit ]);

    useEffect(() => {
        void restoreSession();
    }, [ restoreSession ]);

    useEffect(() => {
        // handle what happens on key press
        const handleStorageChange = (event) => {
            if (event.key === localStorageSessionKey && event.newValue == null) {
                setSession(undefined);
            } else if (event.key === localStorageSessionKey && event.newValue != null) {
                void restoreSession();
            }
        };

        // attach the event listener
        window.addEventListener('storage', handleStorageChange);

        // remove the event listener
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [ localStorageSessionKey, restoreSession ]);

    const chainContextValue = useMemo(
        () => ({
            login,
            logout,
            session,
            isAuthenticated
        }),
        [ login, logout, session, isAuthenticated ]
    );

    return <ChainContext.Provider value={chainContextValue}>{children}</ChainContext.Provider>;
}

export function useChain() {
    const { login, logout, isAuthenticated, session } = useContext(ChainContext);

    return {
        login,
        logout,
        session,
        isAuthenticated,
        actor: session?.actor?.toString(),
        permission: session?.permission?.toString()
    };
}
