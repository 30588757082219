import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Button from "@components/Button";
import ConfirmationModal from "@components/ConfirmationModal";
import { getServerAccountInfo, useServerAccount } from "@hooks/AccountContext";
import { useSetNetwork } from "@hooks/GeneralContext";
import routes from "@resources/Routes";
import strings from "@resources/strings";
import { useChain } from "@hooks/ChainContext";
import { activeUserFromSession, transferNftToCustodyAccount } from "@api/AtomicHubApi";

const TitleContainer = styled.h1(({ theme }) => ({
    ...theme.typography.h1,
    margin: theme.spacing.xs,
    color: theme.colors.common.text,
    maxWidth: "60ch"
}));

//TODO CSS: Remove TextContainer
const TextContainer = styled.h4(({ theme }) => ({
    ...theme.typography.h4,
    margin: theme.spacing.xs,
    color: theme.colors.common.text,
    textAlign: "start",
    maxWidth: "60ch"
}));

const PagePreviewImage = styled.img(({ theme }) => ({
    width: '100%',
    borderRadius: theme.borderRadius.xl,
    marginBottom: theme.spacing.xs
}));

const LayerContainer = styled.div(({ theme }) => ({
    margin: `${theme.spacing.xs} 0px`,
    position: 'relative',
    width: '90vw',
    cursor: 'pointer',
    [ theme.mediaQuery.largeMobileUp ]: {
        height: 'calc(90vw + 210px)'
    },
    [ theme.mediaQuery.largeMobileUp ]: {
        margin: theme.spacing.s,
        width: theme.size.selectorsContainerWidth,
        flexDirection: 'row',
        justifyContent: 'space-evenly'
    }
}));

const PagesSelectionContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing.xs,
    [theme.mediaQuery.tabletUp]: {
        flexDirection: "row"
    }
}));

const Home = () => {
    const { isAuthenticated, session, login } = useChain();
    const navigate = useNavigate();
    const [ errorWaxMessage, setErrorWaxMessage ] = useState("");
    const [ showErrorWaxModal, setShowErrorWaxModal ] = useState(false);
    const { serverAccountInfo } = useServerAccount();
    const { setNetwork, setActiveAccounts, connectedNetwork } = useSetNetwork();

    useEffect(() => {
        if (serverAccountInfo) {
            setActiveAccounts(serverAccountInfo);
        }
    }, []);

    useEffect(() => {
        const serverInfo = getServerAccountInfo();
        if (!serverInfo || !connectedNetwork) {
            navigate(routes.root);
        }

    }, [ serverAccountInfo, connectedNetwork ]);

    const navigateGame = () => {
        const serverInfo = serverAccountInfo || getServerAccountInfo();
        if (!serverInfo.wax_account) {
            setErrorWaxMessage(strings.waxAccountNotLinked);
            setShowErrorWaxModal(true);
        } else if (!isAuthenticated) {
            setErrorWaxMessage(strings.waxAccountNotConnected);
            setShowErrorWaxModal(true);
        } else {
            setNetwork("wax");
            navigate(routes.game);
        }
    };

    const connectToWax = async () => {
        await login();
    };

    return (
        <>
            <TitleContainer>{strings.titleBowlingGame}</TitleContainer>
            <TextContainer>{strings.captionBowlingGame}</TextContainer>
            <PagesSelectionContainer>
                <LayerContainer>
                    <PagePreviewImage onClick={() => navigate(routes.characterCreator)} src="/assets/background-0-full_alley.png" />
                    <Button onClick={() => navigate(routes.characterCreator)}>{strings.characterCreator}</Button>
                </LayerContainer>
                <LayerContainer>
                    <PagePreviewImage onClick={navigateGame} src="/assets/background-0-colorful_alley.png" />
                    <Button onClick={navigateGame}>{strings.game}</Button>
                </LayerContainer>
            </PagesSelectionContainer>
            <ConfirmationModal
                show={showErrorWaxModal}
                title={strings.error}
                confirmationButtonLabel={serverAccountInfo && serverAccountInfo.wax_account ? "Connect" : "Link"}
                onClose={() => {
                    setErrorWaxMessage("");
                    setShowErrorWaxModal(false);
                }}
                onConfirm={() => {
                    setErrorWaxMessage("");
                    setShowErrorWaxModal(false);
                    connectToWax();
                }}
            >
                {errorWaxMessage}
            </ConfirmationModal>
        </>
    );
};

export default Home;
