import strings from "@resources/strings";
import styled from '@emotion/styled';
import routes from '@resources/Routes';
import { useNavigate } from 'react-router';
import { useTheme } from '@emotion/react';

import Button from "@components/Button";

const ContentContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    margin: theme.spacing.xxs
}));

const TabsContainer = styled.div(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing.xs,
    [ theme.mediaQuery.largeMobileUp ]: {
        flexDirection: 'row'
    }
}));

const TabContainer = styled.h4(({ theme }) => ({
    ...theme.typography.h4,
    height: `calc(${theme.size.buttonHeight} - 4px)`,
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing.xs,
    padding: `0px ${theme.spacing.m}`,
    color: theme.colors.common.text,
    maxWidth: '60ch',
    borderRadius: theme.borderRadius.xl,
    border: `2px solid ${theme.colors.common.text}`,
    [ theme.mediaQuery.largeMobileUp ]: {
        margin: 'unset'
    }
}));

const TextContainer = styled.h4(({ finished = true, styles, theme }) => ({
    ...theme.typography.h4,
    margin: theme.spacing.xs,
    color: finished ? theme.colors.common.text : theme.colors.secondary.main,
    maxWidth: '60ch',
    ...styles
}));

const TableContainer = styled.div(({ theme }) => ({
    padding: theme.spacing.xxs,
    borderRadius: theme.borderRadius.l,
    border: `2px solid ${theme.colors.common.text}`
}));

const Table = styled.table(({ theme }) => ({
    display: 'block',
    position: 'relative',
    overflowY: 'scroll',
    borderCollapse: 'collapse',
    padding: `0px ${theme.spacing.xxs}`,
    minHeight: 260,
    maxHeight: 'calc(100vh - 250px)'
}));

const TableRow = styled.tr(({ theme }) => ({
    borderBottom: `1px solid ${theme.colors.common.text}`
}));

const TableHead = styled.th(({ theme }) => ({
    position: 'sticky',
    top: 0,
    backgroundColor: theme.colors.common.background
}));

function MatchHistory({ matches = [], outOfStamina = false }) {
    const navigate = useNavigate();
    const theme = useTheme();

    return (
        <ContentContainer>
            <TabsContainer>
                <TabContainer>{strings.practices}</TabContainer>
                { !matches[0] || (matches[0] && matches[0].finished) ?
                    <Button style={{ margin: `0px ${theme.spacing.s}`, padding: `0px ${theme.spacing.m}` }} onClick={() => navigate(routes.game + routes.practice)} disabled={outOfStamina}>{strings.startNewMatch}</Button>
                    :
                    null
                }
            </TabsContainer>
            <TableContainer>
                { matches[0] ?
                    <Table>
                        <thead>
                            <TableRow>
                                <TableHead><TextContainer>{strings.status}</TextContainer></TableHead>
                                <TableHead><TextContainer>{strings.date}</TextContainer></TableHead>
                                <TableHead><TextContainer>{strings.score}</TextContainer></TableHead>
                                <TableHead><TextContainer>{strings.rewards}</TextContainer></TableHead>
                            </TableRow>
                        </thead>
                        <tbody>
                            {matches.map(match => (
                                <TableRow key={match.uuid}>
                                    <td><TextContainer finished={match.finished}>{match.finished ? strings.finished : strings.ongoing}</TextContainer></td>
                                    <td><TextContainer style={{ textAlign: 'left' }} finished={match.finished}>{match.created_at ? new Date(match.created_at).toDateString() : '?'}</TextContainer></td>
                                    <td><TextContainer style={{ textAlign: 'left' }} finished={match.finished}>{match.score != null ? match.score : '?'}</TextContainer></td>
                                    {
                                        match.finished ?
                                            <td><TextContainer finished={match.finished}>{match.game_data && match.game_data.experience ? match.game_data.experience + ' ' + strings.xp : '?'}</TextContainer></td>
                                            :
                                            <td><Button style={{ margin: `0px ${theme.spacing.xs}`, padding: `0px ${theme.spacing.s}` }} onClick={() => navigate(routes.game + routes.practice)}>{strings.resumeMatch}</Button></td>
                                    }
                                </TableRow>))
                            }
                        </tbody>
                    </Table>
                    :
                    <TextContainer>{strings.noMatchesPlayedYet}</TextContainer>
                }
            </TableContainer>
        </ContentContainer>
    );
}

export default MatchHistory;
