import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { getServerAccountInfo, useServerAccount } from '@hooks/AccountContext';
import routes from '@resources/Routes';

import Loading from '@components/Loading';
import ConfirmationModal from '@components/ConfirmationModal';
import { useEthereum } from '@hooks/EthereumContext';
import { useSetNetwork } from '@hooks/GeneralContext';
import { useChain } from "@hooks/ChainContext";

function Content() {
    const { isAuthenticated, actor } = useChain();
    const navigate = useNavigate();
    const [ message, setMessage ] = useState("");
    const [ showModal, setShowModal ] = useState(false);
    const { serverAccountInfo } = useServerAccount();
    const { account, setDisconnect } = useEthereum();
    const [ searchParams ] = useSearchParams();
    const { connectedNetwork } = useSetNetwork();

    useEffect(() => {
        if (searchParams.has('success')) {
            setMessage(searchParams.get('success'));
            setShowModal(true);
        }
    }, []);

    useEffect(() => {
        const serverInfo = serverAccountInfo || getServerAccountInfo();
        if (serverInfo && connectedNetwork) {
            if ((connectedNetwork === "wax" && isAuthenticated && serverInfo.wax_account !== actor) ||
                (connectedNetwork === "ethereum" && serverInfo.eth_account !== account)) {
                setDisconnect(true);
            } else {
                navigate(routes.home);
            }
        } else {
            if (!connectedNetwork) {
                navigate(routes.ualLogin);
            } else {
                navigate(routes.login);
            }
        }
    }, [ actor, isAuthenticated, account, connectedNetwork ]);

    return (
        <>
            <Loading />
            <ConfirmationModal
                show={showModal}
                title={message}
                onClose={() => { setMessage(""); setShowModal(false); navigate(routes.login); }}
                onConfirm={() => { setMessage(""); setShowModal(false); navigate(routes.login); }}
                noCancel
            />
        </>
    );
}

export default Content;
