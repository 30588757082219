import React, { createContext, useEffect, useState, useContext } from "react";
import { ETHEREUM_CHAIN_ID, ETHEREUM_CHAIN_RPC, ETHEREUM_CHAIN_NAME, CHARACTER_MINT_CONTRACT_ADDRESS } from '@resources/Globals';
import { getCharacterCreatorTicketsEth, getCharacterLastIDEth, getEthUsd, getTicketsLeftEth, getUsdPrice } from "@api/EthApi";
import ConfirmationModal from '@components/ConfirmationModal';
import strings from "@resources/strings";
// eslint-disable-next-line import/extensions
import characterMintABI from '@api/CharacterMintABI.json';
import { ethers } from "ethers";

export const EthereumContext = createContext();

const AUTO_REFRESH_TIMEOUT = 60000;

let nftInterval = null;

export const LocalStorageAccountMetamaskInfoKey = "accountMetamask";

const BaseEthereumProvider = ({ children }) => {
    const [ account, setAccount ] = useState(localStorage.getItem(LocalStorageAccountMetamaskInfoKey));
    const [ provider, setProvider ] = useState(null);
    const [ currentChainId, setCurrentChainId ] = useState(null);
    const [ characterMintContract, setCharacterMintContract ] = useState(null);
    const [ providerEthers, setProviderEthers ] = useState(null);
    const [ accountTickets, setAccountTickets ] = useState(null);
    const [ lastCharacterId, setLastCharacterId ] = useState(0);
    const [ ticketsLeft, setTicketsLeft ] = useState(4000);
    const [ usdPrice, setUsdPrice ] = useState(0);
    const [ ethUsdPrice, setEthUsdPrice ] = useState(0);
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ showErrorModal, setShowErrorModal ] = useState(false);
    const [ reloadPage, setReloadPage ] = useState(false);
    const [ disconnect, setDisconnect ] = useState(false);

    useEffect(() => {
        if (window.ethereum) {
            let provider = null;
            if (window.ethereum.providers && window.ethereum.providers.find(prov => prov.isMetaMask)) {
                provider = window.ethereum.providers.find(prov => prov.isMetaMask);
            } else if (window.ethereum.isMetaMask) {
                provider = window.ethereum;
            }

            if (!provider) {return;}
            setProvider(provider);

            const providerEthers = new ethers.providers.Web3Provider(provider, "any");
            setProviderEthers(providerEthers);

            const pinMasterContract = new ethers.Contract(CHARACTER_MINT_CONTRACT_ADDRESS, characterMintABI, providerEthers);
            setCharacterMintContract(pinMasterContract);

        }
    }, []);

    useEffect(() => {
        const setMetamaskInfo = async () => {
            const chainId = await provider.request({ method: 'eth_chainId' });
            setCurrentChainId(chainId);
            const accounts = await provider.request({ method: 'eth_accounts' });
            const accountConnected = accounts ? accounts[0] : null;
            const accountStorage = localStorage.getItem(LocalStorageAccountMetamaskInfoKey);
            if (chainId !== ETHEREUM_CHAIN_ID || (accountStorage && accountStorage !== accountConnected)) {
                setDisconnect(true);
            } else {
                setAccount(accountConnected);
            }
        };

        if (provider) {
            const providerEthers = new ethers.providers.Web3Provider(provider, "any");
            setProviderEthers(providerEthers);
            setMetamaskInfo();
        }
    }, [ provider ]);

    useEffect(() => {
        const success = (response) => {
            setUsdPrice(response);
        };
        if (account && characterMintContract) {
            refreshCreatorAccountNftsEth();
            getUsdPrice(characterMintContract, success);
        }
    }, [ account, characterMintContract ]);

    const refreshCreatorAccountNftsEth = async () => {
        if (nftInterval != null) {
            clearInterval(nftInterval);
        }
        await internalRefreshCreatorAccountNftsEth();
        nftInterval = setInterval(internalRefreshCreatorAccountNftsEth, AUTO_REFRESH_TIMEOUT);
    };

    const internalRefreshCreatorAccountNftsEth = async () => {
        const successTickets = (result) => {
            console.debug("tickets result", result);
            if (result) {
                setAccountTickets(Number(result.toString()));
            } else {
                setAccountTickets(0);
            }
        };

        let pinMasterContract = characterMintContract;
        if (!pinMasterContract) {
            pinMasterContract = new ethers.Contract(CHARACTER_MINT_CONTRACT_ADDRESS, characterMintABI, providerEthers);
            setCharacterMintContract(pinMasterContract);
        }

        await getCharacterCreatorTicketsEth(pinMasterContract, account, successTickets);

        const successCharacterId = (result) => {
            console.debug("character result", result);
            if (result) {
                setLastCharacterId(Number(result.toString()));
            } else {
                setLastCharacterId(0);
            }
        };

        await getCharacterLastIDEth(pinMasterContract, account, successCharacterId);

        const successTicketsLeft = (result) => {
            console.debug("character result", result);
            if (result) {
                setTicketsLeft(Number(result.toString()));
            } else {
                setTicketsLeft(4000);
            }
        };

        await getTicketsLeftEth(pinMasterContract, account, successTicketsLeft);

        const successEthUsdPrice = (result) => {
            setEthUsdPrice(result);
        };

        await getEthUsd(characterMintContract, successEthUsdPrice);
    };

    const isCreatorAccountEth = async () => {
        await internalRefreshCreatorAccountNftsEth();
        return account && accountTickets > 0;
    };

    async function onChainChanged() {
        const chainId = await provider.request({ method: 'eth_chainId' });
        setCurrentChainId(chainId);
        if (chainId !== ETHEREUM_CHAIN_ID) {
            setDisconnect(true);
        }
    }

    async function onAccountsChanged() {
        const accounts = await provider.request({ method: 'eth_requestAccounts' });
        const newAccount = accounts[0] ? accounts[0] : null;
        if (newAccount) {
            setAccount(newAccount);
        }
    }

    async function changeNetwork() {
        try {
            setShowErrorModal(true);
            setErrorMessage(ETHEREUM_CHAIN_NAME === "Mainnet Chain" ?
                strings.metamaskWrongMainNetwork :
                strings.metamaskWrongTestNetwork);
            setReloadPage(true);
            await provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: ETHEREUM_CHAIN_ID }]
            });
        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask.
            if (err.code === 4902) {
                await provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: ETHEREUM_CHAIN_NAME,
                            chainId: ETHEREUM_CHAIN_ID,
                            nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
                            rpcUrls: [ ETHEREUM_CHAIN_RPC ]
                        }
                    ]
                });
            }
        }
    }

    const connectToMetamask = async () => {
        if (window.ethereum) {
            try {
                if (!provider._state.isConnected) {
                    setShowErrorModal(true);
                    setErrorMessage(strings.metamaskNotLoaded);
                    setDisconnect(true);
                    return;
                }
                let chainId = currentChainId;
                if (!chainId) {
                    chainId = await provider.request({ method: 'eth_chainId' });
                    setCurrentChainId(chainId);
                }
                if (chainId !== ETHEREUM_CHAIN_ID) {
                    await changeNetwork();
                } else {
                    const accounts = await provider.request({ method: 'eth_requestAccounts' });
                    const account = accounts[0] ? accounts[0] : null;
                    setAccount(account);
                    if (account) {
                        localStorage.setItem(LocalStorageAccountMetamaskInfoKey, account);
                    } else {
                        localStorage.removeItem(LocalStorageAccountMetamaskInfoKey);
                    }
                }
            } catch (error) {
                if (error.code === -32002) {
                    setShowErrorModal(true);
                    setErrorMessage(strings.metamaskRequestOpened);
                }
            }
        } else {
            setShowErrorModal(true);
            setErrorMessage(strings.metamaskNotInstalled);
        }

    };

    const logoutMetamask = () => {
        localStorage.removeItem(LocalStorageAccountMetamaskInfoKey);
        setAccount(null);
    };

    if (provider && account) {
        provider.on("chainChanged", onChainChanged);
        provider.on("accountsChanged", onAccountsChanged);
    }

    return (
        <EthereumContext.Provider value={
            {
                providerEthers,
                account,
                accountTickets,
                currentChainId,
                disconnect,
                characterMintContract,
                lastCharacterId,
                ticketsLeft,
                usdPrice,
                ethUsdPrice,
                internalRefreshCreatorAccountNftsEth,
                setDisconnect,
                isCreatorAccountEth,
                changeNetwork,
                connectToMetamask,
                logoutMetamask
            }
        }
        >
            {children}
            <ConfirmationModal
                title={strings.error}
                show={showErrorModal}
                onConfirm={() => {
                    setShowErrorModal(false);
                    setErrorMessage("");
                    if (reloadPage) {
                        setReloadPage(false);
                        window.location.reload();
                    }
                }}
                onClose={() => { setShowErrorModal(false); setErrorMessage(""); }}
                noCancel
            >
                {errorMessage}
            </ConfirmationModal>
        </EthereumContext.Provider>);
};

function useEthereum() {
    const context = useContext(EthereumContext);
    const {  providerEthers, account, accountTickets, currentChainId, disconnect, characterMintContract, lastCharacterId, ticketsLeft, usdPrice, ethUsdPrice, internalRefreshCreatorAccountNftsEth, setDisconnect, isCreatorAccountEth, changeNetwork, connectToMetamask, logoutMetamask } = context;

    return { providerEthers, account, accountTickets, currentChainId, disconnect, characterMintContract, lastCharacterId, ticketsLeft, usdPrice, ethUsdPrice, internalRefreshCreatorAccountNftsEth, setDisconnect, isCreatorAccountEth, changeNetwork, connectToMetamask, logoutMetamask };
}

const EthereumProvider = BaseEthereumProvider;

export { EthereumProvider, useEthereum };
