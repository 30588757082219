import React from 'react';
import Button from '@components/Button';
import { useTheme } from '@emotion/react';

function Tabs({ tabs, onChange, selectedTab, secondary }) {
    const theme = useTheme();

    return (
        <div>
            {tabs.map((tab) => (
                <Button
                    key={tab.uuid}
                    onClick={() => onChange(tab.uuid)}
                    style={{
                        padding: `0px ${theme.spacing.xs}`,
                        margin: theme.spacing.xxs
                    }}
                    outline={tab.uuid !== selectedTab}
                    secondary={secondary}
                >
                    {tab.name}
                </Button>
            ))}
        </div>
    );
}

export default Tabs;
