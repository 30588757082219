import strings from "@resources/strings";
import Button from "@components/Button";

function MetamaskLoginButton({ label, connectToMetamaskWallet, setNetwork, linking = false, setIsLinking, ...otherProps }) {

    async function connectWithMetamask() {
        try {
            await connectToMetamaskWallet();
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Button onClick={() => {
            if (linking) {setIsLinking(true);}
            connectWithMetamask();
            setNetwork("ethereum");
        }
        } {...otherProps}
        >{label}</Button>
    );
}

export default MetamaskLoginButton;
